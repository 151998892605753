import { call, put, takeLatest } from "redux-saga/effects";

import { axiosInstance } from "@api/axios";
import { getAdvantagesUrl, advantageByIdUrl } from "@api/Endpoint";
import { AdvantageActionTypes } from "../redux/actionTypes";
import {
  getAdvantageByIdSuccess,
  getAdvantagesSuccess,
} from "./AdvantageActions";

function* getAdvantagesSaga(action: Action) {
  try {
    const requestPayload = action.payload;

    const url = getAdvantagesUrl(requestPayload);

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    if (response.status === 200) {
      const payload = response.data.data;
      yield put(getAdvantagesSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err) {
    console.log("err: ", err);
  }
}

function* getAdvantageByIdSaga(action: Action) {
  try {
    const id = action.payload;
    const url = advantageByIdUrl(id);

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    if (response.status === 200) {
      const payload = response.data.data;
      yield put(getAdvantageByIdSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err) {
    console.log("err: ", err);
  }
}

export function* advantageSagas() {
  yield takeLatest(AdvantageActionTypes.GET_ADVANTAGES, getAdvantagesSaga);
  yield takeLatest(
    AdvantageActionTypes.GET_ADVANTAGE_BY_ID,
    getAdvantageByIdSaga
  );
}
