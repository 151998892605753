import { Dialog } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Input from "@components/Input";
import { Button } from "@components";
import { useDispatch, useSelector } from "react-redux";
import { login } from "@store/user/UserActions";
import { useNavigate } from "react-router-dom";
import Loader from "@components/Loader";
import { useEffect, useState } from "react";
import ForgotPassword from "./ForgotPassword/ForgotPassword";

interface LoginProps {
  onClose: any;
  open: boolean;
  doNavigate?: boolean;
  email?: string;
}

const defaultValues = {
  email: "",
  password: "",
};

const Login: React.FC<LoginProps> = (props) => {
  const { onClose, open, doNavigate, email } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

  const { loading }: any = useSelector<any>(({ Travelfine }) => Travelfine.app);

  const handleClose = () => {
    onClose();
  };

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Debe introducir un mail válido")
      .required("Debe introducir un mail"),
    password: yup
      .string()
      .required("Por favor, introduzca sucontraseña")
      .min(
        6,
        "La contraseña es demasiado corta, debe tener al menos 6 caracteres"
      ),
  });

  const { control, formState, handleSubmit, reset, setValue } = useForm({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const onSubmit = (data: any) => {
    const payload = {
      email: data.email,
      password: data.password,
      navigate: doNavigate ? navigate : undefined,
    };
    dispatch(login(payload));
    reset(defaultValues);
    handleClose();
  };

  const forgotPasswordOpenHandler = () => {
    setForgotPasswordOpen(true);
    handleClose();
  };
  const forgotPasswordCloseHandler = () => setForgotPasswordOpen(false);

  useEffect(() => {
    if (email) {
      setValue("email", email);
    }
  }, [setValue, email]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="filter-Dialog">
        <Loader loading={loading} />
        <form
          name="loginForm"
          noValidate
          className="flex flex-col items-center justify-center w-full space-y-3 px-6 py-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <p className="text-2xl font-bold">Iniciar sesión</p>

          <Input
            name="email"
            type="email"
            label={"Email"}
            control={control}
            required
            fullWidth
            error={!!errors.email}
            errorMessage={errors?.email?.message}
          />
          <Input
            name="password"
            type="password"
            label={"Contraseña"}
            control={control}
            required
            fullWidth
            error={!!errors.password}
            errorMessage={errors?.password?.message}
          />
          <Button color="primary" className="w-full mt-4" type="submit">
            Iniciar sesión
          </Button>
          <p
            className="underline font-medium cursor-pointer mt-2"
            onClick={forgotPasswordOpenHandler}
          >
            Has olvidado tu contraseña
          </p>
        </form>
      </Dialog>
      <ForgotPassword
        open={forgotPasswordOpen}
        onClose={forgotPasswordCloseHandler}
      />
    </>
  );
};

export default Login;
