import { CodeActionTypes } from "../redux/actionTypes";

export const getCodeByKey = (payload: any) => ({
  type: CodeActionTypes.GET_CODE_BY_KEY,
  payload,
});

export const getCodeByKeySuccess = (payload: any) => ({
  type: CodeActionTypes.GET_CODE_BY_KEY_SUCCESS,
  payload,
});

export const getCollaboratorByCode = (payload: any) => ({
  type: CodeActionTypes.GET_COLLABORATOR_BY_CODE,
  payload,
});

export const getCollaboratorByCodeSuccess = (payload: any) => ({
  type: CodeActionTypes.GET_COLLABORATOR_BY_CODE_SUCCESS,
  payload,
});

export const createVisit = (payload: any) => ({
  type: CodeActionTypes.CREATE_VISIT,
  payload,
});

export const resetCode = (payload: any) => ({
  type: CodeActionTypes.RESET_CODE,
  payload,
});

export const setCodeLoader = (payload: any) => ({
  type: CodeActionTypes.SET_CODE_LOADER,
  payload,
});

export const setDefaultBrandColor = (payload: any) => ({
  type: CodeActionTypes.SET_DEFAULT_BRAND_COLOR,
  payload,
});
