import { ExternalLink, ImageAsset } from "@components";
import { Box, useTheme } from "@mui/material";

import DetailItem from "./DetailItem";

interface DetailsProps {
  applyCollaboratorPanelStyles?: boolean;
}

const Details: React.FC<DetailsProps> = (props) => {
  const theme: any = useTheme();
  const { applyCollaboratorPanelStyles } = props;
  return (
    <div className="flex space-x-3 lg:space-x-0  lg:justify-between px-2 flex-wrap lg:flex-nowrap justify-center lg:px-8 xl:px-20 my-3 py-3 border-b-2 border-gray-100">
      <DetailItem
        icon={
          <ImageAsset
            src="support"
            type="SVG"
            className="w-10"
            style={{
              fill: theme.palette.secondary.main,
            }}
          />
        }
        iconLabel={"ASESORAMIENTO PROFESIONAL & PERSONALIZADO"}
        subtitle={
          "Ponemos a tu disposición a un equipo de profesionales especializados que te ayudarán a elegir tu seguro, con las coberturas que mejor se adapten a la duración y el destino de tu viaje"
        }
      />
      <DetailItem
        icon={
          <ImageAsset
            src="coverage"
            type="SVG"
            className="w-10"
            style={{
              fill: theme.palette.secondary.main,
            }}
          />
        }
        iconLabel={
          <span>
            COBERTURA TOTAL FRENTE AL{" "}
            <ExternalLink
              disabled={applyCollaboratorPanelStyles}
              className="underline"
              href="https://travelfine.es/seguro-de-viaje-covid-19/"
            >
              <Box
                className="transition duration-300 ease-in-out"
                sx={{
                  textDecoration: "underline",
                  "&:hover": {
                    color: "secondary.main",
                  },
                }}
              >
                COVID-19
              </Box>
            </ExternalLink>
          </span>
        }
        subtitle={
          "Para viajar seguro, incluimos cancelación antes del viaje, asistencia médica, hospitalización, cuarentena, PCR y regreso anticipado por hospitalización o fallecimiento de un familiar por COVID-19*"
        }
      />
      <DetailItem
        icon={
          <ImageAsset
            src="app"
            type="SVG"
            className="w-10"
            style={{
              fill: theme.palette.secondary.main,
            }}
          />
        }
        iconLabel={
          <span>
            TECNOLOGÍA E INNOVACIÓN{" "}
            <ExternalLink
              disabled={applyCollaboratorPanelStyles}
              href="https://travelfine.es/app-travelfine/"
            >
              <Box
                className="transition duration-300 ease-in-out"
                sx={{
                  textDecoration: "underline",
                  "&:hover": {
                    color: "secondary.main",
                  },
                }}
              >
                APP TRAVELFINE{" "}
              </Box>
            </ExternalLink>
          </span>
        }
        subtitle={
          "Conectados a ti. Servicio multicanal, a través de WhatsApp, Chat Box, o App, para acceder a tus pólizas, realizar llamadas médicas gratuitas o gestionar tus incidencias en un solo click."
        }
      />
      <DetailItem
        icon={
          <ImageAsset
            src="noAdvance"
            type="SVG"
            className="w-10"
            style={{
              fill: theme.palette.secondary.main,
            }}
          />
        }
        iconLabel={"SIN FRANQUICIAS. SIN ADELANTAR DINERO."}
        subtitle={
          "No pagarás nada por adelantado, eliminamos las franquicias en todas nuestras pólizas. Nosotros nos ocupamos de todo: asistencia en tu mismo idioma, disponible 24h y 365 días al año"
        }
      />
      <DetailItem
        icon={
          <ImageAsset
            src="guarantee"
            type="SVG"
            className="w-10"
            style={{
              fill: theme.palette.secondary.main,
            }}
          />
        }
        iconLabel={"GARANTÍA DE DEVOLUCIÓN & CAMBIO DE FECHAS"}
        subtitle={
          "Siempre a tu lado. Te devolvemos el total pagado por tu seguro, si no iniciaste el viaje, incurrido en gastos o contratado la cobertura de cancelación, además modifica tus fechas de viaje sin gastos extras."
        }
      />
    </div>
  );
};

export default Details;
