import { HEALTH_INSURANCE_CODE, HEALTH_INSURANCE_MJ_CODE, HEALTH_INSURANCE_TITLE } from "@constants/constants";

export const travellerList = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "6",
    value: 6,
  },
  {
    label: "7",
    value: 7,
  },
  {
    label: "8",
    value: 8,
  },
  {
    label: "9",
    value: 9,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "11",
    value: 11,
  },
  {
    label: "12",
    value: 12,
  },
  {
    label: "13",
    value: 13,
  },
  {
    label: "14",
    value: 14,
  },
  {
    label: "15",
    value: 15,
  },
  {
    label: "16",
    value: 16,
  },
  {
    label: "17",
    value: 17,
  },
  {
    label: "18",
    value: 18,
  },
  {
    label: "19",
    value: 19,
  },
  {
    label: "20",
    value: 20,
  },
  {
    label: "21",
    value: 21,
  },
  {
    label: "22",
    value: 22,
  },
  {
    label: "23",
    value: 23,
  },
  {
    label: "24",
    value: 24,
  },
  {
    label: "25",
    value: 25,
  },
  {
    label: "26",
    value: 26,
  },
  {
    label: "27",
    value: 27,
  },
  {
    label: "28",
    value: 28,
  },
  {
    label: "29",
    value: 29,
  },
  {
    label: "30",
    value: 30,
  },
  {
    label: "31",
    value: 31,
  },
  {
    label: "32",
    value: 32,
  },
  {
    label: "33",
    value: 33,
  },
  {
    label: "34",
    value: 34,
  },
  {
    label: "35",
    value: 35,
  },
  {
    label: "36",
    value: 36,
  },
  {
    label: "37",
    value: 37,
  },
  {
    label: "38",
    value: 38,
  },
  {
    label: "39",
    value: 39,
  },
  {
    label: "40",
    value: 40,
  },
  {
    label: "41",
    value: 41,
  },
  {
    label: "42",
    value: 42,
  },
  {
    label: "43",
    value: 43,
  },
  {
    label: "44",
    value: 44,
  },
  {
    label: "45",
    value: 45,
  },
  {
    label: "46",
    value: 46,
  },
  {
    label: "47",
    value: 47,
  },
  {
    label: "48",
    value: 48,
  },
  {
    label: "49",
    value: 49,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "51",
    value: 51,
  },
  {
    label: "52",
    value: 52,
  },
  {
    label: "53",
    value: 53,
  },
  {
    label: "54",
    value: 54,
  },
  {
    label: "55",
    value: 55,
  },
  {
    label: "56",
    value: 56,
  },
  {
    label: "57",
    value: 57,
  },
  {
    label: "58",
    value: 58,
  },
  {
    label: "59",
    value: 59,
  },
  {
    label: "60",
    value: 60,
  },
  {
    label: "61",
    value: 61,
  },
  {
    label: "62",
    value: 62,
  },
  {
    label: "63",
    value: 63,
  },
  {
    label: "64",
    value: 64,
  },
  {
    label: "65",
    value: 65,
  },
  {
    label: "66",
    value: 66,
  },
  {
    label: "67",
    value: 67,
  },
  {
    label: "68",
    value: 68,
  },
  {
    label: "69",
    value: 69,
  },
  {
    label: "70",
    value: 70,
  },
  {
    label: "71",
    value: 71,
  },
  {
    label: "72",
    value: 72,
  },
  {
    label: "73",
    value: 73,
  },
  {
    label: "74",
    value: 74,
  },
  {
    label: "75",
    value: 75,
  },
  {
    label: "76",
    value: 76,
  },
  {
    label: "77",
    value: 77,
  },
  {
    label: "78",
    value: 78,
  },
  {
    label: "79",
    value: 79,
  },
  {
    label: "80",
    value: 80,
  },
  {
    label: "81",
    value: 81,
  },
  {
    label: "82",
    value: 82,
  },
  {
    label: "83",
    value: 83,
  },
  {
    label: "84",
    value: 84,
  },
  {
    label: "85",
    value: 85,
  },
  {
    label: "86",
    value: 86,
  },
  {
    label: "87",
    value: 87,
  },
  {
    label: "88",
    value: 88,
  },
  {
    label: "89",
    value: 89,
  },
  {
    label: "90",
    value: 90,
  },
  {
    label: "91",
    value: 91,
  },
  {
    label: "92",
    value: 92,
  },
  {
    label: "93",
    value: 93,
  },
  {
    label: "94",
    value: 94,
  },
  {
    label: "95",
    value: 95,
  },
  {
    label: "96",
    value: 96,
  },
  {
    label: "97",
    value: 97,
  },
  {
    label: "98",
    value: 98,
  },
  {
    label: "99",
    value: 99,
  },
  {
    label: "100",
    value: 100,
  },
  {
    label: "101",
    value: 101,
  },
  {
    label: "102",
    value: 102,
  },
  {
    label: "103",
    value: 103,
  },
  {
    label: "104",
    value: 104,
  },
  {
    label: "105",
    value: 105,
  },
  {
    label: "106",
    value: 106,
  },
  {
    label: "107",
    value: 107,
  },
  {
    label: "108",
    value: 108,
  },
  {
    label: "109",
    value: 109,
  },
  {
    label: "110",
    value: 110,
  },
  {
    label: "111",
    value: 111,
  },
  {
    label: "112",
    value: 112,
  },
  {
    label: "113",
    value: 113,
  },
  {
    label: "114",
    value: 114,
  },
  {
    label: "115",
    value: 115,
  },
  {
    label: "116",
    value: 116,
  },
  {
    label: "117",
    value: 117,
  },
  {
    label: "118",
    value: 118,
  },
  {
    label: "119",
    value: 119,
  },
  {
    label: "120",
    value: 120,
  },
  {
    label: "121",
    value: 121,
  },
  {
    label: "122",
    value: 122,
  },
  {
    label: "123",
    value: 123,
  },
  {
    label: "124",
    value: 124,
  },
  {
    label: "125",
    value: 125,
  },
  {
    label: "126",
    value: 126,
  },
  {
    label: "127",
    value: 127,
  },
  {
    label: "128",
    value: 128,
  },
  {
    label: "129",
    value: 129,
  },
  {
    label: "130",
    value: 130,
  },
  {
    label: "131",
    value: 131,
  },
  {
    label: "132",
    value: 132,
  },
  {
    label: "133",
    value: 133,
  },
  {
    label: "134",
    value: 134,
  },
  {
    label: "135",
    value: 135,
  },
  {
    label: "136",
    value: 136,
  },
  {
    label: "137",
    value: 137,
  },
  {
    label: "138",
    value: 138,
  },
  {
    label: "139",
    value: 139,
  },
  {
    label: "140",
    value: 140,
  },
  {
    label: "141",
    value: 141,
  },
  {
    label: "142",
    value: 142,
  },
  {
    label: "143",
    value: 143,
  },
  {
    label: "144",
    value: 144,
  },
  {
    label: "145",
    value: 145,
  },
  {
    label: "146",
    value: 146,
  },
  {
    label: "147",
    value: 147,
  },
  {
    label: "148",
    value: 148,
  },
  {
    label: "149",
    value: 149,
  },
  {
    label: "150",
    value: 150,
  },
  {
    label: "151",
    value: 151,
  },
  {
    label: "152",
    value: 152,
  },
  {
    label: "153",
    value: 153,
  },
  {
    label: "154",
    value: 154,
  },
  {
    label: "155",
    value: 155,
  },
  {
    label: "156",
    value: 156,
  },
  {
    label: "157",
    value: 157,
  },
  {
    label: "158",
    value: 158,
  },
  {
    label: "159",
    value: 159,
  },
  {
    label: "160",
    value: 160,
  },
  {
    label: "161",
    value: 161,
  },
  {
    label: "162",
    value: 162,
  },
  {
    label: "163",
    value: 163,
  },
  {
    label: "164",
    value: 164,
  },
  {
    label: "165",
    value: 165,
  },
  {
    label: "166",
    value: 166,
  },
  {
    label: "167",
    value: 167,
  },
  {
    label: "168",
    value: 168,
  },
  {
    label: "169",
    value: 169,
  },
  {
    label: "170",
    value: 170,
  },
  {
    label: "171",
    value: 171,
  },
  {
    label: "172",
    value: 172,
  },
  {
    label: "173",
    value: 173,
  },
  {
    label: "174",
    value: 174,
  },
  {
    label: "175",
    value: 175,
  },
  {
    label: "176",
    value: 176,
  },
  {
    label: "177",
    value: 177,
  },
  {
    label: "178",
    value: 178,
  },
  {
    label: "179",
    value: 179,
  },
  {
    label: "180",
    value: 180,
  },
  {
    label: "181",
    value: 181,
  },
  {
    label: "182",
    value: 182,
  },
  {
    label: "183",
    value: 183,
  },
  {
    label: "184",
    value: 184,
  },
  {
    label: "185",
    value: 185,
  },
  {
    label: "186",
    value: 186,
  },
  {
    label: "187",
    value: 187,
  },
  {
    label: "188",
    value: 188,
  },
  {
    label: "189",
    value: 189,
  },
  {
    label: "190",
    value: 190,
  },
  {
    label: "191",
    value: 191,
  },
  {
    label: "192",
    value: 192,
  },
  {
    label: "193",
    value: 193,
  },
  {
    label: "194",
    value: 194,
  },
  {
    label: "195",
    value: 195,
  },
  {
    label: "196",
    value: 196,
  },
  {
    label: "197",
    value: 197,
  },
  {
    label: "198",
    value: 198,
  },
  {
    label: "199",
    value: 199,
  },
  {
    label: "200",
    value: 200,
  },
];

export const modalityList = [
  {
    label: "Plus",
    value: "Plus",
  },
  {
    label: "Premium",
    value: "Premium",
  },
  {
    label: "Excelent",
    value: "Excelent",
  },
  {
    label: "Vip",
    value: "Vip",
  },
];

export const PRODUCT_PROVIDERS = {
  IMA: "IMA",
  IRIS: "IRIS GLOBAL",
  AIG: "AIG",
  CASER: "CASER",
  ADESLAS: "ADESLAS",
  ASISA: "ASISA",
  ASSISTNED: "ASSISTNED",
  ARAG: "ARAG",
};

export const originList = [
  {
    label: "España y Andorra",
    value: "España y Andorra",
    region: "europa",
    code: "ES",
    groupTitle: "Ámbito Global",
  },
  {
    label: "Europa",
    value: "Europa",
    region: "europa",
    code: "EU",
    groupTitle: "Ámbito Global",
  },
  {
    label: "Mundo",
    value: "Mundo",
    code: "WR",
    groupTitle: "Ámbito Global",
  },
  {
    label: "Afganistán",
    value: "Afganistán",
    code: "AF",
    groupTitle: "Todos los países",
  },
  {
    label: "Albania",
    value: "Albania",
    region: "europa",
    code: "AL",
    groupTitle: "Todos los países",
  },
  {
    label: "Alemania",
    value: "Alemania",
    region: "europa",
    code: "DE",
    groupTitle: "Todos los países",
  },
  {
    label: "Andorra",
    value: "Andorra",
    region: "europa",
    code: "AD",
    groupTitle: "Todos los países",
  },
  {
    label: "Angola",
    value: "Angola",
    code: "AO",
    groupTitle: "Todos los países",
  },
  {
    label: "Anguila",
    value: "Anguila",
    code: "AI",
    groupTitle: "Todos los países",
  },
  {
    label: "Antigua y Barbuda",
    value: "Antigua y Barbuda",
    code: "AG",
    groupTitle: "Todos los países",
  },
  {
    label: "Antártida",
    value: "Antártida",
    code: "AQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Arabia Saudita",
    value: "Arabia Saudita",
    code: "SA",
    groupTitle: "Todos los países",
  },
  {
    label: "Argelia",
    value: "Argelia",
    region: "europa",
    code: "DZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Argentina",
    value: "Argentina",
    code: "AR",
    groupTitle: "Todos los países",
  },
  {
    label: "Armenia",
    value: "Armenia",
    code: "AM",
    groupTitle: "Todos los países",
  },
  {
    label: "Aruba",
    value: "Aruba",
    code: "AW",
    groupTitle: "Todos los países",
  },
  {
    label: "Australia",
    value: "Australia",
    code: "AU",
    groupTitle: "Todos los países",
  },
  {
    label: "Austria",
    value: "Austria",
    region: "europa",
    code: "AT",
    groupTitle: "Todos los países",
  },
  {
    label: "Azerbaiyán",
    value: "Azerbaiyán",
    code: "AZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Bahamas",
    value: "Bahamas",
    code: "BS",
    groupTitle: "Todos los países",
  },
  {
    label: "Bahrein",
    value: "Bahrein",
    code: "BH",
    groupTitle: "Todos los países",
  },
  {
    label: "Bangladesh",
    value: "Bangladesh",
    code: "BD",
    groupTitle: "Todos los países",
  },
  {
    label: "Barbados",
    value: "Barbados",
    code: "BB",
    groupTitle: "Todos los países",
  },
  {
    label: "Belice",
    value: "Belice",
    code: "BZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Benín",
    value: "Benín",
    code: "BJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Bhután",
    value: "Bhután",
    code: "BT",
    groupTitle: "Todos los países",
  },
  {
    label: "Bielorrusia",
    value: "Bielorrusia",
    region: "europa",
    code: "BY",
    groupTitle: "Todos los países",
  },
  {
    label: "Birmania",
    value: "Birmania",
    code: "MM",
    groupTitle: "Todos los países",
  },
  {
    label: "Bolivia",
    value: "Bolivia",
    code: "BO",
    groupTitle: "Todos los países",
  },
  {
    label: "Bonaire, San Eustaquio y Saba",
    value: "Bonaire, San Eustaquio y Saba",
    code: "BQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Bosnia y Herzegovina",
    value: "Bosnia y Herzegovina",
    region: "europa",
    code: "BA",
    groupTitle: "Todos los países",
  },
  {
    label: "Botsuana",
    value: "Botsuana",
    code: "BW",
    groupTitle: "Todos los países",
  },
  {
    label: "Brasil",
    value: "Brasil",
    code: "BR",
    groupTitle: "Todos los países",
  },
  {
    label: "Brunéi",
    value: "Brunéi",
    code: "BN",
    groupTitle: "Todos los países",
  },
  {
    label: "Bulgaria",
    value: "Bulgaria",
    region: "europa",
    code: "BG",
    groupTitle: "Todos los países",
  },
  {
    label: "Burkina Faso",
    value: "Burkina Faso",
    code: "BF",
    groupTitle: "Todos los países",
  },
  {
    label: "Burundi",
    value: "Burundi",
    code: "BI",
    groupTitle: "Todos los países",
  },
  {
    label: "Bélgica",
    value: "Bélgica",
    region: "europa",
    code: "BE",
    groupTitle: "Todos los países",
  },
  {
    label: "Cabo Verde",
    value: "Cabo Verde",
    code: "CV",
    groupTitle: "Todos los países",
  },
  {
    label: "Camboya",
    value: "Camboya",
    code: "KH",
    groupTitle: "Todos los países",
  },
  {
    label: "Camerún",
    value: "Camerún",
    code: "CM",
    groupTitle: "Todos los países",
  },
  {
    label: "Canadá",
    value: "Canadá",
    code: "CA",
    groupTitle: "Todos los países",
  },
  {
    label: "Ceuta y Melilla",
    value: "Ceuta y Melilla",
    code: "EA",
    groupTitle: "Todos los países",
  },
  {
    label: "Chad",
    value: "Chad",
    code: "TD",
    groupTitle: "Todos los países",
  },
  {
    label: "Chile",
    value: "Chile",
    code: "CL",
    groupTitle: "Todos los países",
  },
  {
    label: "China",
    value: "China",
    code: "CN",
    groupTitle: "Todos los países",
  },
  {
    label: "Chipre",
    value: "Chipre",
    code: "CY",
    groupTitle: "Todos los países",
  },
  {
    label: "Ciudad del Vaticano",
    value: "Ciudad del Vaticano",
    code: "VA",
    groupTitle: "Todos los países",
  },
  {
    label: "Colombia",
    value: "Colombia",
    code: "CO",
    groupTitle: "Todos los países",
  },
  {
    label: "Comoras",
    value: "Comoras",
    code: "KM",
    groupTitle: "Todos los países",
  },
  {
    label: "Corea del Norte",
    value: "Corea del Norte",
    code: "KP",
    groupTitle: "Todos los países",
  },
  {
    label: "Corea del Sur",
    value: "Corea del Sur",
    code: "KR",
    groupTitle: "Todos los países",
  },
  {
    label: "Costa de Marfil",
    value: "Costa de Marfil",
    code: "CI",
    groupTitle: "Todos los países",
  },
  {
    label: "Costa Rica",
    value: "Costa Rica",
    code: "CR",
    groupTitle: "Todos los países",
  },
  {
    label: "Croacia",
    value: "Croacia",
    region: "europa",
    code: "HR",
    groupTitle: "Todos los países",
  },
  {
    label: "Cuba",
    value: "Cuba",
    code: "CU",
    groupTitle: "Todos los países",
  },
  {
    label: "Curazao",
    value: "Curazao",
    code: "CW",
    groupTitle: "Todos los países",
  },
  {
    label: "Diego García",
    value: "Diego García",
    code: "DG",
    groupTitle: "Todos los países",
  },
  {
    label: "Dinamarca",
    value: "Dinamarca",
    region: "europa",
    code: "DK",
    groupTitle: "Todos los países",
  },
  {
    label: "Dominica",
    value: "Dominica",
    code: "DM",
    groupTitle: "Todos los países",
  },
  {
    label: "Ecuador",
    value: "Ecuador",
    code: "EC",
    groupTitle: "Todos los países",
  },
  {
    label: "Egipto",
    value: "Egipto",
    region: "europa",
    code: "EG",
    groupTitle: "Todos los países",
  },
  {
    label: "El Salvador",
    value: "El Salvador",
    code: "SV",
    groupTitle: "Todos los países",
  },
  {
    label: "Emiratos Árabes Unidos",
    value: "Emiratos Árabes Unidos",
    code: "AE",
    groupTitle: "Todos los países",
  },
  {
    label: "Eritrea",
    value: "Eritrea",
    code: "ER",
    groupTitle: "Todos los países",
  },
  {
    label: "Eslovaquia",
    value: "Eslovaquia",
    region: "europa",
    code: "SK",
    groupTitle: "Todos los países",
  },
  {
    label: "Eslovenia",
    value: "Eslovenia",
    region: "europa",
    code: "SI",
    groupTitle: "Todos los países",
  },
  {
    label: "Estados Unidos de América",
    value: "Estados Unidos de América",
    code: "US",
    groupTitle: "Todos los países",
  },
  {
    label: "Estonia",
    value: "Estonia",
    region: "europa",
    code: "EE",
    groupTitle: "Todos los países",
  },
  {
    label: "Etiopía",
    value: "Etiopía",
    code: "ET",
    groupTitle: "Todos los países",
  },
  {
    label: "Filipinas",
    value: "Filipinas",
    code: "PH",
    groupTitle: "Todos los países",
  },
  {
    label: "Finlandia",
    value: "Finlandia",
    region: "europa",
    code: "FI",
    groupTitle: "Todos los países",
  },
  {
    label: "Fiyi",
    value: "Fiyi",
    code: "FJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Francia",
    value: "Francia",
    region: "europa",
    code: "FR",
    groupTitle: "Todos los países",
  },
  {
    label: "Gabón",
    value: "Gabón",
    code: "GA",
    groupTitle: "Todos los países",
  },
  {
    label: "Gambia",
    value: "Gambia",
    code: "GM",
    groupTitle: "Todos los países",
  },
  {
    label: "Georgia",
    value: "Georgia",
    code: "GE",
    groupTitle: "Todos los países",
  },
  {
    label: "Ghana",
    value: "Ghana",
    code: "GH",
    groupTitle: "Todos los países",
  },
  {
    label: "Gibraltar",
    value: "Gibraltar",
    region: "europa",
    code: "GI",
    groupTitle: "Todos los países",
  },
  {
    label: "Granada",
    value: "Granada",
    code: "GD",
    groupTitle: "Todos los países",
  },
  {
    label: "Grecia",
    value: "Grecia",
    region: "europa",
    code: "GR",
    groupTitle: "Todos los países",
  },
  {
    label: "Groenlandia",
    value: "Groenlandia",
    code: "GL",
    groupTitle: "Todos los países",
  },
  {
    label: "Guadalupe",
    value: "Guadalupe",
    code: "GP",
    groupTitle: "Todos los países",
  },
  {
    label: "Guam",
    value: "Guam",
    code: "GU",
    groupTitle: "Todos los países",
  },
  {
    label: "Guatemala",
    value: "Guatemala",
    code: "GT",
    groupTitle: "Todos los países",
  },
  {
    label: "Guayana Francesa",
    value: "Guayana Francesa",
    code: "GF",
    groupTitle: "Todos los países",
  },
  {
    label: "Guernsey",
    value: "Guernsey",
    code: "GG",
    groupTitle: "Todos los países",
  },
  {
    label: "Guinea",
    value: "Guinea",
    code: "GN",
    groupTitle: "Todos los países",
  },
  {
    label: "Guinea Ecuatorial",
    value: "Guinea Ecuatorial",
    code: "GQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Guinea-Bissau",
    value: "Guinea-Bissau",
    code: "GW",
    groupTitle: "Todos los países",
  },
  {
    label: "Guyana",
    value: "Guyana",
    code: "GY",
    groupTitle: "Todos los países",
  },
  {
    label: "Haití",
    value: "Haití",
    code: "HT",
    groupTitle: "Todos los países",
  },
  {
    label: "Honduras",
    value: "Honduras",
    code: "HN",
    groupTitle: "Todos los países",
  },
  {
    label: "Hong kong",
    value: "Hong kong",
    code: "HK",
    groupTitle: "Todos los países",
  },
  {
    label: "Hungría",
    value: "Hungría",
    region: "europa",
    code: "HU",
    groupTitle: "Todos los países",
  },
  {
    label: "India",
    value: "India",
    code: "IN",
    groupTitle: "Todos los países",
  },
  {
    label: "Indonesia",
    value: "Indonesia",
    code: "ID",
    groupTitle: "Todos los países",
  },
  {
    label: "Irak",
    value: "Irak",
    code: "IQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Irlanda",
    value: "Irlanda",
    region: "europa",
    code: "IE",
    groupTitle: "Todos los países",
  },
  {
    label: "Irán",
    value: "Irán",
    code: "IR",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Ascensión",
    value: "Isla Ascensión",
    code: "AC",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Bouvet",
    value: "Isla Bouvet",
    code: "BV",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Clipperton",
    value: "Isla Clipperton",
    code: "CP",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla de Man",
    value: "Isla de Man",
    code: "IM",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla de Navidad",
    value: "Isla de Navidad",
    code: "CX",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Norfolk",
    value: "Isla Norfolk",
    code: "NF",
    groupTitle: "Todos los países",
  },
  {
    label: "Islandia",
    value: "Islandia",
    region: "europa",
    code: "IS",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Bermudas",
    value: "Islas Bermudas",
    code: "BM",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Caimán",
    value: "Islas Caimán",
    code: "KY",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Canarias",
    value: "Islas Canarias",
    code: "IC",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Cocos (Keeling)",
    value: "Islas Cocos (Keeling)",
    code: "CC",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Cook",
    value: "Islas Cook",
    code: "CK",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas de Åland",
    value: "Islas de Åland",
    code: "AX",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Feroe",
    value: "Islas Feroe",
    code: "FO",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Georgias del Sur y Sandwich del Sur",
    value: "Islas Georgias del Sur y Sandwich del Sur",
    code: "GS",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Heard y McDonald",
    value: "Islas Heard y McDonald",
    code: "HM",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Maldivas",
    value: "Islas Maldivas",
    code: "MV",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Malvinas",
    value: "Islas Malvinas",
    code: "FK",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Marianas del Norte",
    value: "Islas Marianas del Norte",
    code: "MP",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Marshall",
    value: "Islas Marshall",
    code: "MH",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Pitcairn",
    value: "Islas Pitcairn",
    code: "PN",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Salomón",
    value: "Islas Salomón",
    code: "SB",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Turcas y Caicos",
    value: "Islas Turcas y Caicos",
    code: "TC",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Ultramarinas Menores de Estados Unidos",
    value: "Islas Ultramarinas Menores de Estados Unidos",
    code: "UM",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Vírgenes Británicas",
    value: "Islas Vírgenes Británicas",
    code: "VG",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Vírgenes de los Estados Unidos",
    value: "Islas Vírgenes de los Estados Unidos",
    code: "VI",
    groupTitle: "Todos los países",
  },
  {
    label: "Israel",
    value: "Israel",
    region: "europa",
    code: "IL",
    groupTitle: "Todos los países",
  },
  {
    label: "Italia",
    value: "Italia",
    region: "europa",
    code: "IT",
    groupTitle: "Todos los países",
  },
  {
    label: "Jamaica",
    value: "Jamaica",
    code: "JM",
    groupTitle: "Todos los países",
  },
  {
    label: "Japón",
    value: "Japón",
    code: "JP",
    groupTitle: "Todos los países",
  },
  {
    label: "Jersey",
    value: "Jersey",
    code: "JE",
    groupTitle: "Todos los países",
  },
  {
    label: "Jordania",
    value: "Jordania",
    code: "JO",
    groupTitle: "Todos los países",
  },
  {
    label: "Kazajistán",
    value: "Kazajistán",
    code: "KZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Kenia",
    value: "Kenia",
    code: "KE",
    groupTitle: "Todos los países",
  },
  {
    label: "Kirguistán",
    value: "Kirguistán",
    code: "KG",
    groupTitle: "Todos los países",
  },
  {
    label: "Kiribati",
    value: "Kiribati",
    code: "KI",
    groupTitle: "Todos los países",
  },
  {
    label: "Kosovo",
    value: "Kosovo",
    code: "XK",
    groupTitle: "Todos los países",
  },
  {
    label: "Kuwait",
    value: "Kuwait",
    code: "KW",
    groupTitle: "Todos los países",
  },
  {
    label: "Laos",
    value: "Laos",
    code: "LA",
    groupTitle: "Todos los países",
  },
  {
    label: "Lesoto",
    value: "Lesoto",
    code: "LS",
    groupTitle: "Todos los países",
  },
  {
    label: "Letonia",
    value: "Letonia",
    region: "europa",
    code: "LV",
    groupTitle: "Todos los países",
  },
  {
    label: "Liberia",
    value: "Liberia",
    code: "LR",
    groupTitle: "Todos los países",
  },
  {
    label: "Libia",
    value: "Libia",
    region: "europa",
    code: "LY",
    groupTitle: "Todos los países",
  },
  {
    label: "Liechtenstein",
    value: "Liechtenstein",
    region: "europa",
    code: "LI",
    groupTitle: "Todos los países",
  },
  {
    label: "Lituania",
    value: "Lituania",
    region: "europa",
    code: "LT",
    groupTitle: "Todos los países",
  },
  {
    label: "Luxemburgo",
    value: "Luxemburgo",
    region: "europa",
    code: "LU",
    groupTitle: "Todos los países",
  },
  {
    label: "Líbano",
    value: "Líbano",
    region: "europa",
    code: "LB",
    groupTitle: "Todos los países",
  },
  {
    label: "Macao",
    value: "Macao",
    code: "MO",
    groupTitle: "Todos los países",
  },
  {
    label: "Macedônia",
    value: "Macedônia",
    code: "MK",
    groupTitle: "Todos los países",
  },
  {
    label: "Madagascar",
    value: "Madagascar",
    code: "MG",
    groupTitle: "Todos los países",
  },
  {
    label: "Malasia",
    value: "Malasia",
    code: "MY",
    groupTitle: "Todos los países",
  },
  {
    label: "Malawi",
    value: "Malawi",
    code: "MW",
    groupTitle: "Todos los países",
  },
  {
    label: "Mali",
    value: "Mali",
    code: "ML",
    groupTitle: "Todos los países",
  },
  {
    label: "Malta",
    value: "Malta",
    region: "europa",
    code: "MT",
    groupTitle: "Todos los países",
  },
  {
    label: "Marruecos",
    value: "Marruecos",
    region: "europa",
    code: "MA",
    groupTitle: "Todos los países",
  },
  {
    label: "Martinica",
    value: "Martinica",
    code: "MQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Mauricio",
    value: "Mauricio",
    code: "MU",
    groupTitle: "Todos los países",
  },
  {
    label: "Mauritania",
    value: "Mauritania",
    code: "MR",
    groupTitle: "Todos los países",
  },
  {
    label: "Mayotte",
    value: "Mayotte",
    code: "YT",
    groupTitle: "Todos los países",
  },
  {
    label: "Micronesia",
    value: "Micronesia",
    code: "FM",
    groupTitle: "Todos los países",
  },
  {
    label: "Moldavia",
    value: "Moldavia",
    region: "europa",
    code: "MD",
    groupTitle: "Todos los países",
  },
  {
    label: "Mongolia",
    value: "Mongolia",
    code: "MN",
    groupTitle: "Todos los países",
  },
  {
    label: "Montenegro",
    value: "Montenegro",
    region: "europa",
    code: "ME",
    groupTitle: "Todos los países",
  },
  {
    label: "Montserrat",
    value: "Montserrat",
    code: "MS",
    groupTitle: "Todos los países",
  },
  {
    label: "Mozambique",
    value: "Mozambique",
    code: "MZ",
    groupTitle: "Todos los países",
  },
  {
    label: "México",
    value: "México",
    code: "MX",
    groupTitle: "Todos los países",
  },
  {
    label: "Mónaco",
    value: "Mónaco",
    region: "europa",
    code: "MC",
    groupTitle: "Todos los países",
  },
  {
    label: "Naciones Unidas",
    value: "Naciones Unidas",
    code: "UN",
    groupTitle: "Todos los países",
  },
  {
    label: "Namibia",
    value: "Namibia",
    code: "NA",
    groupTitle: "Todos los países",
  },
  {
    label: "Nauru",
    value: "Nauru",
    code: "NR",
    groupTitle: "Todos los países",
  },
  {
    label: "Nepal",
    value: "Nepal",
    code: "NP",
    groupTitle: "Todos los países",
  },
  {
    label: "Nicaragua",
    value: "Nicaragua",
    code: "NI",
    groupTitle: "Todos los países",
  },
  {
    label: "Niger",
    value: "Niger",
    code: "NE",
    groupTitle: "Todos los países",
  },
  {
    label: "Nigeria",
    value: "Nigeria",
    code: "NG",
    groupTitle: "Todos los países",
  },
  {
    label: "Niue",
    value: "Niue",
    code: "NU",
    groupTitle: "Todos los países",
  },
  {
    label: "Noruega",
    value: "Noruega",
    region: "europa",
    code: "NO",
    groupTitle: "Todos los países",
  },
  {
    label: "Nueva Caledonia",
    value: "Nueva Caledonia",
    code: "NC",
    groupTitle: "Todos los países",
  },
  {
    label: "Nueva Zelanda",
    value: "Nueva Zelanda",
    code: "NZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Omán",
    value: "Omán",
    code: "OM",
    groupTitle: "Todos los países",
  },
  {
    label: "Pakistán",
    value: "Pakistán",
    code: "PK",
    groupTitle: "Todos los países",
  },
  {
    label: "Palau",
    value: "Palau",
    code: "PW",
    groupTitle: "Todos los países",
  },
  {
    label: "Palestina",
    value: "Palestina",
    code: "PS",
    groupTitle: "Todos los países",
  },
  {
    label: "Panamá",
    value: "Panamá",
    code: "PA",
    groupTitle: "Todos los países",
  },
  {
    label: "Papúa Nueva Guinea",
    value: "Papúa Nueva Guinea",
    code: "PG",
    groupTitle: "Todos los países",
  },
  {
    label: "Paraguay",
    value: "Paraguay",
    code: "PY",
    groupTitle: "Todos los países",
  },
  {
    label: "Países Bajos",
    value: "Países Bajos",
    region: "europa",
    code: "NL",
    groupTitle: "Todos los países",
  },
  {
    label: "Perú",
    value: "Perú",
    code: "PE",
    groupTitle: "Todos los países",
  },
  {
    label: "Polinesia Francesa",
    value: "Polinesia Francesa",
    code: "PF",
    groupTitle: "Todos los países",
  },
  {
    label: "Polonia",
    value: "Polonia",
    region: "europa",
    code: "PL",
    groupTitle: "Todos los países",
  },
  {
    label: "Portugal",
    value: "Portugal",
    region: "europa",
    code: "PT",
    groupTitle: "Todos los países",
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
    code: "PR",
    groupTitle: "Todos los países",
  },
  {
    label: "Qatar",
    value: "Qatar",
    code: "QA",
    groupTitle: "Todos los países",
  },
  {
    label: "Reino Unido",
    value: "Reino Unido",
    region: "europa",
    code: "UK",
    groupTitle: "Todos los países",
  },
  {
    label: "Reino Unido de Gran Bretaña e Irlanda del Norte",
    value: "Reino Unido de Gran Bretaña e Irlanda del Norte",
    region: "europa",
    code: "GB",
    groupTitle: "Todos los países",
  },
  {
    label: "República Centroafricana",
    value: "República Centroafricana",
    code: "CF",
    groupTitle: "Todos los países",
  },
  {
    label: "República Checa",
    value: "República Checa",
    region: "europa",
    code: "CZ",
    groupTitle: "Todos los países",
  },
  {
    label: "República de Sudán del Sur",
    value: "República de Sudán del Sur",
    code: "SS",
    groupTitle: "Todos los países",
  },
  {
    label: "República del Congo",
    value: "República del Congo",
    code: "CG",
    groupTitle: "Todos los países",
  },
  {
    label: "República Democrática del Congo",
    value: "República Democrática del Congo",
    code: "CD",
    groupTitle: "Todos los países",
  },
  {
    label: "República Dominicana",
    value: "República Dominicana",
    code: "DO",
    groupTitle: "Todos los países",
  },
  {
    label: "Reunión",
    value: "Reunión",
    code: "RE",
    groupTitle: "Todos los países",
  },
  {
    label: "Ruanda",
    value: "Ruanda",
    code: "RW",
    groupTitle: "Todos los países",
  },
  {
    label: "Rumanía",
    value: "Rumanía",
    code: "RO",
    groupTitle: "Todos los países",
  },
  {
    label: "Rusia",
    value: "Rusia",
    region: "europa",
    code: "RU",
    groupTitle: "Todos los países",
  },
  {
    label: "Sahara Occidental",
    value: "Sahara Occidental",
    code: "EH",
    groupTitle: "Todos los países",
  },
  {
    label: "Samoa",
    value: "Samoa",
    code: "WS",
    groupTitle: "Todos los países",
  },
  {
    label: "Samoa Americana",
    value: "Samoa Americana",
    code: "AS",
    groupTitle: "Todos los países",
  },
  {
    label: "San Bartolomé",
    value: "San Bartolomé",
    code: "BL",
    groupTitle: "Todos los países",
  },
  {
    label: "San Cristóbal y Nieves",
    value: "San Cristóbal y Nieves",
    code: "KN",
    groupTitle: "Todos los países",
  },
  {
    label: "San Marino",
    value: "San Marino",
    region: "europa",
    code: "SM",
    groupTitle: "Todos los países",
  },
  {
    label: "San Martín (Francia)",
    value: "San Martín (Francia)",
    region: "europa",
    code: "MF",
    groupTitle: "Todos los países",
  },
  {
    label: "San Pedro y Miquelón",
    value: "San Pedro y Miquelón",
    code: "PM",
    groupTitle: "Todos los países",
  },
  {
    label: "San Vicente y las Granadinas",
    value: "San Vicente y las Granadinas",
    code: "VC",
    groupTitle: "Todos los países",
  },
  {
    label: "Santa Elena",
    value: "Santa Elena",
    code: "SH",
    groupTitle: "Todos los países",
  },
  {
    label: "Santa Lucía",
    value: "Santa Lucía",
    code: "LC",
    groupTitle: "Todos los países",
  },
  {
    label: "Santo Tomé y Príncipe",
    value: "Santo Tomé y Príncipe",
    code: "ST",
    groupTitle: "Todos los países",
  },
  {
    label: "Senegal",
    value: "Senegal",
    code: "SN",
    groupTitle: "Todos los países",
  },
  {
    label: "Serbia",
    value: "Serbia",
    region: "europa",
    code: "RS",
    groupTitle: "Todos los países",
  },
  {
    label: "Seychelles",
    value: "Seychelles",
    code: "SC",
    groupTitle: "Todos los países",
  },
  {
    label: "Sierra Leona",
    value: "Sierra Leona",
    code: "SL",
    groupTitle: "Todos los países",
  },
  {
    label: "Singapur",
    value: "Singapur",
    code: "SG",
    groupTitle: "Todos los países",
  },
  {
    label: "Sint Maarten",
    value: "Sint Maarten",
    code: "SX",
    groupTitle: "Todos los países",
  },
  {
    label: "Siria",
    value: "Siria",
    code: "SY",
    groupTitle: "Todos los países",
  },
  {
    label: "Somalia",
    value: "Somalia",
    code: "SO",
    groupTitle: "Todos los países",
  },
  {
    label: "Sri lanka",
    value: "Sri lanka",
    code: "LK",
    groupTitle: "Todos los países",
  },
  {
    label: "Sudáfrica",
    value: "Sudáfrica",
    code: "ZA",
    groupTitle: "Todos los países",
  },
  {
    label: "Sudán",
    value: "Sudán",
    code: "SD",
    groupTitle: "Todos los países",
  },
  {
    label: "Suecia",
    value: "Suecia",
    region: "europa",
    code: "SE",
    groupTitle: "Todos los países",
  },
  {
    label: "Suiza",
    value: "Suiza",
    region: "europa",
    code: "CH",
    groupTitle: "Todos los países",
  },
  {
    label: "Surinám",
    value: "Surinám",
    code: "SR",
    groupTitle: "Todos los países",
  },
  {
    label: "Svalbard y Jan Mayen",
    value: "Svalbard y Jan Mayen",
    code: "SJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Swazilandia",
    value: "Swazilandia",
    code: "SZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Tailandia",
    value: "Tailandia",
    code: "TH",
    groupTitle: "Todos los países",
  },
  {
    label: "Taiwán",
    value: "Taiwán",
    code: "TW",
    groupTitle: "Todos los países",
  },
  {
    label: "Tanzania",
    value: "Tanzania",
    code: "TZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Tayikistán",
    value: "Tayikistán",
    code: "TJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Territorio Británico del Océano Índico",
    value: "Territorio Británico del Océano Índico",
    code: "IO",
    groupTitle: "Todos los países",
  },
  {
    label: "Territorios Australes y Antárticas Franceses",
    value: "Territorios Australes y Antárticas Franceses",
    code: "TF",
    groupTitle: "Todos los países",
  },
  {
    label: "Timor Oriental",
    value: "Timor Oriental",
    code: "TL",
    groupTitle: "Todos los países",
  },
  {
    label: "Togo",
    value: "Togo",
    code: "TG",
    groupTitle: "Todos los países",
  },
  {
    label: "Tokelau",
    value: "Tokelau",
    code: "TK",
    groupTitle: "Todos los países",
  },
  {
    label: "Tonga",
    value: "Tonga",
    code: "TO",
    groupTitle: "Todos los países",
  },
  {
    label: "Trinidad y Tobago",
    value: "Trinidad y Tobago",
    code: "TT",
    groupTitle: "Todos los países",
  },
  {
    label: "Tristán de Acuña",
    value: "Tristán de Acuña",
    code: "TA",
    groupTitle: "Todos los países",
  },
  {
    label: "Tunez",
    value: "Tunez",
    region: "europa",
    code: "TN",
    groupTitle: "Todos los países",
  },
  {
    label: "Turkmenistán",
    value: "Turkmenistán",
    code: "TM",
    groupTitle: "Todos los países",
  },
  {
    label: "Turquía",
    value: "Turquía",
    code: "TR",
    groupTitle: "Todos los países",
  },
  {
    label: "Tuvalu",
    value: "Tuvalu",
    code: "TV",
    groupTitle: "Todos los países",
  },
  {
    label: "Ucrania",
    value: "Ucrania",
    region: "europa",
    code: "UA",
    groupTitle: "Todos los países",
  },
  {
    label: "Uganda",
    value: "Uganda",
    code: "UG",
    groupTitle: "Todos los países",
  },
  {
    label: "Unión Soviética",
    value: "Unión Soviética",
    code: "SU",
    groupTitle: "Todos los países",
  },
  {
    label: "Uruguay",
    value: "Uruguay",
    code: "UY",
    groupTitle: "Todos los países",
  },
  {
    label: "Uzbekistán",
    value: "Uzbekistán",
    code: "UZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Vanuatu",
    value: "Vanuatu",
    code: "VU",
    groupTitle: "Todos los países",
  },
  {
    label: "Venezuela",
    value: "Venezuela",
    code: "VE",
    groupTitle: "Todos los países",
  },
  {
    label: "Vietnam",
    value: "Vietnam",
    code: "VN",
    groupTitle: "Todos los países",
  },
  {
    label: "Wallis y Futuna",
    value: "Wallis y Futuna",
    code: "WF",
    groupTitle: "Todos los países",
  },
  {
    label: "Yemen",
    value: "Yemen",
    code: "YE",
    groupTitle: "Todos los países",
  },
  {
    label: "Yibuti",
    value: "Yibuti",
    code: "DJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Zambia",
    value: "Zambia",
    code: "ZM",
    groupTitle: "Todos los países",
  },
  {
    label: "Zimbabue",
    value: "Zimbabue",
    code: "ZW",
    groupTitle: "Todos los países",
  },
];

export const getCountriesListSpanish = (categoryKey?: string, destination?: any, setValue?: any, filterTitle: any = null) => {
  if (categoryKey === "ESTUDIANTES" || categoryKey === "WORKANDHOLIDAY") {
    return _countriesListSpanish.map((country) => {
      if (country.code === "WR_EX_USA")
        return {
          ...country,
          label: "Mundo (Excluido EEUU, Canadá, Australia)",
          value: "Mundo (Excluido EEUU, Canadá, Australia)",
        };

      return country;
    });
  }

  const isExistHealth = (filterTitle?.toUpperCase()?.includes(HEALTH_INSURANCE_TITLE.toUpperCase()) || (categoryKey === HEALTH_INSURANCE_CODE ||
    categoryKey === HEALTH_INSURANCE_MJ_CODE));
  if (isExistHealth && destination) {
    const destinationList = _countriesListSpanish.filter((country: any) => country?.value.includes("España"));
    setValue('destination', destinationList.find((destiny: any) => destination.value === destiny.value
    ) ?? destinationList[0])
    return destinationList;
  }

  return _countriesListSpanish;
};

export const getOriginCountry = (setValue: any, categoryKey?: string, countryList?: any[], filterOrigin: any = null, filterTitle: any = null) => {
  let countries = originList;
  const isExistHealth = (filterTitle?.toUpperCase()?.includes(HEALTH_INSURANCE_TITLE.toUpperCase()) || (categoryKey === HEALTH_INSURANCE_CODE ||
    categoryKey === HEALTH_INSURANCE_MJ_CODE));
  if (isExistHealth && countryList) {
    countries = countryList.filter((country) => !country?.value.includes('España'));
    const origin: any = countries.find((country: any) => country?.value === filterOrigin?.value) ?? countries[0];
    setValue('origin', origin);
  }

  return countries;
};

enum CaserRegion {
  Spain = "spain",
  Europe = "europe",
  // If "caserRegion" field does not exist, it is considered "world" for backend purposes
}

const _countriesListSpanish = [
  {
    label: "Mundo (Excluido EEUU)",
    value: "Mundo (Excluido EEUU)",
    code: "WR_EX_USA",
    groupTitle: "Si viajas a diferentes países",
  },
  {
    label: "Todo el mundo",
    value: "Todo el mundo",
    code: "WR",
    groupTitle: "Si viajas a diferentes países",
  },
  {
    label: "Europa",
    value: "Europa",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "EU",
    groupTitle: "Si viajas a diferentes países",
  },
  {
    label: "España",
    value: "España",
    region: "europa",
    caserRegion: CaserRegion.Spain,
    code: "ES",
    groupTitle: "Si viajas a diferentes países",
  },
  {
    label: "Afganistán",
    value: "Afganistán",
    code: "AF",
    groupTitle: "Todos los países",
  },
  {
    label: "Albania",
    value: "Albania",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "AL",
    groupTitle: "Todos los países",
  },
  {
    label: "Alemania",
    value: "Alemania",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "DE",
    groupTitle: "Todos los países",
  },
  {
    label: "Andorra",
    value: "Andorra",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "AD",
    groupTitle: "Todos los países",
  },
  {
    label: "Angola",
    value: "Angola",
    code: "AO",
    groupTitle: "Todos los países",
  },
  {
    label: "Anguila",
    value: "Anguila",
    code: "AI",
    groupTitle: "Todos los países",
  },
  {
    label: "Antigua y Barbuda",
    value: "Antigua y Barbuda",
    code: "AG",
    groupTitle: "Todos los países",
  },
  {
    label: "Antártida",
    value: "Antártida",
    code: "AQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Arabia Saudita",
    value: "Arabia Saudita",
    code: "SA",
    groupTitle: "Todos los países",
  },
  {
    label: "Argelia",
    value: "Argelia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "DZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Argentina",
    value: "Argentina",
    code: "AR",
    groupTitle: "Todos los países",
  },
  {
    label: "Armenia",
    value: "Armenia",
    code: "AM",
    groupTitle: "Todos los países",
  },
  {
    label: "Aruba",
    value: "Aruba",
    code: "AW",
    groupTitle: "Todos los países",
  },
  {
    label: "Australia",
    value: "Australia",
    code: "AU",
    groupTitle: "Todos los países",
  },
  {
    label: "Austria",
    value: "Austria",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "AT",
    groupTitle: "Todos los países",
  },
  {
    label: "Azerbaiyán",
    value: "Azerbaiyán",
    code: "AZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Bahamas",
    value: "Bahamas",
    code: "BS",
    groupTitle: "Todos los países",
  },
  {
    label: "Bahrein",
    value: "Bahrein",
    code: "BH",
    groupTitle: "Todos los países",
  },
  {
    label: "Bangladesh",
    value: "Bangladesh",
    code: "BD",
    groupTitle: "Todos los países",
  },
  {
    label: "Barbados",
    value: "Barbados",
    code: "BB",
    groupTitle: "Todos los países",
  },
  {
    label: "Belice",
    value: "Belice",
    code: "BZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Benín",
    value: "Benín",
    code: "BJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Bhután",
    value: "Bhután",
    code: "BT",
    groupTitle: "Todos los países",
  },
  {
    label: "Bielorrusia",
    value: "Bielorrusia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "BY",
    groupTitle: "Todos los países",
  },
  {
    label: "Birmania",
    value: "Birmania",
    code: "MM",
    groupTitle: "Todos los países",
  },
  {
    label: "Bolivia",
    value: "Bolivia",
    code: "BO",
    groupTitle: "Todos los países",
  },
  {
    label: "Bonaire, San Eustaquio y Saba",
    value: "Bonaire, San Eustaquio y Saba",
    code: "BQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Bosnia y Herzegovina",
    value: "Bosnia y Herzegovina",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "BA",
    groupTitle: "Todos los países",
  },
  {
    label: "Botsuana",
    value: "Botsuana",
    code: "BW",
    groupTitle: "Todos los países",
  },
  {
    label: "Brasil",
    value: "Brasil",
    code: "BR",
    groupTitle: "Todos los países",
  },
  {
    label: "Brunéi",
    value: "Brunéi",
    code: "BN",
    groupTitle: "Todos los países",
  },
  {
    label: "Bulgaria",
    value: "Bulgaria",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "BG",
    groupTitle: "Todos los países",
  },
  {
    label: "Burkina Faso",
    value: "Burkina Faso",
    code: "BF",
    groupTitle: "Todos los países",
  },
  {
    label: "Burundi",
    value: "Burundi",
    code: "BI",
    groupTitle: "Todos los países",
  },
  {
    label: "Bélgica",
    value: "Bélgica",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "BE",
    groupTitle: "Todos los países",
  },
  {
    label: "Cabo Verde",
    value: "Cabo Verde",
    code: "CV",
    groupTitle: "Todos los países",
  },
  {
    label: "Camboya",
    value: "Camboya",
    code: "KH",
    groupTitle: "Todos los países",
  },
  {
    label: "Camerún",
    value: "Camerún",
    code: "CM",
    groupTitle: "Todos los países",
  },
  {
    label: "Canadá",
    value: "Canadá",
    code: "CA",
    groupTitle: "Todos los países",
  },
  {
    label: "Ceuta y Melilla",
    value: "Ceuta y Melilla",
    caserRegion: CaserRegion.Spain,
    code: "EA",
    groupTitle: "Todos los países",
  },
  {
    label: "Chad",
    value: "Chad",
    code: "TD",
    groupTitle: "Todos los países",
  },
  {
    label: "Chile",
    value: "Chile",
    code: "CL",
    groupTitle: "Todos los países",
  },
  {
    label: "China",
    value: "China",
    code: "CN",
    groupTitle: "Todos los países",
  },
  {
    label: "Chipre",
    value: "Chipre",
    code: "CY",
    groupTitle: "Todos los países",
  },
  {
    label: "Ciudad del Vaticano",
    value: "Ciudad del Vaticano",
    caserRegion: CaserRegion.Europe,
    code: "VA",
    groupTitle: "Todos los países",
  },
  {
    label: "Colombia",
    value: "Colombia",
    code: "CO",
    groupTitle: "Todos los países",
  },
  {
    label: "Comoras",
    value: "Comoras",
    code: "KM",
    groupTitle: "Todos los países",
  },
  {
    label: "Corea del Norte",
    value: "Corea del Norte",
    code: "KP",
    groupTitle: "Todos los países",
  },
  {
    label: "Corea del Sur",
    value: "Corea del Sur",
    code: "KR",
    groupTitle: "Todos los países",
  },
  {
    label: "Costa de Marfil",
    value: "Costa de Marfil",
    code: "CI",
    groupTitle: "Todos los países",
  },
  {
    label: "Costa Rica",
    value: "Costa Rica",
    code: "CR",
    groupTitle: "Todos los países",
  },
  {
    label: "Croacia",
    value: "Croacia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "HR",
    groupTitle: "Todos los países",
  },
  {
    label: "Cuba",
    value: "Cuba",
    code: "CU",
    groupTitle: "Todos los países",
  },
  {
    label: "Curazao",
    value: "Curazao",
    code: "CW",
    groupTitle: "Todos los países",
  },
  {
    label: "Diego García",
    value: "Diego García",
    code: "DG",
    groupTitle: "Todos los países",
  },
  {
    label: "Dinamarca",
    value: "Dinamarca",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "DK",
    groupTitle: "Todos los países",
  },
  {
    label: "Dominica",
    value: "Dominica",
    code: "DM",
    groupTitle: "Todos los países",
  },
  {
    label: "Ecuador",
    value: "Ecuador",
    code: "EC",
    groupTitle: "Todos los países",
  },
  {
    label: "Egipto",
    value: "Egipto",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "EG",
    groupTitle: "Todos los países",
  },
  {
    label: "El Salvador",
    value: "El Salvador",
    code: "SV",
    groupTitle: "Todos los países",
  },
  {
    label: "Emiratos Árabes Unidos",
    value: "Emiratos Árabes Unidos",
    code: "AE",
    groupTitle: "Todos los países",
  },
  {
    label: "Eritrea",
    value: "Eritrea",
    code: "ER",
    groupTitle: "Todos los países",
  },
  {
    label: "Eslovaquia",
    value: "Eslovaquia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "SK",
    groupTitle: "Todos los países",
  },
  {
    label: "Eslovenia",
    value: "Eslovenia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "SI",
    groupTitle: "Todos los países",
  },
  {
    label: "Estados Unidos de América",
    value: "Estados Unidos de América",
    code: "US",
    groupTitle: "Todos los países",
  },
  {
    label: "Estonia",
    value: "Estonia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "EE",
    groupTitle: "Todos los países",
  },
  {
    label: "Etiopía",
    value: "Etiopía",
    code: "ET",
    groupTitle: "Todos los países",
  },
  {
    label: "Filipinas",
    value: "Filipinas",
    code: "PH",
    groupTitle: "Todos los países",
  },
  {
    label: "Finlandia",
    value: "Finlandia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "FI",
    groupTitle: "Todos los países",
  },
  {
    label: "Fiyi",
    value: "Fiyi",
    code: "FJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Francia",
    value: "Francia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "FR",
    groupTitle: "Todos los países",
  },
  {
    label: "Gabón",
    value: "Gabón",
    code: "GA",
    groupTitle: "Todos los países",
  },
  {
    label: "Gambia",
    value: "Gambia",
    code: "GM",
    groupTitle: "Todos los países",
  },
  {
    label: "Georgia",
    value: "Georgia",
    code: "GE",
    groupTitle: "Todos los países",
  },
  {
    label: "Ghana",
    value: "Ghana",
    code: "GH",
    groupTitle: "Todos los países",
  },
  {
    label: "Gibraltar",
    value: "Gibraltar",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "GI",
    groupTitle: "Todos los países",
  },
  {
    label: "Granada",
    value: "Granada",
    code: "GD",
    groupTitle: "Todos los países",
  },
  {
    label: "Grecia",
    value: "Grecia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "GR",
    groupTitle: "Todos los países",
  },
  {
    label: "Groenlandia",
    value: "Groenlandia",
    code: "GL",
    groupTitle: "Todos los países",
  },
  {
    label: "Guadalupe",
    value: "Guadalupe",
    code: "GP",
    groupTitle: "Todos los países",
  },
  {
    label: "Guam",
    value: "Guam",
    code: "GU",
    groupTitle: "Todos los países",
  },
  {
    label: "Guatemala",
    value: "Guatemala",
    code: "GT",
    groupTitle: "Todos los países",
  },
  {
    label: "Guayana Francesa",
    value: "Guayana Francesa",
    code: "GF",
    groupTitle: "Todos los países",
  },
  {
    label: "Guernsey",
    value: "Guernsey",
    code: "GG",
    groupTitle: "Todos los países",
  },
  {
    label: "Guinea",
    value: "Guinea",
    code: "GN",
    groupTitle: "Todos los países",
  },
  {
    label: "Guinea Ecuatorial",
    value: "Guinea Ecuatorial",
    code: "GQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Guinea-Bissau",
    value: "Guinea-Bissau",
    code: "GW",
    groupTitle: "Todos los países",
  },
  {
    label: "Guyana",
    value: "Guyana",
    code: "GY",
    groupTitle: "Todos los países",
  },
  {
    label: "Haití",
    value: "Haití",
    code: "HT",
    groupTitle: "Todos los países",
  },
  {
    label: "Honduras",
    value: "Honduras",
    code: "HN",
    groupTitle: "Todos los países",
  },
  {
    label: "Hong kong",
    value: "Hong kong",
    code: "HK",
    groupTitle: "Todos los países",
  },
  {
    label: "Hungría",
    value: "Hungría",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "HU",
    groupTitle: "Todos los países",
  },
  {
    label: "India",
    value: "India",
    code: "IN",
    groupTitle: "Todos los países",
  },
  {
    label: "Indonesia",
    value: "Indonesia",
    code: "ID",
    groupTitle: "Todos los países",
  },
  {
    label: "Irak",
    value: "Irak",
    code: "IQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Irlanda",
    value: "Irlanda",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "IE",
    groupTitle: "Todos los países",
  },
  {
    label: "Irán",
    value: "Irán",
    code: "IR",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Ascensión",
    value: "Isla Ascensión",
    code: "AC",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Bouvet",
    value: "Isla Bouvet",
    code: "BV",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Clipperton",
    value: "Isla Clipperton",
    code: "CP",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla de Man",
    value: "Isla de Man",
    code: "IM",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla de Navidad",
    value: "Isla de Navidad",
    code: "CX",
    groupTitle: "Todos los países",
  },
  {
    label: "Isla Norfolk",
    value: "Isla Norfolk",
    code: "NF",
    groupTitle: "Todos los países",
  },
  {
    label: "Islandia",
    value: "Islandia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "IS",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Bermudas",
    value: "Islas Bermudas",
    code: "BM",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Caimán",
    value: "Islas Caimán",
    code: "KY",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Canarias",
    value: "Islas Canarias",
    caserRegion: CaserRegion.Spain,
    code: "IC",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Cocos (Keeling)",
    value: "Islas Cocos (Keeling)",
    code: "CC",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Cook",
    value: "Islas Cook",
    code: "CK",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas de Åland",
    value: "Islas de Åland",
    code: "AX",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Feroe",
    value: "Islas Feroe",
    code: "FO",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Georgias del Sur y Sandwich del Sur",
    value: "Islas Georgias del Sur y Sandwich del Sur",
    code: "GS",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Heard y McDonald",
    value: "Islas Heard y McDonald",
    code: "HM",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Maldivas",
    value: "Islas Maldivas",
    code: "MV",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Malvinas",
    value: "Islas Malvinas",
    code: "FK",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Marianas del Norte",
    value: "Islas Marianas del Norte",
    code: "MP",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Marshall",
    value: "Islas Marshall",
    code: "MH",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Pitcairn",
    value: "Islas Pitcairn",
    code: "PN",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Salomón",
    value: "Islas Salomón",
    code: "SB",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Turcas y Caicos",
    value: "Islas Turcas y Caicos",
    code: "TC",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Ultramarinas Menores de Estados Unidos",
    value: "Islas Ultramarinas Menores de Estados Unidos",
    code: "UM",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Vírgenes Británicas",
    value: "Islas Vírgenes Británicas",
    code: "VG",
    groupTitle: "Todos los países",
  },
  {
    label: "Islas Vírgenes de los Estados Unidos",
    value: "Islas Vírgenes de los Estados Unidos",
    code: "VI",
    groupTitle: "Todos los países",
  },
  {
    label: "Israel",
    value: "Israel",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "IL",
    groupTitle: "Todos los países",
  },
  {
    label: "Italia",
    value: "Italia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "IT",
    groupTitle: "Todos los países",
  },
  {
    label: "Jamaica",
    value: "Jamaica",
    code: "JM",
    groupTitle: "Todos los países",
  },
  {
    label: "Japón",
    value: "Japón",
    code: "JP",
    groupTitle: "Todos los países",
  },
  {
    label: "Jersey",
    value: "Jersey",
    code: "JE",
    groupTitle: "Todos los países",
  },
  {
    label: "Jordania",
    value: "Jordania",
    code: "JO",
    groupTitle: "Todos los países",
  },
  {
    label: "Kazajistán",
    value: "Kazajistán",
    code: "KZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Kenia",
    value: "Kenia",
    code: "KE",
    groupTitle: "Todos los países",
  },
  {
    label: "Kirguistán",
    value: "Kirguistán",
    code: "KG",
    groupTitle: "Todos los países",
  },
  {
    label: "Kiribati",
    value: "Kiribati",
    code: "KI",
    groupTitle: "Todos los países",
  },
  {
    label: "Kosovo",
    value: "Kosovo",
    code: "XK",
    groupTitle: "Todos los países",
  },
  {
    label: "Kuwait",
    value: "Kuwait",
    code: "KW",
    groupTitle: "Todos los países",
  },
  {
    label: "Laos",
    value: "Laos",
    code: "LA",
    groupTitle: "Todos los países",
  },
  {
    label: "Lesoto",
    value: "Lesoto",
    code: "LS",
    groupTitle: "Todos los países",
  },
  {
    label: "Letonia",
    value: "Letonia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "LV",
    groupTitle: "Todos los países",
  },
  {
    label: "Liberia",
    value: "Liberia",
    code: "LR",
    groupTitle: "Todos los países",
  },
  {
    label: "Libia",
    value: "Libia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "LY",
    groupTitle: "Todos los países",
  },
  {
    label: "Liechtenstein",
    value: "Liechtenstein",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "LI",
    groupTitle: "Todos los países",
  },
  {
    label: "Lituania",
    value: "Lituania",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "LT",
    groupTitle: "Todos los países",
  },
  {
    label: "Luxemburgo",
    value: "Luxemburgo",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "LU",
    groupTitle: "Todos los países",
  },
  {
    label: "Líbano",
    value: "Líbano",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "LB",
    groupTitle: "Todos los países",
  },
  {
    label: "Macao",
    value: "Macao",
    code: "MO",
    groupTitle: "Todos los países",
  },
  {
    label: "Macedônia",
    value: "Macedônia",
    code: "MK",
    groupTitle: "Todos los países",
  },
  {
    label: "Madagascar",
    value: "Madagascar",
    code: "MG",
    groupTitle: "Todos los países",
  },
  {
    label: "Malasia",
    value: "Malasia",
    code: "MY",
    groupTitle: "Todos los países",
  },
  {
    label: "Malawi",
    value: "Malawi",
    code: "MW",
    groupTitle: "Todos los países",
  },
  {
    label: "Mali",
    value: "Mali",
    code: "ML",
    groupTitle: "Todos los países",
  },
  {
    label: "Malta",
    value: "Malta",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "MT",
    groupTitle: "Todos los países",
  },
  {
    label: "Marruecos",
    value: "Marruecos",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "MA",
    groupTitle: "Todos los países",
  },
  {
    label: "Martinica",
    value: "Martinica",
    code: "MQ",
    groupTitle: "Todos los países",
  },
  {
    label: "Mauricio",
    value: "Mauricio",
    code: "MU",
    groupTitle: "Todos los países",
  },
  {
    label: "Mauritania",
    value: "Mauritania",
    code: "MR",
    groupTitle: "Todos los países",
  },
  {
    label: "Mayotte",
    value: "Mayotte",
    code: "YT",
    groupTitle: "Todos los países",
  },
  {
    label: "Micronesia",
    value: "Micronesia",
    code: "FM",
    groupTitle: "Todos los países",
  },
  {
    label: "Moldavia",
    value: "Moldavia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "MD",
    groupTitle: "Todos los países",
  },
  {
    label: "Mongolia",
    value: "Mongolia",
    code: "MN",
    groupTitle: "Todos los países",
  },
  {
    label: "Montenegro",
    value: "Montenegro",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "ME",
    groupTitle: "Todos los países",
  },
  {
    label: "Montserrat",
    value: "Montserrat",
    code: "MS",
    groupTitle: "Todos los países",
  },
  {
    label: "Mozambique",
    value: "Mozambique",
    code: "MZ",
    groupTitle: "Todos los países",
  },
  {
    label: "México",
    value: "México",
    code: "MX",
    groupTitle: "Todos los países",
  },
  {
    label: "Mónaco",
    value: "Mónaco",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "MC",
    groupTitle: "Todos los países",
  },
  {
    label: "Naciones Unidas",
    value: "Naciones Unidas",
    code: "UN",
    groupTitle: "Todos los países",
  },
  {
    label: "Namibia",
    value: "Namibia",
    code: "NA",
    groupTitle: "Todos los países",
  },
  {
    label: "Nauru",
    value: "Nauru",
    code: "NR",
    groupTitle: "Todos los países",
  },
  {
    label: "Nepal",
    value: "Nepal",
    code: "NP",
    groupTitle: "Todos los países",
  },
  {
    label: "Nicaragua",
    value: "Nicaragua",
    code: "NI",
    groupTitle: "Todos los países",
  },
  {
    label: "Niger",
    value: "Niger",
    code: "NE",
    groupTitle: "Todos los países",
  },
  {
    label: "Nigeria",
    value: "Nigeria",
    code: "NG",
    groupTitle: "Todos los países",
  },
  {
    label: "Niue",
    value: "Niue",
    code: "NU",
    groupTitle: "Todos los países",
  },
  {
    label: "Noruega",
    value: "Noruega",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "NO",
    groupTitle: "Todos los países",
  },
  {
    label: "Nueva Caledonia",
    value: "Nueva Caledonia",
    code: "NC",
    groupTitle: "Todos los países",
  },
  {
    label: "Nueva Zelanda",
    value: "Nueva Zelanda",
    code: "NZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Omán",
    value: "Omán",
    code: "OM",
    groupTitle: "Todos los países",
  },
  {
    label: "Pakistán",
    value: "Pakistán",
    code: "PK",
    groupTitle: "Todos los países",
  },
  {
    label: "Palau",
    value: "Palau",
    code: "PW",
    groupTitle: "Todos los países",
  },
  {
    label: "Palestina",
    value: "Palestina",
    code: "PS",
    groupTitle: "Todos los países",
  },
  {
    label: "Panamá",
    value: "Panamá",
    code: "PA",
    groupTitle: "Todos los países",
  },
  {
    label: "Papúa Nueva Guinea",
    value: "Papúa Nueva Guinea",
    code: "PG",
    groupTitle: "Todos los países",
  },
  {
    label: "Paraguay",
    value: "Paraguay",
    code: "PY",
    groupTitle: "Todos los países",
  },
  {
    label: "Países Bajos",
    value: "Países Bajos",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "NL",
    groupTitle: "Todos los países",
  },
  {
    label: "Perú",
    value: "Perú",
    code: "PE",
    groupTitle: "Todos los países",
  },
  {
    label: "Polinesia Francesa",
    value: "Polinesia Francesa",
    code: "PF",
    groupTitle: "Todos los países",
  },
  {
    label: "Polonia",
    value: "Polonia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "PL",
    groupTitle: "Todos los países",
  },
  {
    label: "Portugal",
    value: "Portugal",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "PT",
    groupTitle: "Todos los países",
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
    code: "PR",
    groupTitle: "Todos los países",
  },
  {
    label: "Qatar",
    value: "Qatar",
    code: "QA",
    groupTitle: "Todos los países",
  },
  {
    label: "Reino Unido",
    value: "Reino Unido",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "UK",
    groupTitle: "Todos los países",
  },
  {
    label: "Reino Unido de Gran Bretaña e Irlanda del Norte",
    value: "Reino Unido de Gran Bretaña e Irlanda del Norte",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "GB",
    groupTitle: "Todos los países",
  },
  {
    label: "República Centroafricana",
    value: "República Centroafricana",
    code: "CF",
    groupTitle: "Todos los países",
  },
  {
    label: "República Checa",
    value: "República Checa",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "CZ",
    groupTitle: "Todos los países",
  },
  {
    label: "República de Sudán del Sur",
    value: "República de Sudán del Sur",
    code: "SS",
    groupTitle: "Todos los países",
  },
  {
    label: "República del Congo",
    value: "República del Congo",
    code: "CG",
    groupTitle: "Todos los países",
  },
  {
    label: "República Democrática del Congo",
    value: "República Democrática del Congo",
    code: "CD",
    groupTitle: "Todos los países",
  },
  {
    label: "República Dominicana",
    value: "República Dominicana",
    code: "DO",
    groupTitle: "Todos los países",
  },
  {
    label: "Reunión",
    value: "Reunión",
    code: "RE",
    groupTitle: "Todos los países",
  },
  {
    label: "Ruanda",
    value: "Ruanda",
    code: "RW",
    groupTitle: "Todos los países",
  },
  {
    label: "Rumanía",
    value: "Rumanía",
    code: "RO",
    groupTitle: "Todos los países",
  },
  {
    label: "Rusia",
    value: "Rusia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "RU",
    groupTitle: "Todos los países",
  },
  {
    label: "Sahara Occidental",
    value: "Sahara Occidental",
    code: "EH",
    groupTitle: "Todos los países",
  },
  {
    label: "Samoa",
    value: "Samoa",
    code: "WS",
    groupTitle: "Todos los países",
  },
  {
    label: "Samoa Americana",
    value: "Samoa Americana",
    code: "AS",
    groupTitle: "Todos los países",
  },
  {
    label: "San Bartolomé",
    value: "San Bartolomé",
    code: "BL",
    groupTitle: "Todos los países",
  },
  {
    label: "San Cristóbal y Nieves",
    value: "San Cristóbal y Nieves",
    code: "KN",
    groupTitle: "Todos los países",
  },
  {
    label: "San Marino",
    value: "San Marino",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "SM",
    groupTitle: "Todos los países",
  },
  {
    label: "San Martín (Francia)",
    value: "San Martín (Francia)",
    region: "europa",
    code: "MF",
    groupTitle: "Todos los países",
  },
  {
    label: "San Pedro y Miquelón",
    value: "San Pedro y Miquelón",
    code: "PM",
    groupTitle: "Todos los países",
  },
  {
    label: "San Vicente y las Granadinas",
    value: "San Vicente y las Granadinas",
    code: "VC",
    groupTitle: "Todos los países",
  },
  {
    label: "Santa Elena",
    value: "Santa Elena",
    code: "SH",
    groupTitle: "Todos los países",
  },
  {
    label: "Santa Lucía",
    value: "Santa Lucía",
    code: "LC",
    groupTitle: "Todos los países",
  },
  {
    label: "Santo Tomé y Príncipe",
    value: "Santo Tomé y Príncipe",
    code: "ST",
    groupTitle: "Todos los países",
  },
  {
    label: "Senegal",
    value: "Senegal",
    code: "SN",
    groupTitle: "Todos los países",
  },
  {
    label: "Serbia",
    value: "Serbia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "RS",
    groupTitle: "Todos los países",
  },
  {
    label: "Seychelles",
    value: "Seychelles",
    code: "SC",
    groupTitle: "Todos los países",
  },
  {
    label: "Sierra Leona",
    value: "Sierra Leona",
    code: "SL",
    groupTitle: "Todos los países",
  },
  {
    label: "Singapur",
    value: "Singapur",
    code: "SG",
    groupTitle: "Todos los países",
  },
  {
    label: "Sint Maarten",
    value: "Sint Maarten",
    code: "SX",
    groupTitle: "Todos los países",
  },
  {
    label: "Siria",
    value: "Siria",
    caserRegion: CaserRegion.Europe,
    code: "SY",
    groupTitle: "Todos los países",
  },
  {
    label: "Somalia",
    value: "Somalia",
    code: "SO",
    groupTitle: "Todos los países",
  },
  {
    label: "Sri lanka",
    value: "Sri lanka",
    code: "LK",
    groupTitle: "Todos los países",
  },
  {
    label: "Sudáfrica",
    value: "Sudáfrica",
    code: "ZA",
    groupTitle: "Todos los países",
  },
  {
    label: "Sudán",
    value: "Sudán",
    code: "SD",
    groupTitle: "Todos los países",
  },
  {
    label: "Suecia",
    value: "Suecia",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "SE",
    groupTitle: "Todos los países",
  },
  {
    label: "Suiza",
    value: "Suiza",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "CH",
    groupTitle: "Todos los países",
  },
  {
    label: "Surinám",
    value: "Surinám",
    code: "SR",
    groupTitle: "Todos los países",
  },
  {
    label: "Svalbard y Jan Mayen",
    value: "Svalbard y Jan Mayen",
    code: "SJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Swazilandia",
    value: "Swazilandia",
    code: "SZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Tailandia",
    value: "Tailandia",
    code: "TH",
    groupTitle: "Todos los países",
  },
  {
    label: "Taiwán",
    value: "Taiwán",
    code: "TW",
    groupTitle: "Todos los países",
  },
  {
    label: "Tanzania",
    value: "Tanzania",
    code: "TZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Tayikistán",
    value: "Tayikistán",
    code: "TJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Territorio Británico del Océano Índico",
    value: "Territorio Británico del Océano Índico",
    code: "IO",
    groupTitle: "Todos los países",
  },
  {
    label: "Territorios Australes y Antárticas Franceses",
    value: "Territorios Australes y Antárticas Franceses",
    code: "TF",
    groupTitle: "Todos los países",
  },
  {
    label: "Timor Oriental",
    value: "Timor Oriental",
    code: "TL",
    groupTitle: "Todos los países",
  },
  {
    label: "Togo",
    value: "Togo",
    code: "TG",
    groupTitle: "Todos los países",
  },
  {
    label: "Tokelau",
    value: "Tokelau",
    code: "TK",
    groupTitle: "Todos los países",
  },
  {
    label: "Tonga",
    value: "Tonga",
    code: "TO",
    groupTitle: "Todos los países",
  },
  {
    label: "Trinidad y Tobago",
    value: "Trinidad y Tobago",
    code: "TT",
    groupTitle: "Todos los países",
  },
  {
    label: "Tristán de Acuña",
    value: "Tristán de Acuña",
    code: "TA",
    groupTitle: "Todos los países",
  },
  {
    label: "Tunez",
    value: "Tunez",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "TN",
    groupTitle: "Todos los países",
  },
  {
    label: "Turkmenistán",
    value: "Turkmenistán",
    code: "TM",
    groupTitle: "Todos los países",
  },
  {
    label: "Turquía",
    value: "Turquía",
    code: "TR",
    caserRegion: CaserRegion.Europe,
    groupTitle: "Todos los países",
  },
  {
    label: "Tuvalu",
    value: "Tuvalu",
    code: "TV",
    groupTitle: "Todos los países",
  },
  {
    label: "Ucrania",
    value: "Ucrania",
    region: "europa",
    caserRegion: CaserRegion.Europe,
    code: "UA",
    groupTitle: "Todos los países",
  },
  {
    label: "Uganda",
    value: "Uganda",
    code: "UG",
    groupTitle: "Todos los países",
  },
  {
    label: "Unión Soviética",
    value: "Unión Soviética",
    code: "SU",
    groupTitle: "Todos los países",
  },
  {
    label: "Uruguay",
    value: "Uruguay",
    code: "UY",
    groupTitle: "Todos los países",
  },
  {
    label: "Uzbekistán",
    value: "Uzbekistán",
    code: "UZ",
    groupTitle: "Todos los países",
  },
  {
    label: "Vanuatu",
    value: "Vanuatu",
    code: "VU",
    groupTitle: "Todos los países",
  },
  {
    label: "Venezuela",
    value: "Venezuela",
    code: "VE",
    groupTitle: "Todos los países",
  },
  {
    label: "Vietnam",
    value: "Vietnam",
    code: "VN",
    groupTitle: "Todos los países",
  },
  {
    label: "Wallis y Futuna",
    value: "Wallis y Futuna",
    code: "WF",
    groupTitle: "Todos los países",
  },
  {
    label: "Yemen",
    value: "Yemen",
    code: "YE",
    groupTitle: "Todos los países",
  },
  {
    label: "Yibuti",
    value: "Yibuti",
    code: "DJ",
    groupTitle: "Todos los países",
  },
  {
    label: "Zambia",
    value: "Zambia",
    code: "ZM",
    groupTitle: "Todos los países",
  },
  {
    label: "Zimbabue",
    value: "Zimbabue",
    code: "ZW",
    groupTitle: "Todos los países",
  },
];

export const userCountriesListSpanish = [
  { label: "España", value: "España" },
  {
    label: "Afganistán",
    value: "Afganistán",
  },
  {
    label: "Albania",
    value: "Albania",
  },
  {
    label: "Alemania",
    value: "Alemania",
  },
  {
    label: "Andorra",
    value: "Andorra",
  },
  {
    label: "Angola",
    value: "Angola",
  },
  {
    label: "Anguila",
    value: "Anguila",
  },
  {
    label: "Antigua y Barbuda",
    value: "Antigua y Barbuda",
  },
  {
    label: "Antártida",
    value: "Antártida",
  },
  {
    label: "Arabia Saudita",
    value: "Arabia Saudita",
  },
  {
    label: "Argelia",
    value: "Argelia",
  },
  {
    label: "Argentina",
    value: "Argentina",
  },
  {
    label: "Armenia",
    value: "Armenia",
  },
  {
    label: "Aruba",
    value: "Aruba",
  },
  {
    label: "Australia",
    value: "Australia",
  },
  {
    label: "Austria",
    value: "Austria",
  },
  {
    label: "Azerbaiyán",
    value: "Azerbaiyán",
  },
  {
    label: "Bahamas",
    value: "Bahamas",
  },
  {
    label: "Bahrein",
    value: "Bahrein",
  },
  {
    label: "Bangladesh",
    value: "Bangladesh",
  },
  {
    label: "Barbados",
    value: "Barbados",
  },
  {
    label: "Belice",
    value: "Belice",
  },
  {
    label: "Benín",
    value: "Benín",
  },
  {
    label: "Bhután",
    value: "Bhután",
  },
  {
    label: "Bielorrusia",
    value: "Bielorrusia",
  },
  {
    label: "Birmania",
    value: "Birmania",
  },
  {
    label: "Bolivia",
    value: "Bolivia",
  },
  {
    label: "Bonaire, San Eustaquio y Saba",
    value: "Bonaire, San Eustaquio y Saba",
  },
  {
    label: "Bosnia y Herzegovina",
    value: "Bosnia y Herzegovina",
  },
  {
    label: "Botsuana",
    value: "Botsuana",
  },
  {
    label: "Brasil",
    value: "Brasil",
  },
  {
    label: "Brunéi",
    value: "Brunéi",
  },
  {
    label: "Bulgaria",
    value: "Bulgaria",
  },
  {
    label: "Burkina Faso",
    value: "Burkina Faso",
  },
  {
    label: "Burundi",
    value: "Burundi",
  },
  {
    label: "Bélgica",
    value: "Bélgica",
  },
  {
    label: "Cabo Verde",
    value: "Cabo Verde",
  },
  {
    label: "Camboya",
    value: "Camboya",
  },
  {
    label: "Camerún",
    value: "Camerún",
  },
  {
    label: "Canadá",
    value: "Canadá",
  },
  {
    label: "Ceuta y Melilla",
    value: "Ceuta y Melilla",
  },
  {
    label: "Chad",
    value: "Chad",
  },
  {
    label: "Chile",
    value: "Chile",
  },
  {
    label: "China",
    value: "China",
  },
  {
    label: "Chipre",
    value: "Chipre",
  },
  {
    label: "Ciudad del Vaticano",
    value: "Ciudad del Vaticano",
  },
  {
    label: "Colombia",
    value: "Colombia",
  },
  {
    label: "Comoras",
    value: "Comoras",
  },
  {
    label: "Corea del Norte",
    value: "Corea del Norte",
  },
  {
    label: "Corea del Sur",
    value: "Corea del Sur",
  },
  {
    label: "Costa de Marfil",
    value: "Costa de Marfil",
  },
  {
    label: "Costa Rica",
    value: "Costa Rica",
  },
  {
    label: "Croacia",
    value: "Croacia",
  },
  {
    label: "Cuba",
    value: "Cuba",
  },
  {
    label: "Curazao",
    value: "Curazao",
  },
  {
    label: "Diego García",
    value: "Diego García",
  },
  {
    label: "Dinamarca",
    value: "Dinamarca",
  },
  {
    label: "Dominica",
    value: "Dominica",
  },
  {
    label: "Ecuador",
    value: "Ecuador",
  },
  {
    label: "Egipto",
    value: "Egipto",
  },
  {
    label: "El Salvador",
    value: "El Salvador",
  },
  {
    label: "Emiratos Árabes Unidos",
    value: "Emiratos Árabes Unidos",
  },
  {
    label: "Eritrea",
    value: "Eritrea",
  },
  {
    label: "Eslovaquia",
    value: "Eslovaquia",
  },
  {
    label: "Eslovenia",
    value: "Eslovenia",
  },
  {
    label: "Estados Unidos de América",
    value: "Estados Unidos de América",
  },
  {
    label: "Estonia",
    value: "Estonia",
  },
  {
    label: "Etiopía",
    value: "Etiopía",
  },
  {
    label: "Filipinas",
    value: "Filipinas",
  },
  {
    label: "Finlandia",
    value: "Finlandia",
  },
  {
    label: "Fiyi",
    value: "Fiyi",
  },
  {
    label: "Francia",
    value: "Francia",
  },
  //{
  //   label: "Francia metropolitana",
  //   value: "Francia metropolitana",
  //},
  {
    label: "Gabón",
    value: "Gabón",
  },
  {
    label: "Gambia",
    value: "Gambia",
  },
  {
    label: "Georgia",
    value: "Georgia",
  },
  {
    label: "Ghana",
    value: "Ghana",
  },
  {
    label: "Gibraltar",
    value: "Gibraltar",
  },
  {
    label: "Granada",
    value: "Granada",
  },
  {
    label: "Grecia",
    value: "Grecia",
  },
  {
    label: "Groenlandia",
    value: "Groenlandia",
  },
  {
    label: "Guadalupe",
    value: "Guadalupe",
  },
  {
    label: "Guam",
    value: "Guam",
  },
  {
    label: "Guatemala",
    value: "Guatemala",
  },
  {
    label: "Guayana Francesa",
    value: "Guayana Francesa",
  },
  {
    label: "Guernsey",
    value: "Guernsey",
  },
  {
    label: "Guinea",
    value: "Guinea",
  },
  {
    label: "Guinea Ecuatorial",
    value: "Guinea Ecuatorial",
  },
  {
    label: "Guinea-Bissau",
    value: "Guinea-Bissau",
  },
  {
    label: "Guyana",
    value: "Guyana",
  },
  {
    label: "Haití",
    value: "Haití",
  },
  {
    label: "Honduras",
    value: "Honduras",
  },
  {
    label: "Hong kong",
    value: "Hong kong",
  },
  {
    label: "Hungría",
    value: "Hungría",
  },
  {
    label: "India",
    value: "India",
  },
  {
    label: "Indonesia",
    value: "Indonesia",
  },
  {
    label: "Irak",
    value: "Irak",
  },
  {
    label: "Irlanda",
    value: "Irlanda",
  },
  {
    label: "Irán",
    value: "Irán",
  },
  {
    label: "Isla Ascensión",
    value: "Isla Ascensión",
  },
  {
    label: "Isla Bouvet",
    value: "Isla Bouvet",
  },
  {
    label: "Isla Clipperton",
    value: "Isla Clipperton",
  },
  {
    label: "Isla de Man",
    value: "Isla de Man",
  },
  {
    label: "Isla de Navidad",
    value: "Isla de Navidad",
  },
  {
    label: "Isla Norfolk",
    value: "Isla Norfolk",
  },
  {
    label: "Islandia",
    value: "Islandia",
  },
  {
    label: "Islas Bermudas",
    value: "Islas Bermudas",
  },
  {
    label: "Islas Caimán",
    value: "Islas Caimán",
  },
  {
    label: "Islas Canarias",
    value: "Islas Canarias",
  },
  {
    label: "Islas Cocos (Keeling)",
    value: "Islas Cocos (Keeling)",
  },
  {
    label: "Islas Cook",
    value: "Islas Cook",
  },
  {
    label: "Islas de Åland",
    value: "Islas de Åland",
  },
  {
    label: "Islas Feroe",
    value: "Islas Feroe",
  },
  {
    label: "Islas Georgias del Sur y Sandwich del Sur",
    value: "Islas Georgias del Sur y Sandwich del Sur",
  },
  {
    label: "Islas Heard y McDonald",
    value: "Islas Heard y McDonald",
  },
  {
    label: "Islas Maldivas",
    value: "Islas Maldivas",
  },
  {
    label: "Islas Malvinas",
    value: "Islas Malvinas",
  },
  {
    label: "Islas Marianas del Norte",
    value: "Islas Marianas del Norte",
  },
  {
    label: "Islas Marshall",
    value: "Islas Marshall",
  },
  {
    label: "Islas Pitcairn",
    value: "Islas Pitcairn",
  },
  {
    label: "Islas Salomón",
    value: "Islas Salomón",
  },
  {
    label: "Islas Turcas y Caicos",
    value: "Islas Turcas y Caicos",
  },
  {
    label: "Islas Ultramarinas Menores de Estados Unidos",
    value: "Islas Ultramarinas Menores de Estados Unidos",
  },
  {
    label: "Islas Vírgenes Británicas",
    value: "Islas Vírgenes Británicas",
  },
  {
    label: "Islas Vírgenes de los Estados Unidos",
    value: "Islas Vírgenes de los Estados Unidos",
  },
  {
    label: "Israel",
    value: "Israel",
  },
  {
    label: "Italia",
    value: "Italia",
  },
  {
    label: "Jamaica",
    value: "Jamaica",
  },
  {
    label: "Japón",
    value: "Japón",
  },
  {
    label: "Jersey",
    value: "Jersey",
  },
  {
    label: "Jordania",
    value: "Jordania",
  },
  {
    label: "Kazajistán",
    value: "Kazajistán",
  },
  {
    label: "Kenia",
    value: "Kenia",
  },
  {
    label: "Kirguistán",
    value: "Kirguistán",
  },
  {
    label: "Kiribati",
    value: "Kiribati",
  },
  {
    label: "Kosovo",
    value: "Kosovo",
  },
  {
    label: "Kuwait",
    value: "Kuwait",
  },
  {
    label: "Laos",
    value: "Laos",
  },
  {
    label: "Lesoto",
    value: "Lesoto",
  },
  {
    label: "Letonia",
    value: "Letonia",
  },
  {
    label: "Liberia",
    value: "Liberia",
  },
  {
    label: "Libia",
    value: "Libia",
  },
  {
    label: "Liechtenstein",
    value: "Liechtenstein",
  },
  {
    label: "Lituania",
    value: "Lituania",
  },
  {
    label: "Luxemburgo",
    value: "Luxemburgo",
  },
  {
    label: "Líbano",
    value: "Líbano",
  },
  {
    label: "Macao",
    value: "Macao",
  },
  {
    label: "Macedônia",
    value: "Macedônia",
  },
  {
    label: "Madagascar",
    value: "Madagascar",
  },
  {
    label: "Malasia",
    value: "Malasia",
  },
  {
    label: "Malawi",
    value: "Malawi",
  },
  {
    label: "Mali",
    value: "Mali",
  },
  {
    label: "Malta",
    value: "Malta",
  },
  {
    label: "Marruecos",
    value: "Marruecos",
  },
  {
    label: "Martinica",
    value: "Martinica",
  },
  {
    label: "Mauricio",
    value: "Mauricio",
  },
  {
    label: "Mauritania",
    value: "Mauritania",
  },
  {
    label: "Mayotte",
    value: "Mayotte",
  },
  {
    label: "Micronesia",
    value: "Micronesia",
  },
  {
    label: "Moldavia",
    value: "Moldavia",
  },
  {
    label: "Mongolia",
    value: "Mongolia",
  },
  {
    label: "Montenegro",
    value: "Montenegro",
  },
  {
    label: "Montserrat",
    value: "Montserrat",
  },
  {
    label: "Mozambique",
    value: "Mozambique",
  },
  {
    label: "México",
    value: "México",
  },
  {
    label: "Mónaco",
    value: "Mónaco",
  },
  {
    label: "Naciones Unidas",
    value: "Naciones Unidas",
  },
  {
    label: "Namibia",
    value: "Namibia",
  },
  {
    label: "Nauru",
    value: "Nauru",
  },
  {
    label: "Nepal",
    value: "Nepal",
  },
  {
    label: "Nicaragua",
    value: "Nicaragua",
  },
  {
    label: "Niger",
    value: "Niger",
  },
  {
    label: "Nigeria",
    value: "Nigeria",
  },
  {
    label: "Niue",
    value: "Niue",
  },
  {
    label: "Noruega",
    value: "Noruega",
  },
  {
    label: "Nueva Caledonia",
    value: "Nueva Caledonia",
  },
  {
    label: "Nueva Zelanda",
    value: "Nueva Zelanda",
  },
  {
    label: "Omán",
    value: "Omán",
  },
  {
    label: "Pakistán",
    value: "Pakistán",
  },
  {
    label: "Palau",
    value: "Palau",
  },
  {
    label: "Palestina",
    value: "Palestina",
  },
  {
    label: "Panamá",
    value: "Panamá",
  },
  {
    label: "Papúa Nueva Guinea",
    value: "Papúa Nueva Guinea",
  },
  {
    label: "Paraguay",
    value: "Paraguay",
  },
  {
    label: "Países Bajos",
    value: "Países Bajos",
  },
  {
    label: "Perú",
    value: "Perú",
  },
  {
    label: "Polinesia Francesa",
    value: "Polinesia Francesa",
  },
  {
    label: "Polonia",
    value: "Polonia",
  },
  {
    label: "Portugal",
    value: "Portugal",
  },
  {
    label: "Puerto Rico",
    value: "Puerto Rico",
  },
  {
    label: "Qatar",
    value: "Qatar",
  },
  {
    label: "Reino Unido",
    value: "Reino Unido",
  },
  {
    label: "Reino Unido de Gran Bretaña e Irlanda del Norte",
    value: "Reino Unido de Gran Bretaña e Irlanda del Norte",
  },
  {
    label: "República Centroafricana",
    value: "República Centroafricana",
  },
  {
    label: "República Checa",
    value: "República Checa",
  },
  {
    label: "República de Sudán del Sur",
    value: "República de Sudán del Sur",
  },
  {
    label: "República del Congo",
    value: "República del Congo",
  },
  {
    label: "República Democrática del Congo",
    value: "República Democrática del Congo",
  },
  {
    label: "República Dominicana",
    value: "República Dominicana",
  },
  {
    label: "Reunión",
    value: "Reunión",
  },
  {
    label: "Ruanda",
    value: "Ruanda",
  },
  {
    label: "Rumanía",
    value: "Rumanía",
  },
  {
    label: "Rusia",
    value: "Rusia",
  },
  {
    label: "Sahara Occidental",
    value: "Sahara Occidental",
  },
  {
    label: "Samoa",
    value: "Samoa",
  },
  {
    label: "Samoa Americana",
    value: "Samoa Americana",
  },
  {
    label: "San Bartolomé",
    value: "San Bartolomé",
  },
  {
    label: "San Cristóbal y Nieves",
    value: "San Cristóbal y Nieves",
  },
  {
    label: "San Marino",
    value: "San Marino",
  },
  {
    label: "San Martín (Francia)",
    value: "San Martín (Francia)",
  },
  {
    label: "San Pedro y Miquelón",
    value: "San Pedro y Miquelón",
  },
  {
    label: "San Vicente y las Granadinas",
    value: "San Vicente y las Granadinas",
  },
  {
    label: "Santa Elena",
    value: "Santa Elena",
  },
  {
    label: "Santa Lucía",
    value: "Santa Lucía",
  },
  {
    label: "Santo Tomé y Príncipe",
    value: "Santo Tomé y Príncipe",
  },
  {
    label: "Senegal",
    value: "Senegal",
  },
  {
    label: "Serbia",
    value: "Serbia",
  },
  {
    label: "Seychelles",
    value: "Seychelles",
  },
  {
    label: "Sierra Leona",
    value: "Sierra Leona",
  },
  {
    label: "Singapur",
    value: "Singapur",
  },
  {
    label: "Sint Maarten",
    value: "Sint Maarten",
  },
  {
    label: "Siria",
    value: "Siria",
  },
  {
    label: "Somalia",
    value: "Somalia",
  },
  {
    label: "Sri lanka",
    value: "Sri lanka",
  },
  {
    label: "Sudáfrica",
    value: "Sudáfrica",
  },
  {
    label: "Sudán",
    value: "Sudán",
  },
  {
    label: "Suecia",
    value: "Suecia",
  },
  {
    label: "Suiza",
    value: "Suiza",
  },
  {
    label: "Surinám",
    value: "Surinám",
  },
  {
    label: "Svalbard y Jan Mayen",
    value: "Svalbard y Jan Mayen",
  },
  {
    label: "Swazilandia",
    value: "Swazilandia",
  },
  {
    label: "Tailandia",
    value: "Tailandia",
  },
  {
    label: "Taiwán",
    value: "Taiwán",
  },
  {
    label: "Tanzania",
    value: "Tanzania",
  },
  {
    label: "Tayikistán",
    value: "Tayikistán",
  },
  {
    label: "Territorio Británico del Océano Índico",
    value: "Territorio Británico del Océano Índico",
  },
  {
    label: "Territorios Australes y Antárticas Franceses",
    value: "Territorios Australes y Antárticas Franceses",
  },
  {
    label: "Timor Oriental",
    value: "Timor Oriental",
  },
  {
    label: "Togo",
    value: "Togo",
  },
  {
    label: "Tokelau",
    value: "Tokelau",
  },
  {
    label: "Tonga",
    value: "Tonga",
  },
  {
    label: "Trinidad y Tobago",
    value: "Trinidad y Tobago",
  },
  {
    label: "Tristán de Acuña",
    value: "Tristán de Acuña",
  },
  {
    label: "Tunez",
    value: "Tunez",
  },
  {
    label: "Turkmenistán",
    value: "Turkmenistán",
  },
  {
    label: "Turquía",
    value: "Turquía",
  },
  {
    label: "Tuvalu",
    value: "Tuvalu",
  },
  {
    label: "Ucrania",
    value: "Ucrania",
  },
  {
    label: "Uganda",
    value: "Uganda",
  },
  // {
  //   label: "Unión Europea",
  //   value: "Unión Europea",
  // },
  {
    label: "Unión Soviética",
    value: "Unión Soviética",
  },
  {
    label: "Uruguay",
    value: "Uruguay",
  },
  {
    label: "Uzbekistán",
    value: "Uzbekistán",
  },
  {
    label: "Vanuatu",
    value: "Vanuatu",
  },
  {
    label: "Venezuela",
    value: "Venezuela",
  },
  {
    label: "Vietnam",
    value: "Vietnam",
  },
  {
    label: "Wallis y Futuna",
    value: "Wallis y Futuna",
  },
  {
    label: "Yemen",
    value: "Yemen",
  },
  {
    label: "Yibuti",
    value: "Yibuti",
  },
  {
    label: "Zambia",
    value: "Zambia",
  },
  {
    label: "Zimbabue",
    value: "Zimbabue",
  },
];

export const documentTypeList = [
  { label: "DNI", value: "DNI" },
  { label: "Pasaporte", value: "Pasaporte" },
  { label: "CIF", value: "CIF" },
  { label: "NIE", value: "NIE" },
];

export const documentTypeListAIG = [
  { label: "DNI", value: "DNI" },
  { label: "CIF", value: "CIF" },
  { label: "NIE", value: "NIE" },
];

export const documentTypeListCASER = [{ label: "DNI", value: "DNI" }];

export const PAYMENT_STATUS = {
  ERROR_PAYMENT: "Error en el Pago",
  PAID: "Pagado",
  ERROR_EMISSION: "Error Emisión",
  PAYMENT_DEFFERED: "Pago Diferido",
  COMPLETED: "Completada",
  CANCELLED: "Cancelada",
};

export const ANNUAL_CATEGOIRES = [
  "LARGAESTANCIA",
  "ANUALMULTIVIAJE",
  "PROFESORESVISITANTES",
  "REPATRIACION",
  "AVIRISAnualMultiviaje",
];

export const COLLABORATOR_PANEL_URL_PARAMETER_NAME = "fromCollaboratorPanel";
