import {
  addDays,
  addMonths,
  addYears,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  format,
} from "date-fns";
import { toast } from "react-toastify";

export const logoutUser = (navigate: any, dispatch: any) => {
  localStorage.removeItem("tr_access_token");
  localStorage.removeItem("userId");
  const userType = localStorage.getItem("userType");
  // dispatch(logout());
  localStorage.removeItem("userType");
  navigate.push("/login?type=" + userType);
};

export const getFormattedDate = (current_datetime: Date) => {
  let formatted_date = "";
  if (current_datetime) {
    formatted_date = format(new Date(current_datetime), "dd/MM/yyyy");
  }
  return formatted_date;
};

export const numberWithCommas = (number: any) => {
  if (!number) return 0;
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isNumeric = (str: any) => {
  // if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

export const debounce = (func: any, milliseconds: number) => {
  const time = milliseconds || 400;
  let timer: any;

  return (event: any) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(func, time, event);
  };
};

export const urlify = (text: string) => {
  /* eslint-disable no-useless-escape */
  /* eslint-disable no-control-regex */
  // const urlRegex =
  //   /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i; // eslint-disable-next-line

  // const urlRegex =
  //   /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  const urlRegex =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  return text.replace(urlRegex, function (url) {
    let href = url;
    if (url.indexOf("http://") !== 0 && url.indexOf("https://") !== 0) {
      href = "https://" + url;
    }
    return (
      '<a target="_blank" rel="noreferrer" class="underline" href="' +
      href +
      '">' +
      url +
      "</a>"
    );
  });
};

export const isColorDark = (color: any) => {
  // Variables for red, green, blue values
  let r: any, g: any, b: any, hsp: any;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&"));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return false;
  } else {
    return true;
  }
};

export const showPrice = ({
  price,
  discountPercentage,
  totalTravellers,
  numberOfTravellersPerPrice,
}: {
  price: number;
  discountPercentage?: number | null;
  totalTravellers?: number;
  numberOfTravellersPerPrice?: number;
}) => {
  let newPrice = price;

  if (totalTravellers) {
    const totalTravellersMultiplier = numberOfTravellersPerPrice
      ? Math.ceil(totalTravellers / numberOfTravellersPerPrice)
      : totalTravellers;

    newPrice = newPrice * totalTravellersMultiplier;
  }

  if (discountPercentage) {
    newPrice = newPrice * ((100 - discountPercentage) / 100);
  }

  return numberWithCommas(newPrice?.toFixed(2)) + " €";
};

export const getPrice = ({
  price,
  totalTravellers,
  numberOfTravellersPerPrice,
}: {
  price: number;
  totalTravellers?: number;
  numberOfTravellersPerPrice?: number;
}) => {
  let newPrice = price;

  if (totalTravellers) {
    const totalTravellersMultiplier = numberOfTravellersPerPrice
      ? Math.ceil(totalTravellers / numberOfTravellersPerPrice)
      : totalTravellers;

    newPrice = newPrice * totalTravellersMultiplier;
  }

  return Number(newPrice?.toFixed(2));
};

export const parseXlsxDate = (serialDateNumber: number) => {
  // Number of milliseconds in a day
  const millisecondsPerDay = 24 * 60 * 60 * 1000;

  // Date object representing January 1, 1900
  const baseDate = new Date(1900, 0, 1);

  // Calculate the number of days from the base date
  const numberOfDays = serialDateNumber - 2;

  // Calculate the total number of milliseconds
  const totalMilliseconds = numberOfDays * millisecondsPerDay;

  // Create a new Date object by adding the total milliseconds to the base date
  const currentDate = new Date(baseDate.getTime() + totalMilliseconds);
  return currentDate;
};

export function formatIdade(date: any) {
  const result = [];
  const now = new Date();
  let age = new Date(date);

  const years = differenceInYears(now, age);
  if (years > 0) {
    result.push(`${years} years`);
    age = addYears(age, years);
  }

  const months = differenceInMonths(now, age);
  if (months > 0) {
    result.push(`${months} months`);
    age = addMonths(age, months);
  }

  const days = differenceInDays(now, age);
  if (days > 0) {
    result.push(`${days} days`);
  }
}

export const copyDataToClipboard = (text: string) => {

  function copyMobile(text: string) {
    const elementTemp = document.createElement("input");
    elementTemp.setAttribute("type", "text");
    elementTemp.setAttribute("value", text);
    elementTemp.style.position = "absolute";
    elementTemp.style.left = "-9999px";

    document.body.appendChild(elementTemp);
    elementTemp.select();

    document.addEventListener("copy", function (e: any) {
      e.clipboardData.setData("text/plain", text);
      e.preventDefault();
    });

    var sel = document.getSelection();
    var range = document.createRange();
    range.selectNodeContents(document.body);
    sel?.removeAllRanges();
    sel?.addRange(range);
    document.execCommand("copy");
    const selection = window?.getSelection();
    selection?.removeAllRanges();
    document.body.removeChild(elementTemp);
    toast.success("Texto copiado al portapapeles");

  }

  function copyDashboard(text: string) {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success("Texto copiado al portapapeles");
      })
      .catch(err => {
        copyMobile(text);
      });
  }

  if (navigator.clipboard) {
    copyDashboard(text);
  } else {
    copyMobile(text);
  }

};

// This is done in order to prevent a bug: if you save the budget of modality A (with no extras),
// but at that time, extras in the modality B are selected, on load url with budget, extras in A will be empty
export function filterProductOvercosts(productOverCosts: any, productVariant: string) {
  const output: any = {};

  Object.entries(productOverCosts).forEach(([key, value]) => {
    output[key] = productVariant === key ? value : [];
  });
  return output;
}

export function filterIrisExtrasSelected(
  irisExtraSelected: any,
  productVariant: string
) {
  const output: any = {};

  Object.entries(irisExtraSelected).forEach(([key, value]) => {
    output[key] = productVariant === key ? value : null;
  });
  return output;
}

export function checkMinimunAge(value: any, minAge: number = 70, maxAge: number = 80, dayForCoverage: number = 90) {

  const birthDate = new Date(value);
  // const birthDatePlus90Days = addDays(birthDate, dayForCoverage);
  const endOfYearCurrent = new Date();

  const age90days = differenceInYears(endOfYearCurrent, birthDate);

  if (age90days >= minAge) {
    return age90days <= maxAge;
  }

  return false;
}

export function calculateNetPrice(
  precioNeto: any,
  totalTravellers: any,
  elderCount: any,
  priceNetoExtra: any,
  productProvider: string,
  PRODUCT_PROVIDERS: any
): any {
  let precioNetoPerPerson = precioNeto / totalTravellers;
  let precioNetoAll = precioNeto ?? 0;
  if (productProvider === PRODUCT_PROVIDERS?.IRIS && priceNetoExtra > 0) {
    precioNetoAll = (precioNetoPerPerson * (totalTravellers - elderCount) + priceNetoExtra);
  }

  return precioNetoAll?.toFixed(2);
}
