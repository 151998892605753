import { Button, DatePicker, Input, SelectAutoComplete } from "@components";
import { documentTypeList } from "@helpers/data";
import { useNavigate } from "react-router-dom";

interface UpdateProfileFormProps {
  control: any;
  errors: any;
  onSubmit: any;
  setValue: any;
}

const UpdateProfileForm: React.FC<UpdateProfileFormProps> = (props) => {
  const { control, errors, onSubmit, setValue } = props;

  const navigate = useNavigate();

  const cancelHandler = () => {
    navigate(-1);
  };

  return (
    <form
      name="updateProfileForm"
      noValidate
      className="flex flex-col w-full px-4 lg:px-10vw pt-20"
      onSubmit={onSubmit}
    >
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-3">
        <div className="flex flex-col">
          <Input
            className="whitebgInput"
            name="name"
            label={"Nombre"}
            control={control}
            required
            fullWidth
            error={!!errors.name}
            errorMessage={errors?.name?.message}
            labelOutside
          />
        </div>
        <div className="flex flex-col">
          <Input
            className="whitebgInput"
            name="surname"
            label={"Apellidos"}
            control={control}
            required
            fullWidth
            error={!!errors.surname}
            errorMessage={errors?.surname?.message}
            labelOutside
          />
        </div>
        <div className="flex flex-col">
          <DatePicker
            className="whitebgInput"
            name="dob"
            label="Fecha Nacimiento"
            control={control}
            required
            fullWidth
            error={!!errors.dob}
            errorMessage={errors?.dob?.message}
            maxDate={new Date()}
            labelOutside
            disableTextField
          />
        </div>
        <div className="flex flex-col">
          <Input
            className="whitebgInput"
            name="documentNumber"
            label={"Nº documento"}
            control={control}
            required
            fullWidth
            error={!!errors.documentNumber}
            errorMessage={errors?.documentNumber?.message}
            labelOutside
          />
        </div>
        <div className="flex flex-col">
          <SelectAutoComplete
            className="whitebgInput"
            label="Tipo documento"
            name="documentType"
            control={control}
            required
            fullWidth
            error={!!errors.documentType}
            errorMessage={errors?.documentType?.message}
            list={documentTypeList}
            disableClearable
            labelOutside
            onChange={(newValue: any, field: any) => {
              field.onChange(newValue.value);
              setValue("documentNumber", "");
            }}
          />
        </div>
      </div>
      <div className="flex flex-col mt-3">
        <Input
          className="whitebgInput"
          name="address"
          label={"Dirección"}
          control={control}
          required
          fullWidth
          error={!!errors.address}
          errorMessage={errors?.address?.message}
          labelOutside
        />
      </div>
      <div className="grid lg:grid-cols-2 mt-3 gap-3">
        <div className="flex flex-col">
          <Input
            className="whitebgInput"
            name="postalCode"
            label={"Codigo Postal"}
            control={control}
            required
            fullWidth
            error={!!errors.postalCode}
            errorMessage={errors?.postalCode?.message}
            labelOutside
          />
        </div>
        <div className="flex flex-col">
          <Input
            className="whitebgInput"
            name="city"
            label={"Ciudad"}
            control={control}
            required
            fullWidth
            error={!!errors.city}
            errorMessage={errors?.city?.message}
            labelOutside
          />
        </div>
        <div className="flex flex-col">
          <Input
            className="whitebgInput"
            name="email"
            label={"Email"}
            control={control}
            required
            fullWidth
            error={!!errors.email}
            errorMessage={errors?.email?.message}
            labelOutside
          />
        </div>
        <div className="flex flex-col">
          <Input
            className="whitebgInput"
            name="phoneNo"
            label={"Telefono"}
            control={control}
            required
            fullWidth
            error={!!errors.phoneNo}
            errorMessage={errors?.phoneNo?.message}
            labelOutside
          />
        </div>
        <div className="flex flex-col">
          <Input
            className="whitebgInput"
            name="country"
            label={"Pais"}
            control={control}
            required
            fullWidth
            error={!!errors.country}
            errorMessage={errors?.country?.message}
            labelOutside
          />
        </div>
        <div className="flex flex-col">
          <Input
            className="whitebgInput"
            name="province"
            label={"Provincia"}
            control={control}
            required
            fullWidth
            error={!!errors.province}
            errorMessage={errors?.province?.message}
            labelOutside
          />
        </div>
      </div>

      <div className="flex self-end space-x-3 py-8">
        <Button
          color="secondary"
          className="w-full mt-4"
          variant="outlined"
          onClick={cancelHandler}
        >
          Cancelar
        </Button>
        <Button color="secondary" className="w-full mt-4" type="submit">
          Salvar
        </Button>
      </div>
    </form>
  );
};

export default UpdateProfileForm;
