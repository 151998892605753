import { useSelector } from "react-redux";
import logoTravelfineWhite from "assets/icons/logoTravelfineWhite.png";
import { Login } from "@components/modals";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";

interface BrandingHeaderProps {}

const BrandingHeader: React.FC<BrandingHeaderProps> = (props) => {
  const { brandImage }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.code
  );
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [searchParams] = useSearchParams();

  const userTypeParams = searchParams.get("userType");

  return (
    <div className="h-20 flex bg-secondary items-center px-4 relative">
      <img
        alt="logo"
        className="h-full w-5/12 lg:w-auto lg:mx-auto object-contain py-1"
        src={brandImage ? brandImage : logoTravelfineWhite}
      />
      {userTypeParams !== "collaborator" && (
        <div className="flex flex-col justify-center items-center h-full absolute top-0 right-4">
          {localStorage.getItem("tr_access_token") ? (
            <Button
              color="neutral"
              variant="outlined"
              onClick={() => navigate("/dashboard/purchases")}
            >
              Panel de control
            </Button>
          ) : (
            <Button color="neutral" variant="outlined" onClick={handleOpen}>
              Iniciar sesión
            </Button>
          )}
          <Login open={open} onClose={handleClose} doNavigate />
        </div>
      )}
    </div>
  );
};

export default BrandingHeader;
