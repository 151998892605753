import { RewardActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: RewardState = {
  loading: false,
  rewards: [],
  totalRewards: 0,
  // advantage:{},
};

const RewardReducer = (
  state: RewardState = INITIAL_STATE,
  action: Action
): RewardState => {
  switch (action.type) {
    case RewardActionTypes.GET_REWARDS_SUCCESS:
      if (action.payload.currentPage === 1) {
        return {
          ...state,
          rewards: action.payload.rewards,
          totalRewards: action.payload.totalRewards,
        };
      }
      return {
        ...state,
        rewards: [...state.rewards, ...action.payload.rewards],
        totalRewards: action.payload.totalRewards,
      };

    case RewardActionTypes.SET_REWARD_LOADER:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default RewardReducer;
