import { MenuItem, TextField } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { numberWithCommas } from "@helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilteredProductPrices,
  setProductOverCosts,
} from "@store/product/ProductActions";
import ItemTitle from "./ItemTitle";
import { PRODUCT_PROVIDERS } from "@helpers/data";
import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { GLOBAL_FINE, IRIS_DEROGATION_70_80, IRIS_DEROGATION_DEPORTE, IRIS_DEROGATION_DEPORTE_CRUCERO, IRIS_DEROGATION_70_80_CRUCERO, IRIS_DEROGATION_CRUCERO } from "@constants/constants";

interface IrisApiExtraItemProps {
  item?: any;
  type: string;
}

const IrisApiExtraItem: React.FC<IrisApiExtraItemProps> = (props) => {
  const { item, type } = props;
  const dispatch = useDispatch();

  const limitObj = item.limits?.find((limit: any) => limit.name === type);
  const limit = limitObj?.value;

  const [value, setValue] = useState<any>("");

  const { productOverCosts, product, filter, irisExtraSelected }: any =
    useSelector<any>(({ Travelfine }) => Travelfine.product);

  const list = useMemo(() => {
    let irisList = [];

    const ImpLimite = item.ImpLimite[type];
    const ImpLimiteMaximo = item.ImpLimiteMaximo[type];
    const ImpTramoLimite = item.ImpTramoLimite[type];
    const IndLimiteOpcional = item.IndLimiteOpcional[type];
    const IndOpcional = item.IndOpcional[type];

    //Checking if this extra has a limit or not
    if (ImpLimite) {
      IndOpcional && irisList.push({ label: "No", value: "No" });
      if (IndLimiteOpcional) {
        for (
          let index = ImpLimite;
          index <= ImpLimiteMaximo;
          index = index + ImpTramoLimite
        ) {
          irisList.push({ label: index.toString(), value: index });
        }
      } else {
        irisList.push({
          label: ImpLimite.toString() + "€",
          value: ImpLimite,
        });
      }
    }

    const val = irisList;

    return val;
  }, [item, type]);

  useEffect(() => {
    let val = list && list[0] && list[0].value;

    if (item.isIrisApiExtra) {
      const itemProductOverCosts = productOverCosts[type];
      itemProductOverCosts.forEach(
        ({ idGarantia, Capital }: { idGarantia: any; Capital: number }) => {
          if (idGarantia === item.id) {
            val = Capital;
          }
        }
      );
    }
    setValue(val);
  }, [item, list, type, productOverCosts, limit]);

  const changeHandler = (event: any) => {
    if (item.isIrisApiExtra) {
      const newProductOverCosts = JSON.parse(JSON.stringify(productOverCosts));

      let newArray = [...newProductOverCosts[type]];
      if (event.target.value !== "No") {
        const obj = {
          idGarantia: item.id,
          Num_opcion_Contratacion: item.Num_opcion_Contratacion,
          Capital: event.target.value,
          limit: event.target.value,
          title: item.title,
        };
        newArray.push(obj);

        //This is necessary as these two extras with ids 10441,10413 are always connected with each other so both of these have to be enabled
        if (item.id === 10441 || item.id === 10413) {
          const itemIdToFilter = item.id === 10441 ? 10413 : 10441;

          const extraToAdd = product?.extras.find(
            (item: any) => item.id === itemIdToFilter
          );

          if (extraToAdd) {
            const itemValues = [];
            const ImpLimite = extraToAdd.ImpLimite[type];
            const ImpLimiteMaximo = extraToAdd.ImpLimiteMaximo[type];
            const ImpTramoLimite = extraToAdd.ImpTramoLimite[type];
            const IndLimiteOpcional = extraToAdd.IndLimiteOpcional[type];

            if (IndLimiteOpcional) {
              for (
                let index = ImpLimite;
                index <= ImpLimiteMaximo;
                index = index + ImpTramoLimite
              ) {
                itemValues.push(index);
              }
            } else {
              itemValues.push(ImpLimite);
            }

            const obj = {
              idGarantia: extraToAdd.id,
              Num_opcion_Contratacion: extraToAdd.Num_opcion_Contratacion,
              Capital: itemValues[0],
              limit: itemValues[0],
              title: extraToAdd.title,
            };
            newArray.push(obj);
          }
        }

        
      } else {
        //This is necessary as these two extras with ids 10441,10413 are always connected with each other so both of these have to be disabled
        newArray = newArray.filter((e: any) => e.idGarantia !== item?.id);

        if (item.id === 10441 || item.id === 10413) {
          const itemIdToFilter = item.id === 10441 ? 10413 : 10441;

          newArray = newArray.filter(
            (item: any) => item.idGarantia !== itemIdToFilter
          );
        }
      }
      newProductOverCosts[type] = newArray;

      let irisExtraAll = [];

      if (product?.providers[type] === PRODUCT_PROVIDERS.IRIS) {
        if (irisExtraSelected && irisExtraSelected[type]?.derogation === IRIS_DEROGATION_CRUCERO) {
          irisExtraAll = product.irisExtras.filter((extra: any) => extra?.derogation === IRIS_DEROGATION_70_80_CRUCERO || extra?.derogation === IRIS_DEROGATION_CRUCERO);
        } else if (irisExtraSelected && (irisExtraSelected[type]?.derogation === IRIS_DEROGATION_DEPORTE_CRUCERO || irisExtraSelected[type]?.derogation === IRIS_DEROGATION_DEPORTE)) {
          irisExtraAll = product.irisExtras.filter((extra: any) => extra?.derogation === IRIS_DEROGATION_70_80);
        }else if (!irisExtraSelected[type]) {
          irisExtraAll = product.irisExtras.filter((extra: any) => extra?.derogation === IRIS_DEROGATION_70_80);
        }
      }

      dispatch(
        getFilteredProductPrices({
          productId: product.productIds[type],
          extras: newArray,
          filter: {
            origin: filter.origin,
            destination: filter.destination,
            departureDate: format(new Date(filter.departureDate), "yyyy-MM-dd"),
            returnDate: format(new Date(filter.returnDate), "yyyy-MM-dd"),
            totalTravellers: filter.totalTravellers,
          },
          variant: type,
          irisExtraAll: irisExtraAll ?? [],
          variants: product.variants,
          irisExtraSelected: irisExtraSelected[type],
        })
      );
      dispatch(
        setProductOverCosts({
          productOverCosts: { ...newProductOverCosts },
        })
      );
    }

    setValue(event.target.value);
  };

  return (
    <>
      {list?.length > 0 ? (
        <TextField
          className={`capitalize`}
          variant={"outlined"}
          select
          onChange={changeHandler}
          value={value}
          size={"small"}
        >
          {list.map((listItem: any) => {
            return (
              <MenuItem key={listItem.value} value={listItem.value}>
                {listItem.label}
              </MenuItem>
            );
          })}
        </TextField>
      ) : (
        <CancelIcon className="text-gray-400 fill-current" />
      )}
    </>
  );
};

interface ExtraItemProps {
  item?: any;
  type: string;
}

const ExtraItem: React.FC<ExtraItemProps> = (props) => {
  const { item, type } = props;
  const dispatch = useDispatch();

  const { filter }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.product
  );

  const limitObj = item.limits?.find((limit: any) => limit.name === type);
  const limit = limitObj?.value;
  const noOptionLabel = limitObj?.noOptionLabel;
  const [value, setValue] = useState<any>("");

  const isPrice = !isNaN(limit);

  const { productOverCosts, originalPrices, prices, product }: any =
    useSelector<any>(({ Travelfine }) => Travelfine.product);

  const list = useMemo(() => {
    const isLimitMultiple = item?.isLimitMultiple;

    const typeLimitsList = isLimitMultiple ? item?.limitsList[type] : [];

    const limitList: any = [];

    if (isLimitMultiple) {
      typeLimitsList.forEach(({ limit }: any) => {
        limitList.push({ label: limit.toString() + "€", value: limit });
      });
    }

    const val = isLimitMultiple
      ? limitList
      : limit === "No/Si"
        ? [
          { label: noOptionLabel ? noOptionLabel : "No", value: "No" },
          { label: "Si", value: "Si" },
        ]
        : [
          { label: noOptionLabel ? noOptionLabel : "No", value: "No" },
          {
            label: isPrice ? numberWithCommas(limit) + "€" : limit,
            value: limit,
          },
        ];
    return val;
  }, [isPrice, item, limit, type, noOptionLabel]);

  useEffect(() => {
    let val = list && list[0] && list[0].value;

    if (!item.isLimitMultiple) {
      const itemProductOverCosts = productOverCosts[type];
      itemProductOverCosts.forEach(({ id }: { id: any }) => {
        if (id === item.id) {
          val = limit === "No/Si" ? "Si" : limit;
        }
      });
    } else {
      const itemProductOverCosts = productOverCosts[type];
      itemProductOverCosts.forEach(({ id, limit }: { id: any; limit: any }) => {
        if (id === item.id) {
          val = limit;
        }
      });
    }
    setValue(val);
  }, [item, list, type, productOverCosts, limit]);

  const changeHandler = (event: any) => {
    const newProductOverCosts = JSON.parse(JSON.stringify(productOverCosts));

    const newPrices = JSON.parse(JSON.stringify(prices));

    newPrices[type] = originalPrices[type];
    let newArray = [...newProductOverCosts[type]];
    const isOverCostAlreadyAdded = newArray.find((e: any) => e.id === item.id);

    if (isOverCostAlreadyAdded) {
      newArray = newArray.filter((e: any) => e.id !== item?.id);
    }
    if (event.target.value !== "No") {
      if (!item?.isLimitMultiple) {
        //Checking if category is vacacional and variant is premium as this is custom feature only for this category and variant
        if (
          (filter?.modality?.key === "HOLIDAY" && type === "RECOMENDADO") ||
          (filter?.modality?.key === "GLOBAL" && type === "VIP")
        ) {
          const isCancellationAlreadyAdded = newArray.find(
            (e: any) => e.key === "annulmentAndRefun"
          );
          if (item.key === "cruceros" && !isCancellationAlreadyAdded) {
            const cancellationExtra = product?.extras.find(
              (e: any) => e.key === "annulmentAndRefun"
            );
            let overcostAdded = cancellationExtra?.OverCostsObj[type].value;
            overcostAdded = Number(overcostAdded.toFixed(2));
            const overcostType = cancellationExtra?.OverCostsObj[type].type;

            const obj = {
              _id: cancellationExtra._id,
              id: cancellationExtra.id,
              title: cancellationExtra.title,
              overcost: cancellationExtra?.OverCostsObj[type].value,
              key: cancellationExtra.key,
              limit: event.target.value,
              overcostAdded,
              hasCode: cancellationExtra.hasCode,
              overcostType,
            };
            newArray.push(obj);
          }
        } else {
          const isCrucerosAlreadyAdded = newArray.find(
            (e: any) => e.key === "cruceros"
          );
          const isSportAlreadyAdded = newArray.find(
            (e: any) => e.key === "sportsAdventure"
          );
          if (item.key === "sportsAdventure" && isCrucerosAlreadyAdded) {
            newArray = newArray.filter((e: any) => e.key !== "cruceros");
          } else if (item.key === "cruceros" && isSportAlreadyAdded) {
            newArray = newArray.filter((e: any) => e.key !== "sportsAdventure");
          }
        }

        let overcostAdded = item?.OverCostsObj[type].value;
        overcostAdded = Number(overcostAdded.toFixed(2));
        const overcostType = item?.OverCostsObj[type].type;

        const obj = {
          _id: item._id,
          id: item.id,
          title: item.title,
          overcost: item?.OverCostsObj[type].value,
          key: item.key,
          limit: event.target.value,
          overcostAdded,
          hasCode: item.hasCode,
          overcostType,
        };
        newArray.push(obj);
      } else {
        let overcostObj = item?.limitsList[type].find(
          ({ limit }: any) => limit.toString() === event.target.value.toString()
        );

        let overcostAdded = overcostObj.overcost;
        overcostAdded = Number(overcostAdded.toFixed(2));

        const obj = {
          _id: item._id,
          id: item.id,
          title: item.title,
          overcost: overcostAdded,
          key: item.key,
          overcostAdded,
          limit: overcostObj.limit,
          hasCode: item.hasCode,
        };
        newArray.push(obj);
      }
    }

    newProductOverCosts[type] = newArray;
    let totalOvercostsAdded = 0;
    newArray.forEach((item, i) => {
      if (item.overcostType !== "percentage") {
        totalOvercostsAdded = totalOvercostsAdded + item.overcostAdded;
      }
    });
    const totalStaticPrice = newPrices[type] + totalOvercostsAdded;
    newArray.forEach((item, i) => {
      if (item.overcostType === "percentage") {
        totalOvercostsAdded =
          totalOvercostsAdded + (item?.overcostAdded * totalStaticPrice) / 100;
      }
    });

    newPrices[type] = newPrices[type] + totalOvercostsAdded;

    dispatch(
      setProductOverCosts({
        productOverCosts: { ...newProductOverCosts },
        prices: { ...newPrices },
      })
    );

    setValue(event.target.value);
  };

  const defaultValue = item?.limits?.find(
    (item: any) => item.name === type
  )?.defaultValue;

  //Checking if connected extra is already added to the overcosts
  const isConnectedExtraEnabled =
    ((filter?.modality?.key === "HOLIDAY" && type === "RECOMENDADO") ||
      (filter?.modality?.key === "GLOBAL" && type === "VIP")) &&
    productOverCosts[type].find((item: any) => item.key === "cruceros") &&
    item.key !== "cruceros";

  return (
    <>
      {defaultValue ? (
        defaultValue === "Si" ? (
          <CheckCircleIcon className="text-green-300 fill-current" />
        ) : defaultValue === "limit" ? (
          <>{item?.limits?.find((item: any) => item.name === type)?.value}€</>
        ) : (
          /* defaultValue === No */ <CancelIcon className="text-gray-400 fill-current" />
        )
      ) : (
        <TextField
          className={`capitalize`}
          variant={"outlined"}
          select
          onChange={changeHandler}
          value={value}
          size={"small"}
          disabled={isConnectedExtraEnabled}
        >
          {list.map((listItem: any) => {
            return (
              <MenuItem key={listItem.value} value={listItem.value}>
                {listItem.label}
              </MenuItem>
            );
          })}
        </TextField>
      )}
    </>
  );
};

interface ExtraRowItemProps {
  className?: string;
  item?: any;
  type: string;
  singleItem?: boolean;
}

const ExtraRowItem: React.FC<ExtraRowItemProps> = (props) => {
  const { className, item, type, singleItem } = props;
  const [searchParams] = useSearchParams();
  const variantParams: any = searchParams.get("variant");
  const theme: any = useTheme();
  const { code }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);

  const isExistCode = code?.codeKey?.toUpperCase().includes(GLOBAL_FINE.code?.toUpperCase()) ?? false;
  const colorSelect = isExistCode ? theme.palette.secondary.main: '#fdc413';

  const { product }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.product
  );

  return (
<td
      className={` ${variantParams === type ? 'border-2':'border'} py-2 text-center px-2 ${className}`}
      style={{
        borderColor: `${variantParams === type ? colorSelect : ''}`,
        borderTopColor: 'rgb(243 244 246)',
        borderBottomColor: 'rgb(243 244 246)'
      }}
    >
  <div className="flex items-center justify-between lg:block">
    {item && singleItem && <ItemTitle item={item} />}

    {item?.isIrisApiExtra ? (
      product.providers[type] !== PRODUCT_PROVIDERS.IRIS ? (
        <CancelIcon className="text-gray-400 fill-current" />
      ) : (
        <IrisApiExtraItem item={item} type={type} />
      )
    ) : product.providers[type] === PRODUCT_PROVIDERS.IRIS ? (
      <CancelIcon className="text-gray-400 fill-current" />
    ) : (
      <ExtraItem item={item} type={type} />
    )}
  </div>
    </td >
  );
};

export default ExtraRowItem;
