import { UserActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: UserState = {
  loading: false,
  municipals: [],
  points: 0,
  // user:{},
};

const UserReducer = (
  state: UserState = INITIAL_STATE,
  action: Action
): UserState => {
  switch (action.type) {
    case UserActionTypes.GET_MUNICIPALS:
      return {
        ...state,
        municipals: [],
      };

    case UserActionTypes.GET_MUNICIPALS_SUCCESS:
      const { municipals } = action.payload;
      return {
        ...state,
        municipals,
      };

    case UserActionTypes.GET_USER_SUCCESS:
      const { user } = action.payload;
      return {
        ...state,
        user,
      };

    case UserActionTypes.GET_USER_POINTS:
      return {
        ...state,
        points: 0,
      };

    case UserActionTypes.GET_USER_POINTS_SUCCESS:
      const points = action?.payload?.userPoints?.points
        ? Number(action?.payload?.userPoints?.points.toFixed(2))
        : 0;
      return {
        ...state,
        points,
      };

    case UserActionTypes.SET_USER_LOADER:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
