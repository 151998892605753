import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Collapse } from "@mui/material";
import { useState } from "react";
import DOMPurify from "dompurify";

interface ItemTitleProps {
  item: any;
}

const ItemTitle: React.FC<ItemTitleProps> = (props) => {
  const { item } = props;

  const [checked, setChecked] = useState(false);
  const isClickable = item.description;

  const sanitizedHTML = DOMPurify.sanitize(item.description);

  return (
    <div className="flex space-x-2 items-center w-3/4 lg:w-full">
      {!checked && (
        <AddCircleOutlineIcon
          className={`${isClickable ? "cursor-pointer" : "opacity-0"}`}
          onClick={() => {
            isClickable && setChecked(true);
          }}
        />
      )}
      {checked && (
        <RemoveCircleOutlineIcon
          className={`${isClickable ? "cursor-pointer" : "opacity-0"}`}
          onClick={() => {
            isClickable && setChecked(false);
          }}
        />
      )}

      <div className="flex flex-col itemDescription">
        <p className={`text-left font-bold`}>{item.title}</p>
        <Collapse in={checked}>
          <div
            className="text-sm"
            style={{ textAlign: "initial" }}
            dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
          />
          {/* <p className="text-justify text-xs mt-1">{item.description}</p> */}
        </Collapse>
      </div>
    </div>
  );
};

export default ItemTitle;
