// import { useSearchParams } from "react-router-dom";

interface ExternalLinkProps {
  href: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const ExternalLink: React.FC<ExternalLinkProps> = (props) => {
  const { href, className, style, disabled } = props;
  // const [searchParams] = useSearchParams();

  // const userTypeParams = searchParams.get("userType");

  const { children } = props;

  return (
    <>
      <a
        href={href}
        style={style}
        // target={userTypeParams !== "collaborator" ? "_blank" : undefined}
        target={disabled ? "_self" : "_blank"}
        rel="noreferrer"
        className={`${className}`}
      >
        {children}
      </a>
    </>
  );
};

export default ExternalLink;
