import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { Loader, ProfileCover } from "@components";
import { updatePassword } from "@store/user/UserActions";
import UpdatePasswordForm from "./components/UpdatePasswordForm";

const defaultValues = {
  oldPassword: "",
  password: "",
  passwordConfirm: "",
};

interface UpdatePasswordProps {}

const UpdatePassword: React.FC<UpdatePasswordProps> = (props) => {
  const dispatch = useDispatch();

  const { loading }: any = useSelector<any>(({ Travelfine }) => Travelfine.app);

  const schema = yup.object().shape({
    oldPassword: yup
      .string()
      .required("Por favor, introduzca sucontraseña")
      .min(
        6,
        "La contraseña es demasiado corta, debe tener al menos 6 caracteres"
      ),
    password: yup
      .string()
      .required("Por favor, introduzca sucontraseña")
      .min(
        6,
        "La contraseña es demasiado corta, debe tener al menos 6 caracteres"
      ),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden"),
  });

  const { control, formState, handleSubmit } = useForm({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const onSubmit = (data: any) => {
    const payload = {
      oldPassword: data.oldPassword,
      newPassword: data.password,
    };
    dispatch(updatePassword(payload));
  };

  return (
    <div className="flex flex-col lg:px-10vw">
      <Loader loading={loading} />
      <ProfileCover />

      <div className="flex flex-col px-4 lg:px-10vw py-20">
        <UpdatePasswordForm
          control={control}
          errors={errors}
          onSubmit={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
};

export default UpdatePassword;
