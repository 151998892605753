import { useSelector } from "react-redux";
import IrisExtraRowItem from "./IrisExtraRowItem";
import ItemTitle from "./ItemTitle";
import ExtraRowItem from "./ExtraRowItem";

interface ExtraProps {
  item: any;
  type?: string;
  isIRISExtra?: boolean;
}

const Extra: React.FC<ExtraProps> = (props) => {
  const { item, type, isIRISExtra } = props;

  const { product }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.product
  );

  return (
    <tr>
      {!type && (
        <td className="px-4 py-2 border border-gray-100">
          <ItemTitle item={item} />
        </td>
      )}
      {type ? (
        !isIRISExtra ? (
          <ExtraRowItem type={type} item={item} singleItem />
        ) : (
          <IrisExtraRowItem type={type} item={item} singleItem />
        )
      ) : (
        <>
          {product?.variants?.length > 0 &&
            product?.variants.map((variant: any) =>
              !isIRISExtra ? (
                <ExtraRowItem key={variant} type={variant} item={item} />
              ) : (
                <IrisExtraRowItem type={variant} item={item} />
              )
            )}
        </>
      )}
    </tr>
  );
};

export default Extra;
