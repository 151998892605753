import { ImageAsset } from "@components";
import { getFormattedDate } from "@helpers/utils";

interface PurchasedDetailCardMobileProps {
  policy: any;
}

const PurchasedDetailCardMobile: React.FC<PurchasedDetailCardMobileProps> = (
  props
) => {
  const { policy } = props;

  return (
    <div className="lg:hidden flex flex-col">
      <div className="">
        <div className="flex justify-between">
          <div className="flex flex-col">
            <p className="text-gray-500 text-sm">Origen</p>
            <p className="font-semibold text-base">
              {policy?.travelInfo?.origin?.value}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="text-gray-500 text-sm">Destino</p>
            <p className="font-semibold text-base">
              {policy?.travelInfo?.destination?.value}
            </p>
          </div>
        </div>
        <div className="flex items-center space-x-5">
          <div className="h-1 bg-gray-300 w-full" />
          <ImageAsset src="aeroplaneYellow" className="w-6" />
          <div className="h-1 bg-gray-300 w-full" />
        </div>
        <div className="flex justify-between">
          <div className="flex flex-col">
            <p className="text-gray-500 text-sm">Fecha De Salida</p>
            <p className="font-semibold text-base">
              {policy?.travelInfo?.departureDate &&
                getFormattedDate(policy?.travelInfo?.departureDate)}
            </p>
          </div>

          <div className="flex flex-col">
            <p className="text-gray-500 text-sm">Fecha De Retorno</p>
            <p className="font-semibold text-base">
              {policy?.travelInfo?.returnDate &&
                getFormattedDate(policy?.travelInfo?.returnDate)}
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 mt-10">
        <div className="flex flex-col mt-3">
          <p className="text-gray-500 text-sm">Nombre</p>
          <p className="font-semibold text-base">{policy?.user?.name}</p>
        </div>
        <div className="flex flex-col mt-3">
          <p className="text-gray-500 text-sm">Tipo documento</p>
          <p className="font-semibold text-base">
            {policy?.user?.documentType}
          </p>
        </div>
        <div className="flex flex-col mt-3">
          <p className="text-gray-500 text-sm">Apellidos</p>
          <p className="font-semibold text-base">{policy?.user?.surname}</p>
        </div>
        <div className="flex flex-col mt-3">
          <p className="text-gray-500 text-sm">Nº documento</p>
          <p className="font-semibold text-base">
            {policy?.user?.documentNumber}
          </p>
        </div>
        <div className="flex flex-col mt-3">
          <p className="text-gray-500 text-sm">Ciudad</p>
          <p className="font-semibold text-base">
            {policy?.user?.city?.label
              ? policy?.user?.city?.label
              : policy?.user?.city}
          </p>
        </div>
        <div className="flex flex-col mt-3">
          <p className="text-gray-500 text-sm">Codigo Postal</p>
          <p className="font-semibold text-base">{policy?.user?.postalCode}</p>
        </div>
      </div>
      <div className="flex flex-col mt-3">
        <p className="text-gray-500 text-sm">Pais</p>
        <p className="font-semibold text-base">{policy?.user?.country}</p>
      </div>
      <div className="flex flex-col mt-3">
        <p className="text-gray-500 text-sm">Dirección</p>
        <p className="font-semibold text-base">{policy?.user?.address}</p>
      </div>
      <div className="flex flex-col mt-3">
        <p className="text-gray-500 text-sm">Telefono</p>
        <p className="font-semibold text-base">{policy?.user?.phoneNo}</p>
      </div>
      <div className="flex flex-col mt-3">
        <p className="text-gray-500 text-sm">Email</p>
        <p className="font-semibold text-base">{policy?.user?.email}</p>
      </div>
    </div>
  );
};

export default PurchasedDetailCardMobile;
