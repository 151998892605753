import { Navigate, useLocation } from "react-router-dom";

interface ProtectedRouteProps {
  children: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { children } = props;

  const { pathname } = useLocation();

  if (
    !localStorage.getItem("tr_access_token") &&
    pathname !== "/dashboard/success/"
  ) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
