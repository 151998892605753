import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAdvantages } from "@store/advantage/AdvantageActions";
import AdvantageCard from "./components/AdvantageCard";
import { Loader } from "@components";

interface AdvantagesProps {}

const limit = 20;

const Advantages: React.FC<AdvantagesProps> = (props) => {
  const dispatch = useDispatch();
  const [currentPageNo, setCurrentPageNo] = useState(1);

  const { loading }: any = useSelector<any>(({ Travelfine }) => Travelfine.app);
  const { advantages, totalAdvantages }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.advantage
  );

  const observer = useRef<any>();

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        const hasMore = currentPageNo < Math.ceil(totalAdvantages / limit);
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPageNo(currentPageNo + 1);
          const payload = {
            page: currentPageNo + 1,
            limit,
          };
          dispatch(getAdvantages(payload));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, currentPageNo, dispatch, totalAdvantages]
  );

  useEffect(() => {
    const payload = {
      page: 1,
      limit,
    };
    dispatch(getAdvantages(payload));
  }, [dispatch]);

  return (
    <div className="flex flex-col lg:px-10vw">
      <p className="font-bold mb-3 text-center lg:text-left text-lg">
        Tus beneficios
      </p>
      <Loader loading={loading} />
      <div className="grid lg:grid-cols-5 items-center gap-3 pb-6">
        {advantages.map((advantage: any) => {
          return (
            <AdvantageCard
              key={advantage?._id}
              advantage={advantage}
              lastElementRef={lastElementRef}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Advantages;
