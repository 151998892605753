import { ProductActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: ProductState = {
  loading: false,
  product: {},
  productOverCosts: {},
  prices: {},
  originalPrices: {},
  irisExtraSelected: {},
  filter: {
    origin: {
      label: "España y Andorra",
      value: "España y Andorra",
      code: "ES",
      region: "europa",
    },
    destination: { label: "Todo el mundo", value: "Todo el mundo", code: "WR" },
    departureDate: new Date(),
    returnDate: new Date(),
    totalTravellers: 1,
    userInDestination: false,
  },
  variantsAgeLimit: null,
  irisExtraAll: []
};

const ProductReducer = (
  state: ProductState = INITIAL_STATE,
  action: Action
): ProductState => {
  switch (action.type) {
    case ProductActionTypes.GET_FILTERED_PRODUCT:
      return {
        ...state,
        product: {},
        productOverCosts: {},
        prices: {},
        originalPrices: {},
        irisExtraSelected: {},
        variantsAgeLimit: null,
      };

    case ProductActionTypes.GET_FILTERED_PRODUCT_SUCCESS: {
      const { product, budgetExtras } = action.payload;

      let productOverCosts: any = {};
      let irisExtraSelected: any = {};
      let variantsAgeLimit = null;

      if (product?.variantsAgeLimit) {
        variantsAgeLimit = product.variantsAgeLimit;
      }

      if (product?.variants?.length > 0) {
        product.variants.forEach((variant: any) => {
          if (
            product.extraSent &&
            product.extraSent[variant] &&
            product.extraSent[variant].length > 0
          ) {
            productOverCosts[variant] = [...product.extraSent[variant]];
          } else {
            productOverCosts[variant] = [];
          }

          // Iris extras
          if (
            budgetExtras &&
            budgetExtras?.irisExtraSelected &&
            budgetExtras?.irisExtraSelected[variant] !== null
          ) {
            irisExtraSelected[variant] =
              budgetExtras.irisExtraSelected[variant];
          } else {
            irisExtraSelected[variant] = null;
          }
        });
      }

      if (
        product?.extras &&
        product?.extras[0] &&
        product.extras[0].isLimitMultiple
      ) {
        if (product?.variants?.length > 0) {
          product.variants.forEach((variant: any) => {
            const overcostAdded =
              product.extras[0]?.limitsList[variant][0].overcost;
            const obj = {
              id: product.extras[0].id,
              title: product.extras[0].title,
              overcost: overcostAdded,
              key: product.extras[0].key,
              overcostAdded: overcostAdded,
              hasCode: product.extras[0].hasCode,
              limit: product.extras[0].limitsList[variant][0].limit,
            };
            productOverCosts[variant] = [obj];
          });
        }
      }

      const prices = product.price;
      const originalPrices = JSON.parse(JSON.stringify(product.price));

      //Adding default overcosts to the product in case any of the extras has "Si" or "limit" as the default value set.
      product?.variants.forEach((variant: string) => {
        product?.extras.forEach((extra: any) => {
          const item = extra?.limits?.find(
            (item: any) => item.name === variant
          );
          if (item?.defaultValue === "Si" || item?.defaultValue === "limit") {
            prices[variant] = JSON.parse(
              JSON.stringify(originalPrices[variant])
            );
            let newArray = [...productOverCosts[variant]];

            let overcostAdded = extra?.OverCostsObj[variant].value;
            overcostAdded = Number(overcostAdded.toFixed(2));
            const overcostType = extra?.OverCostsObj[variant].type;

            const obj = {
              _id: extra._id,
              id: extra.id,
              title: extra.title,
              overcost: extra?.OverCostsObj[variant].value,
              key: extra.key,
              limit: "Si",
              overcostAdded,
              hasCode: extra.hasCode,
              overcostType,
            };
            newArray.push(obj);

            productOverCosts[variant] = newArray;
            let totalOvercostsAdded = 0;
            newArray.forEach((item, i) => {
              if (item.overcostType !== "percentage") {
                totalOvercostsAdded = totalOvercostsAdded + item.overcostAdded;
              }
            });
            const totalStaticPrice = prices[variant] + totalOvercostsAdded;
            newArray.forEach((item, i) => {
              if (item.overcostType === "percentage") {
                totalOvercostsAdded =
                  totalOvercostsAdded +
                  (item?.overcostAdded * totalStaticPrice) / 100;
              }
            });
            prices[variant] = prices[variant] + totalOvercostsAdded;
          }
        });
      });

      return {
        ...state,
        product: product,
        productOverCosts,
        prices,
        originalPrices,
        irisExtraSelected,
        irisExtraAll:[],
        filter: {
          ...state.filter,
          returnDate: action.payload.newEndDay
            ? action.payload.newEndDay
            : state.filter.returnDate,
          dontCallAPI: true,
        },
        variantsAgeLimit,
      };
    }

    case ProductActionTypes.GET_FILTERED_PRODUCT_PRICES_SUCCESS: {
      const { productPrices, variant, irisPriceObj, irisExtraAll } = action.payload;

      const newPrices = JSON.parse(JSON.stringify(state.prices));

      const newProduct = JSON.parse(JSON.stringify(state.product));

      newPrices[variant] = productPrices;

      if (Object.keys(newProduct).length > 0) {
        newProduct.irisPriceObj[variant] = irisPriceObj;
        newProduct.irisExtraAll = irisExtraAll;
      }
      
      return {
        ...state,
        prices: newPrices,
        product: newProduct,
        irisExtraAll: irisExtraAll
      };
    }

    case ProductActionTypes.SET_PRODUCT_FILTER:
      return {
        ...state,
        filter: {
          modality:
            action.payload.modality === "reset"
              ? undefined
              : action.payload.modality
                ? action.payload.modality
                : state.filter.modality,
          origin: action.payload.origin
            ? action.payload.origin
            : state.filter.origin,
          destination: action.payload.destination
            ? action.payload.destination
            : state.filter.destination,
          departureDate: action.payload.departureDate
            ? action.payload.departureDate
            : state.filter.departureDate,
          returnDate: action.payload.returnDate
            ? action.payload.returnDate
            : state.filter.returnDate,
          totalTravellers: action.payload.totalTravellers
            ? action.payload.totalTravellers
            : state.filter.totalTravellers,
          codeKey: action.payload.code,
          userInDestination:
            action.payload.userInDestination !== undefined
              ? action.payload.userInDestination
              : state.filter.userInDestination,
          pricing:
            action.payload.pricing === "reset"
              ? undefined
              : action.payload.pricing !== undefined
                ? action.payload.pricing
                : state.filter.pricing,
        },
      };

    case ProductActionTypes.SET_PRODUCT_OVERCOSTS:
      const newPrices = action.payload.prices
        ? action.payload.prices
        : state.prices;
      return {
        ...state,
        productOverCosts: action.payload.productOverCosts,
        prices: newPrices,
      };

    case ProductActionTypes.SET_PRODUCT_IRIS_EXTRA_SELECTED:
      return {
        ...state,
        irisExtraSelected: action.payload,
      };

    case ProductActionTypes.SET_PRODUCT_LOADER:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default ProductReducer;
