import { ImageAsset } from "@components";
import { useRef, useState } from "react";

import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

import DashboardTab from "./components/DashboardTab";
import { Drawer, Menu, MenuItem } from "@mui/material";

interface DashboardProps {}

const userTabs = [
  { text: "Compras", route: "/dashboard/purchases" },
  { text: "Tus beneficios", route: "/dashboard/benefits" },
  { text: "Club Travelfine", route: "/dashboard/rewards" },
  { text: "Contratar Seguro", route: "/" },
];

const successScreenStyles = {
  background: `linear-gradient(247.81deg, rgba(21, 145, 202, 0.82) 17.42%, rgba(21, 145, 202, 0) 65.03%)`,
  backgroundColor: "#F3F3F3",
  minHeight: "100vh",
};

const Dashboard: React.FC<DashboardProps> = (props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ref = useRef<any>(null);

  const toggleDrawer = (toggle: any) => {
    setOpenDrawer(toggle);
  };

  const handleClick = (event: any) => {
    setAnchorEl(ref.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [searchParams] = useSearchParams();

  const userTypeParams = searchParams.get("userType");
  const codeParams = searchParams.get("code");

  const isCollaborator = userTypeParams === "collaborator";

  return (
    <div
      className={`${pathname === "/dashboard/success" && "relative"}`}
      style={
        pathname === "/dashboard/success"
          ? successScreenStyles
          : { backgroundColor: "#F3F3F3", minHeight: "100vh" }
      }
    >
      <div className="flex lg:space-x-6 justify-end lg:justify-between py-2  lg:py-10 px-10vw">
        {isCollaborator && (
          <div className="flex justify-center w-full">
            <DashboardTab
              tab={{
                text: "Contratar Seguro",
                route: `/?userType=collaborator&code=${codeParams}`,
              }}
            />
          </div>
        )}
        {!isCollaborator && (
          <>
            <div className="hidden lg:flex flex-wrap lg:flex-nowrap space-y-2 lg:space-y-0 lg:space-x-2 w-full">
              {userTabs.map((tab) => {
                return <DashboardTab key={tab.route} tab={tab} />;
              })}
            </div>
            <div className="flex self-end lg:self-center space-x-2 items-center">
              <div className="cursor-pointer" ref={ref} onClick={handleClick}>
                <ImageAsset className="object-contain w-10 h-10" src="user" />
              </div>
            </div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className="mt-1"
            >
              <MenuItem
                onClick={() => {
                  navigate("/dashboard/profile");
                  handleClose();
                }}
              >
                Perfil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/dashboard/update-profile");
                  handleClose();
                }}
              >
                Editar perfil
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate("/dashboard/update-password");
                  handleClose();
                }}
              >
                Cambia la contraseña
              </MenuItem>
              <MenuItem
                onClick={() => {
                  localStorage.removeItem("tr_access_token");
                  localStorage.removeItem("userId");
                  handleClose();
                  navigate("/");
                }}
              >
                Cerrar sesión
              </MenuItem>
            </Menu>
          </>
        )}
        {!isCollaborator && (
          <>
            <MenuIcon
              className="self-center ml-3 lg:hidden"
              onClick={() => toggleDrawer(!openDrawer)}
            />
            <Drawer
              anchor={"right"}
              open={openDrawer}
              onClose={() => {
                toggleDrawer(false);
              }}
            >
              <div className="flex flex-col py-8 space-y-4 px-4">
                {userTabs.map((tab) => {
                  return <DashboardTab key={tab.route} tab={tab} />;
                })}
              </div>
            </Drawer>
          </>
        )}
      </div>
      <Outlet />
    </div>
  );
};

export default Dashboard;
