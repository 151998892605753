import { ExternalLink, Input } from "@components";
import { Paper } from "@mui/material";

interface PurchaseConditionsFormProps {
  control: any;
  errors: any;
}

const PurchaseConditionsForm: React.FC<PurchaseConditionsFormProps> = (
  props
) => {
  const { control, errors } = props;

  return (
    <>
      <p className="font-bold text-xl mt-8">Condiciones de compra</p>
      <Paper elevation={2} className="rounded-lg px-4 py-4 mt-4">
        <div className="flex flex-col lg:ml-3">
          <Input
            label={
              <label>
                Acepto la{" "}
                <ExternalLink href="https://travelfine.es/politica-de-privacidad">
                  <span className="underline hover:text-primary transition duration-300 ease-in-out">
                    Protección de Datos de Carácter Personal*
                  </span>
                </ExternalLink>
              </label>
            }
            name="confirmations.acceptDataProtection"
            control={control}
            error={!!errors?.confirmations?.acceptDataProtection}
            errorMessage={errors?.confirmations?.acceptDataProtection?.message}
            className="mb-2"
            size="small"
            type="checkbox"
            color="secondary"
          />
          <Input
            label="Acepto que se me facilite la información previa relativa a este contrato de seguro a través de esta web y estar informado sobre ofertas y promociones de vuestros productos"
            name="confirmations.acceptCommercialInfo"
            control={control}
            error={!!errors?.confirmations?.acceptCommercialInfo}
            errorMessage={errors?.confirmations?.acceptCommercialInfo?.message}
            className="mb-2"
            size="small"
            type="checkbox"
            color="secondary"
          />
          <Input
            label="Acepto las Condiciones Generales del Seguro y de contratación*"
            name="confirmations.acceptTermConditions"
            control={control}
            error={!!errors?.confirmations?.acceptTermConditions}
            errorMessage={errors?.confirmations?.acceptTermConditions?.message}
            className="mb-2"
            size="small"
            type="checkbox"
            color="secondary"
          />
        </div>
      </Paper>
    </>
  );
};

export default PurchaseConditionsForm;
