import { ImageAsset, PaymentStatusBadge } from "@components";
import { getFormattedDate } from "@helpers/utils";
import { useNavigate } from "react-router-dom";

interface PurchasedCardProps {
  policy: any;
  lastElementRef: any;
}

const PurchasedCard: React.FC<PurchasedCardProps> = (props) => {
  const { policy, lastElementRef } = props;

  const navigate = useNavigate();

  const cardClickHandler = () => {
    navigate("/dashboard/purchases/" + policy?._id);
  };
  return (
    <div
      className="flex flex-col px-4 lg:px-10vw shadow-lg bg-white py-4 mt-3 justify-between cursor-pointer"
      ref={lastElementRef}
      onClick={cardClickHandler}
    >
      <div className="flex space-x-2">
        <div className="flex flex-col">
          <p className="text-gray-500">Origen</p>
          <p className="font-bold">{policy?.travelInfo?.origin?.value}</p>
          <p className="text-gray-500 mt-5">Fecha De Salida</p>
          <p className="font-bold">
            {policy?.travelInfo?.departureDate &&
              getFormattedDate(policy?.travelInfo?.departureDate)}
          </p>
        </div>
        <div className="flex items-center justify-center w-1/2 space-x-3">
          <div className="h-1 bg-gray-300 w-full lg:w-1/4" />
          <ImageAsset src="aeroplaneYellow" className="w-6" />
          <div className="h-1 bg-gray-300 w-full lg:w-1/4" />
        </div>
        <div className="flex flex-col">
          <p className="text-gray-500">Destino</p>
          <p className="font-bold">{policy?.travelInfo?.destination?.value}</p>
          <p className="text-gray-500 mt-5">Fecha De Retorno</p>
          <p className="font-bold">
            {policy?.travelInfo?.returnDate &&
              getFormattedDate(policy?.travelInfo?.returnDate)}
          </p>
          <div className="lg:hidden flex flex-col mt-4">
            <p className="text-gray-500">Viajeros</p>
            <p className="font-bold">{policy?.travelInfo?.totalTravellers}</p>
          </div>
        </div>
        <div className="hidden lg:flex flex-col items-center">
          <p className="text-gray-500">Estado De Pago</p>
          <PaymentStatusBadge paymentStatus={policy?.paymentStatus} />
          <p className="text-gray-500 mt-2">Viajeros</p>
          <p className="font-bold">{policy?.travelInfo?.totalTravellers}</p>
        </div>
      </div>
      <div className="lg:hidden flex justify-between items-center mt-4">
        <p className="text-gray-500">Estado De Pago:</p>
        <PaymentStatusBadge paymentStatus={policy?.paymentStatus} />
      </div>
    </div>
  );
};

export default PurchasedCard;
