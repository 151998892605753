import { CategoryActionTypes } from "../redux/actionTypes";

export const getCategories = () => ({
  type: CategoryActionTypes.GET_CATEGORIES,
});

export const getCategoriesSuccess = (payload: any) => ({
  type: CategoryActionTypes.GET_CATEGORIES_SUCCESS,
  payload,
});

export const getCategoryMaximumRange = (payload: any) => ({
  type: CategoryActionTypes.GET_CATEGORY_MAXIMUM_RANGE,
  payload,
});

export const getCategoryMaximumRangeSuccess = (payload: any) => ({
  type: CategoryActionTypes.GET_CATEGORY_MAXIMUM_RANGE_SUCCESS,
  payload,
});

export const setCategoryLoader = (payload: any) => ({
  type: CategoryActionTypes.SET_CATEGORY_LOADER,
  payload,
});
