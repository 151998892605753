import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { Loader, ProfileCover } from "@components";
import { getUser, updateUser } from "@store/user/UserActions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import UpdateProfileForm from "./components/UpdateProfileForm";
import { format } from "date-fns";

interface UpdateProfileProps {}

const defaultValues = {
  name: "",
  surname: "",
  dob: "",
  documentType: "",
  documentNumber: "",
  address: "",
  postalCode: "",
  city: "",
  email: "",
  phoneNo: "",
  country: "",
  province: "",
};

const UpdateProfile: React.FC<UpdateProfileProps> = (props) => {
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    name: yup.string().required("Nombre es obligatorio"),
    surname: yup.string().required("Apellidos obligatorio"),
    dob: yup.string().required("Fecha Nacimiento obligatorio"),
    documentType: yup.string().required("Tipo documento es obligatorio"),
    documentNumber: yup.string().when("documentType", (documentType) => {
      if (documentType === "DNI") {
        return yup
          .string()
          .required("DNI o pasaporte es obligatorio")
          .matches(/^([0-9]{8}[A-Z]{1})$/, "DNI inválido");
      }
      return yup.string().required("No debe estar vacío");
    }),
    address: yup.string().required("Dirección es obligatorio"),
    postalCode: yup.string().required("Codigo postal es obligatorio"),

    city: yup.string().required("Ciudad es obligatorio"),
    email: yup
      .string()
      .email("Debe introducir un mail válido")
      .required("Email es obligatorio"),
    phoneNo: yup.string().required("Telefono es obligatorio"),
    country: yup.string().required("Pais es obligatorio"),
    province: yup.string().required("Provincia es obligatorio"),
  });

  const { control, formState, handleSubmit, reset, setValue } = useForm({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const { loading }: any = useSelector<any>(({ Travelfine }) => Travelfine.app);
  const { user }: any = useSelector<any>(({ Travelfine }) => Travelfine.user);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    if (!user) {
      return;
    }

    /**
     * Reset the form on data changes
     */

    const data = {
      ...user,
    };

    reset(data);
  }, [reset, user]);

  const onSubmit = (data: any) => {
    const payload = {
      name: data.name,
      surname: data.surname,
      dob: format(new Date(data.dob), "yyyy-MM-dd"),
      documentType: data.documentType,
      documentNumber: data.documentNumber,
      address: data.address,
      postalCode: data.postalCode,
      city: data.city,
      email: data.email,
      phoneNo: data.phoneNo,
      country: data.country,
      province: data.province,
    };
    dispatch(updateUser(payload));
  };

  return (
    <div className="flex flex-col lg:px-10vw">
      <Loader loading={loading} />
      <ProfileCover />

      <UpdateProfileForm
        control={control}
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
        setValue={setValue}
      />
    </div>
  );
};

export default UpdateProfile;
