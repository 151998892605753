import { useNavigate } from "react-router-dom";

interface AdvantageCardProps {
  advantage: any;
  lastElementRef: any;
}

const AdvantageCard: React.FC<AdvantageCardProps> = (props) => {
  const { advantage, lastElementRef } = props;

  const navigate = useNavigate();

  const cardClickHandler = () => {
    navigate("/dashboard/benefits/" + advantage?._id);
  };

  return (
    <div
      ref={lastElementRef}
      onClick={cardClickHandler}
      className="flex bg-white px-1 py-1 shadow-md rounded-sm flex-col w-full cursor-pointer h-60 overflow-auto"
    >
      <img
        src={advantage?.image}
        className="object-cover w-full"
        alt="advantage"
        style={{ height: "7.5rem" }}
      />
      <p className="text-center px-1 py-6 text-xs">{advantage?.title}</p>
    </div>
  );
};

export default AdvantageCard;
