import { sharedUrl } from "@api/Endpoint";
import ShareIcon from "@mui/icons-material/Share";
import { Button, Popover } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { axiosInstance } from "@api/axios";
import { filterProductOvercosts, filterIrisExtrasSelected } from "@helpers/utils";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import MetaPage from "@components/MetaPage";

function ShareBudget({ className, data, productId }: any) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [link, setLink] = useState<string>('');
    const { productOverCosts, product, irisExtraSelected }: any = useSelector<any>(
        ({ Travelfine }) => Travelfine.product
    );

    const { provider } = product;
    const { code }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);

    const open = Boolean(anchorEl);
    const popoverId = open ? "simple-popover" : undefined;

    const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        const url = await getLink();
        setLink(url)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getLink = async () => {
        const payload = {
            extrasInfo: {
                productOvercosts: filterProductOvercosts(
                    productOverCosts,
                    data.productVariant
                ),
                irisExtraSelected: filterIrisExtrasSelected(
                    irisExtraSelected,
                    data.productVariant
                ),
            },
            collaboratorCode: code?.codeKey,
            departureDate: format(new Date(data.departureDate), "yyyy-MM-dd"),
            returnDate: format(new Date(data.returnDate), "yyyy-MM-dd"),
            totalTravellers: data.totalTravellers,
            category: data.modality.label,
            destination: {
                value: data.destination.value,
                code: data.destination.code,
                region: data.destination.region ? data.destination.region : "mundo",
            },
            origin: {
                value: data.origin.value,
                code: data.origin.code,
                region: data.origin.region ? data.origin.region : "mundo",
            },
            productId: productId,
            productVariant: data.productVariant,
            productProvider: data?.productProvider ? data?.productProvider : provider,
            price: data.price,
            userInDestination: data.userInDestination,
        };

        const response = await axiosInstance.post(sharedUrl(), { ...payload });
        return response.data.data.link;
    };

    const handleCopyClick = async () => {
        const url = await getLink();
        setLink(url)
    };

    return (
        <div className={className}>
            <MetaPage link={link} />
            <Button
                variant={open ? "outlined" : "text"}
                size="small"
                className="w-64"
                startIcon={<ShareIcon />}
                onClick={handleClick}
            >
                <span className='normal-case'> Compartir presupuestos </span>
            </Button>
            <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <div className="flex flex-col justify-center w-64 py-2 space-y-2">
                    <Button
                        sx={{ textTransform: "capitalize" }}
                        size="small"
                        onClick={() => {
                            const description = `¡Hola! Mira esta imagen y más detalles en el siguiente enlace: ${encodeURIComponent(link)}`;
                            window.location.replace(`https://api.whatsapp.com/send?text=${description}`);
                            handleClose();
                        }
                        }
                    >
                        Whatsapp
                    </Button>
                    <Button
                        sx={{ textTransform: "capitalize" }}
                        size="small"
                        onClick={() => {
                            window.location.href = `mailto:?subject=Presupuesto TravelFine&body=${encodeURIComponent(
                                link
                            )}`;
                            handleClose();
                        }
                        }
                    >
                        Email
                    </Button>
                    <div className="flex place-content-center">
                        <CopyToClipboard
                            text={link}
                            onCopy={() => toast.success('Texto copiado')}
                        >
                            <Button
                                sx={{ textTransform: "capitalize" }}
                                size="small"
                                className="w-full"
                                id='shared'
                                onClick={() => handleCopyClick()}
                            >
                                Copiar enlace
                            </Button>
                        </CopyToClipboard>
                    </div>
                </div>
            </Popover >
        </div >
    );
}
export { ShareBudget };