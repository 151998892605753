import { ImageAsset } from "@components";
import { getFormattedDate } from "@helpers/utils";

interface PurchasedDetailCardDesktopProps {
  policy: any;
}

const PurchasedDetailCardDesktop: React.FC<PurchasedDetailCardDesktopProps> = (
  props
) => {
  const { policy } = props;

  return (
    <div className="hidden lg:grid lg:grid-cols-3">
      <div className="flex flex-col">
        <p className="text-gray-500 text-xl">Origen</p>
        <p className="font-semibold text-2xl">
          {policy?.travelInfo?.origin?.value}
        </p>
        <p className="text-gray-500 text-xl mt-5">Fecha De Salida</p>
        <p className="font-semibold text-2xl">
          {policy?.travelInfo?.departureDate &&
            getFormattedDate(policy?.travelInfo?.departureDate)}
        </p>
      </div>
      <div className="flex items-center space-x-3">
        <div className="h-1 bg-gray-300 w-20" />
        <ImageAsset src="aeroplaneYellow" className="w-6" />
        <div className="h-1 bg-gray-300 w-20" />
      </div>
      <div className="flex flex-col">
        <p className="text-gray-500 text-xl">Destino</p>
        <p className="font-semibold text-2xl">
          {policy?.travelInfo?.destination?.value}
        </p>
        <p className="text-gray-500 text-xl mt-5">Fecha De Retorno</p>
        <p className="font-semibold text-2xl">
          {policy?.travelInfo?.returnDate &&
            getFormattedDate(policy?.travelInfo?.returnDate)}
        </p>
      </div>
      <div className="flex flex-col mt-10">
        <p className="text-gray-500">Nombre</p>
        <p className="font-semibold text-xl">{policy?.user?.name}</p>
      </div>
      <div className="flex flex-col mt-10">
        <p className="text-gray-500">Tipo documento</p>
        <p className="font-semibold text-xl">{policy?.user?.documentType}</p>
      </div>
      <div className="flex flex-col mt-10">
        <p className="text-gray-500">Nº documento</p>
        <p className="font-semibold text-xl">{policy?.user?.documentNumber}</p>
      </div>
      <div className="flex flex-col mt-3">
        <p className="text-gray-500">Apellidos</p>
        <p className="font-semibold text-xl">{policy?.user?.surname}</p>
      </div>
      <div className="flex flex-col mt-3">
        <p className="text-gray-500">Codigo Postal</p>
        <p className="font-semibold text-xl">{policy?.user?.postalCode}</p>
      </div>
      <div></div>
      <div className="flex flex-col mt-3">
        <p className="text-gray-500">Pais</p>
        <p className="font-semibold text-xl">{policy?.user?.country}</p>
      </div>
      <div className="flex flex-col mt-3">
        <p className="text-gray-500">Ciudad</p>
        <p className="font-semibold text-xl">
          {policy?.user?.city?.label
            ? policy?.user?.city?.label
            : policy?.user?.city}
        </p>
      </div>
      <div className="flex flex-col mt-3">
        <p className="text-gray-500">Dirección</p>
        <p className="font-semibold text-xl">{policy?.user?.address}</p>
      </div>
      <div className="flex flex-col mt-3">
        <p className="text-gray-500">Telefono</p>
        <p className="font-semibold text-xl">{policy?.user?.phoneNo}</p>
      </div>
      <div className="flex flex-col mt-3">
        <p className="text-gray-500">Email</p>
        <p className="font-semibold text-xl">{policy?.user?.email}</p>
      </div>
    </div>
  );
};

export default PurchasedDetailCardDesktop;
