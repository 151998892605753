export const BASE = "api end point";

export const getCategoriesUrl = () => {
  return encodeURI("category");
};

export const getFilteredProductUrl = (requestPayload: any) => {
  let url =
    "product/filtered?returnDate=" +
    requestPayload.returnDate +
    "&origin=" +
    JSON.stringify(requestPayload.origin) +
    "&destination=" +
    JSON.stringify(requestPayload.destination) +
    "&departureDate=" +
    requestPayload.departureDate +
    "&modality=" +
    JSON.stringify(requestPayload.modality.key) +
    "&totalTravellers=" +
    requestPayload.totalTravellers +
    "&userInDestination=" +
    requestPayload.userInDestination;

  if (requestPayload.codeKey) {
    url = url + "&codeKey=" + requestPayload.codeKey;
  }
  if (requestPayload.pricing) {
    url = url + "&pricing=" + requestPayload.pricing;
  }

  if (requestPayload.budgetId) {
    url = url + "&budgetId=" + requestPayload.budgetId;
  }

  return encodeURI(url);
};

export const getFilteredProductPricesUrl = () => {
  return encodeURI("product/filteredPrices");
};

export const policyUrl = () => {
  return encodeURI("policy");
};

export const createClientSecretUrl = () => {
  return encodeURI("payment/client_secret");
};

export const budgetUrl = () => {
  return encodeURI("budget");
};

export const sharedUrl = () => {
  return encodeURI("shared");
};

export const getCodeByKeyUrl = (codeKey: any, visitData: any) => {
  let url = "code/?codeKey=" + codeKey;
  if (visitData) {
    url = url + "&visitData=" + JSON.stringify(visitData);
  }
  return encodeURI(url);
};

export const getCollaboratorByCodeUrl = (codeKey: string) => {
  const url = "code/collaborator/?codeKey=" + codeKey;
  return encodeURI(url);
};

export const getMunicipalsUrl = (postalCode: any) => {
  return encodeURI("product/municipals/?postalCode=" + postalCode);
};

export const updatePasswordUrl = () => {
  return encodeURI("user/updatePassword");
};

export const getPoliciesUrl = (payload: any) => {
  const { page = "", limit = "" } = payload;
  return encodeURI("policy?page=" + page + "&limit=" + limit);
};

export const getAdvantagesUrl = (payload: any) => {
  const { page = "", limit = "" } = payload;
  return encodeURI("advantage?page=" + page + "&limit=" + limit);
};

export const advantageByIdUrl = (id: any) => {
  return encodeURI("advantage/" + id);
};

export const policyByIdUrl = (id: any) => {
  return encodeURI("policy/" + id);
};

export const userUrl = () => {
  return encodeURI("user");
};

export const getUserPointsUrl = () => {
  return encodeURI("/points");
};

export const pointTransactionUrl = () => {
  return encodeURI("/points-transaction");
};

export const getRewardsUrl = (payload: any) => {
  const { page = "", limit = "" } = payload;
  return encodeURI("reward?page=" + page + "&limit=" + limit);
};

export const loginUrl = () => {
  return encodeURI("/auth/login");
};

export const checkUserUrl = () => {
  return encodeURI("user/checkUserExists");
};

export const forgotPasswordUrl = () => {
  return encodeURI("user/forgotPassword");
};

export const changePasswordUrl = () => {
  return encodeURI("user/changePassword");
};

export const getGeneratedPolicyPDFUrl = (id: any) => {
  return encodeURI("policy/generatedPolicyPDFUser/" + id);
};

export const createVisitUrl = () => {
  return encodeURI("/visit");
};

export const loginViaTokenUrl = () => {
  return encodeURI("/auth/login-via-token");
};

export const getCategoryMaximumRangeUrl = (id: string) => {
  return encodeURI(`/product/maximum-range/${id}`);
};
