import { IRIS_DEROGATION_70_80, IRIS_DEROGATION_70_80_CRUCERO } from "@constants/constants";
import { checkMinimunAge } from "@helpers/utils";
import { useSelector } from "react-redux";

const usePriceAndAddiontalFeesCalculatorIris = ({
  totalTravellers,
  watch,
  variant,
  isInsuredPolicyHolder,
  priceWithoutAdditionalFees,
  discountPercentage,
}: {
  totalTravellers: number;
  isInsuredPolicyHolder: boolean;
  watch: Function;
  variant?: string;
  irisEldersExtraCheckMinimunAge: any,
  priceWithoutAdditionalFees?: any,
  discountPercentage?: any
}) => {

  const travellers = watch("travellers");
  const policyHolderBirthdate = watch("dob");
  const { irisExtraAll, prices }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.product
  );
  const extra = irisExtraAll?.filter((extra: any) => extra?.derogation === IRIS_DEROGATION_70_80 || extra?.derogation === IRIS_DEROGATION_70_80_CRUCERO)[0];
  const priceOriginal: any = variant ? (prices[variant] ?? (Number.parseFloat(priceWithoutAdditionalFees) / totalTravellers)) : (Number.parseFloat(priceWithoutAdditionalFees) / totalTravellers);
  const priceExtra: any = extra?.productPrices ?? 0;
  const infoExtra = extra?.title ?? '';
  function getNumberOfElderIris() {
    let output = 0;
    if (irisExtraAll.length > 0) {
      if (
        isInsuredPolicyHolder &&
        checkMinimunAge(policyHolderBirthdate)
      ) {
        output += 1;
      }


      travellers.forEach((traveller: any) => {
        if (
          checkMinimunAge(traveller.dob)
        ) {
          output += 1;
        }
      });
    }
    return output;
  }

  const elderCount = getNumberOfElderIris();
  const totalPriceBeforeDiscount = getTotalPrice();
  const totalPriceAfterDiscount = getFinalPriceAfterDiscount(totalPriceBeforeDiscount);
  const additionalFeeDueElders = totalPriceBeforeDiscount - priceWithoutAdditionalFees;
  const priceNetoExtra:number = Number(((extra?.irisPriceObj?.Precio_Neto / totalTravellers) * elderCount)?.toFixed(2));
  
  function getTotalPrice() {
    let totalTraveller = totalTravellers - elderCount;
    const totalPrice = (elderCount * priceExtra) + (totalTraveller * priceOriginal);
    return Number(totalPrice?.toFixed(2));
  }

 
  function getFinalPriceAfterDiscount(total: any) {
    let totalTraveller = totalTravellers - elderCount;
    const totalPrice = (elderCount * priceExtra) + (totalTraveller * priceOriginal);
    let output = totalPrice;
    if (discountPercentage) {
      output -= (discountPercentage * output) / 100;
    }
    return Number(output?.toFixed(2));
  }

  return {
    elderCount,
    totalPriceBeforeDiscount,
    totalPriceAfterDiscount,
    additionalFeeDueElders,
    infoExtra,
    isExtraDerogation: elderCount > 0,
    sobrePrimaExtraIris: extra ? {
      ...extra?.extra
    } : null,
    priceNetoExtra
  };
};

export { usePriceAndAddiontalFeesCalculatorIris };