import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  PurchasePolicies,
  Checkout,
  Dashboard,
  Success,
  Purchases,
  Advantages,
  AdvantageDetail,
  PurchasedDetail,
  UserProfile,
  UpdateProfile,
  UpdatePassword,
  Rewards,
} from "@modules";
import App from "App";
import { ProtectedRoute } from "@hocs";

interface RoutesProps {}

const MainRoutes: React.FC<RoutesProps> = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<PurchasePolicies />} />
          <Route path="checkout" element={<Checkout />} />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          >
            <Route path="success" element={<Success />} />
            <Route path="purchases" element={<Purchases />} />
            <Route path="purchases/:id" element={<PurchasedDetail />} />
            <Route path="benefits" element={<Advantages />} />
            <Route path="benefits/:id" element={<AdvantageDetail />} />
            <Route path="profile" element={<UserProfile />} />
            <Route path="update-profile" element={<UpdateProfile />} />
            <Route path="update-password" element={<UpdatePassword />} />
            <Route path="rewards" element={<Rewards />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;
