import { Tooltip } from "@mui/material";
import React from "react";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface DetailItemProps {
  icon: any;
  iconLabel?: any;
  subtitle?: any;
}

const DetailItem: React.FC<DetailItemProps> = (props) => {
  const { icon, iconLabel, subtitle } = props;

  // const [checked, setChecked] = useState(false);

  // const ref = useRef<any>(null);

  // const handleClick = (event: any) => {
  //   setChecked(!checked);
  // };

  return (
    <div className="flex flex-col w-min">
      <Tooltip
        placement="top"
        enterTouchDelay={0}
        title={
          <>
            <p className="text-sm lg:hidden">{iconLabel}</p>
            <p className="text-sm hidden lg:block">{subtitle}</p>
          </>
        }
      >
        <div
          className="flex justify-center items-start cursor-pointer w-max"
          // onClick={handleClick}
          // ref={ref}
        >
          <div className="p-1 rounded-full flex justify-center items-center">
            {icon}
          </div>
          <div className="hidden lg:block ml-1 text-sm font-medium w-36">
            <span>{iconLabel}</span>
            {/* <span>
            <KeyboardArrowDownIcon className="text-lg" />
          </span> */}
          </div>
        </div>
      </Tooltip>
      {/* <Collapse in={checked}>
        <div className=" text-xs px-3">{subtitle}</div>
      </Collapse> */}
    </div>
  );
};

export default DetailItem;
