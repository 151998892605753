import logoTravelfineWhite from "./icons/logoTravelfineWhite.png";
import requestBudget from "./icons/requestBudget.png";
import user from "./icons/user.png";
import settings from "./icons/settings.png";
import aeroplane from "./icons/aeroplane.png";
import cloud1 from "./icons/cloud1.png";
import cloud2 from "./icons/cloud2.png";
import barcode from "./icons/barcode.png";
import checkmark from "./icons/checkmark.png";
import aeroplaneYellow from "./icons/aeroplaneYellow.png";
import profileCover from "./icons/profileCover.png";
import rewardsCover from "./icons/rewardsCover.png";
import guaranteeIcon from "./icons/guaranteeIcon.png";
import appIcon from "./icons/appIcon.png";
import coverageIcon from "./icons/coverageIcon.png";
import emailIcon from "./icons/emailIcon.png";
import noAdvanceIcon from "./icons/noAdvanceIcon.png";
import phoneIcon from "./icons/phoneIcon.png";
import supportIcon from "./icons/supportIcon.png";
import whatsappIcon from "./icons/whatsappIcon.png";
import playStore from "./icons/playStore.png";
import appStore from "./icons/appStore.png";
import requestBudget2 from "./icons/requestBudget2.png";
import expand from "./icons/expand.png";
import lockIcon from "./icons/lockIcon.png";
import greenCheckIcon from "./icons/greenCheckIcon.png";

import { ReactComponent as email } from "./icons/emailIcon.svg";
import { ReactComponent as phone } from "./icons/phoneIcon.svg";
import { ReactComponent as whatsapp } from "./icons/whatsappIcon.svg";
import { ReactComponent as guarantee } from "./icons/guaranteeIcon.svg";
import { ReactComponent as noAdvance } from "./icons/noAdvanceIcon.svg";
import { ReactComponent as app } from "./icons/appIcon.svg";
import { ReactComponent as coverage } from "./icons/coverageIcon.svg";
import { ReactComponent as support } from "./icons/supportIcon.svg";

export const icons: any = {
  logoTravelfineWhite,
  requestBudget,
  user,
  settings,
  aeroplane,
  cloud1,
  cloud2,
  barcode,
  checkmark,
  aeroplaneYellow,
  profileCover,
  rewardsCover,
  guaranteeIcon,
  appIcon,
  coverageIcon,
  emailIcon,
  noAdvanceIcon,
  phoneIcon,
  supportIcon,
  whatsappIcon,
  playStore,
  appStore,
  requestBudget2,
  expand,
  lockIcon,
  email,
  phone,
  whatsapp,
  guarantee,
  noAdvance,
  app,
  coverage,
  support,
  greenCheckIcon,
};
