import Input from "@components/Input";
import { Paper } from "@mui/material";

interface LoginCollaboratorProps {
  control: any;
  errors: any;
}

const LoginCollaborator: React.FC<LoginCollaboratorProps> = (props) => {
  const { control, errors } = props;

  return (
    <div>
      <p className="text-2xl font-bold">Verificar Colaborador Credenciales</p>
      <Paper elevation={3} className="rounded-lg px-4 py-4 flex space-x-4 mt-4">
        <Input
          name="collaborator.email"
          type="email"
          label={"Email"}
          control={control}
          required
          fullWidth
          error={!!errors?.collaborator?.email}
          errorMessage={errors?.collaborator?.email?.message}
        />
        <Input
          name="collaborator.password"
          type="password"
          label={"Contraseña"}
          control={control}
          required
          fullWidth
          error={!!errors?.collaborator?.password}
          errorMessage={errors?.collaborator?.password?.message}
        />
      </Paper>
    </div>
  );
};

export default LoginCollaborator;
