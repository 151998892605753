import { responsiveFontSizes } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { enUS, esES } from "@mui/material/locale";
import { useSelector } from "react-redux";

const locale = process.env.REACT_APP_LANGUAGE !== "en" ? esES : enUS;

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

interface ThemeProps {
  brandColor?: string;
  children: any;
}

const Theme: React.FC<ThemeProps> = (props) => {
  const { children } = props;

  const { brandColor }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.code
  );

  let mainTheme = createTheme(
    {
      palette: {
        primary: {
          main: "#000000",
        },
        secondary: {
          main: brandColor ? brandColor : "#fdc413",
        },
        background: {
          default: "#fff",
        },
        neutral: {
          main: "#fff",
          contrastText: "#fff",
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 640,
          md: 768,
          lg: 1024,
          xl: 1280,
        },
      },
      typography: {
        fontFamily: `"Area", sans-serif, "Helvetica", "Arial"`,
      },
    },
    locale
  );

  mainTheme = responsiveFontSizes(mainTheme);
  return <ThemeProvider theme={mainTheme}>{children}</ThemeProvider>;
};

export default Theme;
