import { call, put, takeLatest } from "redux-saga/effects";

import { axiosInstance } from "@api/axios";
import { budgetUrl } from "@api/Endpoint";
import { BudgetActionTypes } from "../redux/actionTypes";
import { toast } from "react-toastify";
import { setBudgetId, setBudgetLoader } from "./BudgetActions";

function* createBudgetSaga(action: any) {
  try {
    const { form: requestPayload } = action.payload;

    const url = budgetUrl();
    yield put(setBudgetLoader(true));

    const response: ResponseGenerator = yield call(
      axiosInstance.post,
      url,
      requestPayload
    );

    yield put(setBudgetLoader(false));
    if (response.status === 200) {
      yield put(setBudgetId(response.data.data.budgetId));
      const successMessage = response.data.message;
      toast.success(successMessage);
    } else {
      const errorMessage = response.response.data.message;
      toast.error(errorMessage);
    }
  } catch (err) {
    yield put(setBudgetLoader(false));
    console.log("err: ", err);
  }
}

export function* budgetSagas() {
  yield takeLatest(BudgetActionTypes.CREATE_BUDGET, createBudgetSaga);
}
