import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useTheme } from "@mui/styles";
import ExternalLink from "./ExternalLink";

interface FooterProps {}

const socialLinks = [
  { icon: <FacebookIcon />, url: "https://www.facebook.com/travelfine/" },
  { icon: <InstagramIcon />, url: "https://www.instagram.com/travelfine_es/" },
  { icon: <LinkedInIcon />, url: "https://es.linkedin.com/company/travelfine" },
  { icon: <TwitterIcon />, url: "https://twitter.com/Travelfine_es" },
];

const navLinks = [
  {
    label: "POLÍTICA DE PRIVACIDAD",
    url: "https://travelfine.es/politica-de-privacidad/",
  },
  {
    label: "POLÍTICA DE COOKIES",
    url: "https://travelfine.es/politica-de-cookies/",
  },
  { label: "DEBER SECRETO", url: "https://travelfine.es/deber-secreto/" },
  {
    label: "PRESTACIÓN DE SERVICIOS",
    url: "https://travelfine.es/prestacion-de-servicios/",
  },
];

const Footer: React.FC<FooterProps> = (props) => {
  const theme: any = useTheme();
  return (
    <div className="py-6 flex flex-col justify-center bg-secondary items-center px-4 relative">
      <div className="flex space-x-3">
        {socialLinks.map((link) => (
          <ExternalLink
            key={link.url}
            className="flex justify-center items-center px-2 py-2 rounded-full"
            href={link.url}
            style={{
              backgroundColor: theme.palette.secondary.main,
            }}
          >
            {link.icon}
          </ExternalLink>
        ))}
      </div>
      <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-3 text-white mt-5">
        {navLinks.map((link) => (
          <ExternalLink
            key={link.url}
            className="hover:underline transition duration-300 ease-in-out"
            href={link.url}
          >
            {link.label}
          </ExternalLink>
        ))}
      </div>
    </div>
  );
};

export default Footer;
