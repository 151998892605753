import { BudgetActionTypes } from "../redux/actionTypes";

export const createBudget = (payload: any) => ({
  type: BudgetActionTypes.CREATE_BUDGET,
  payload,
});

export const setBudgetId = (payload: any) => ({
  type: BudgetActionTypes.SET_BUDGET_ID,
  payload,
});

export const setBudgetLoader = (payload: any) => ({
  type: BudgetActionTypes.SET_BUDGET_LOADER,
  payload,
});
