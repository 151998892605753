import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPolicies } from "@store/policy/PolicyActions";
import PurchasedCard from "./components/PurchasedCard";
import { Loader } from "@components";

interface PurchasesProps {}

const limit = 10;

const Purchases: React.FC<PurchasesProps> = (props) => {
  const dispatch = useDispatch();
  const [currentPageNo, setCurrentPageNo] = useState(1);

  const { loading }: any = useSelector<any>(({ Travelfine }) => Travelfine.app);
  const { policies, totalPolicies }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.policy
  );

  const observer = useRef<any>();

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        const hasMore = currentPageNo < Math.ceil(totalPolicies / limit);
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPageNo(currentPageNo + 1);
          const payload = {
            page: currentPageNo + 1,
            limit,
          };
          dispatch(getPolicies(payload));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, currentPageNo, dispatch, totalPolicies]
  );

  useEffect(() => {
    const payload = {
      page: 1,
      limit,
    };
    dispatch(getPolicies(payload));
  }, [dispatch]);

  return (
    <div className="flex flex-col">
      <Loader loading={loading} />
      <p className="font-bold px-4 lg:px-10vw mb-3 text-lg">Compras</p>
      {policies.map((policy: any) => {
        return (
          <PurchasedCard
            key={policy?._id}
            policy={policy}
            lastElementRef={lastElementRef}
          />
        );
      })}
    </div>
  );
};

export default Purchases;
