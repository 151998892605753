import { BudgetActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: BudgetState = {
  loading: false,
  budgetId: "",
};

const BudgetReducer = (
  state: BudgetState = INITIAL_STATE,
  action: Action
): BudgetState => {
  switch (action.type) {
    case BudgetActionTypes.SET_BUDGET_LOADER:
      return {
        ...state,
        loading: action.payload,
      };

    case BudgetActionTypes.SET_BUDGET_ID:
      return {
        ...state,
        budgetId: action.payload,
      };

    default:
      return state;
  }
};

export default BudgetReducer;
