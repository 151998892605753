import { Button } from "@components";
import { Dialog } from "@mui/material";
import PsychologyIcon from "@mui/icons-material/Psychology";

interface ConfirmationProps {
  onClose: any;
  open: boolean;
  onConfirm: any;
  confirmText: string;
}

const Confirmation: React.FC<ConfirmationProps> = (props) => {
  const { onClose, open, onConfirm, confirmText } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-Dialog">
      <div className="flex flex-col items-center px-4 py-3">
        <PsychologyIcon className="mb-3 text-5xl" />
        <p className="text-xl font-medium py-6">{confirmText}</p>
        <div className="flex space-x-2 mt-4">
          <Button onClick={handleClose}>Cancelar</Button>
          <Button color="secondary" onClick={onConfirm}>
            Confirmar
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default Confirmation;
