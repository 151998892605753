import { Loader, ProfileCover } from "@components";
import { getFormattedDate } from "@helpers/utils";
import { getUser } from "@store/user/UserActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

interface UserProfileProps {}

const UserProfile: React.FC<UserProfileProps> = (props) => {
  const dispatch = useDispatch();

  const { loading }: any = useSelector<any>(({ Travelfine }) => Travelfine.app);
  const { user }: any = useSelector<any>(({ Travelfine }) => Travelfine.user);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  return (
    <div className="flex flex-col lg:px-10vw">
      <Loader loading={loading} />
      <ProfileCover />

      <div className="flex flex-col px-4 lg:px-20 py-20">
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-3">
          <div className="flex flex-col">
            <p className="text-lg">Nombre</p>
            <p className="text-xl font-bold">{user?.name}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-lg">Apellidos</p>
            <p className="text-xl font-bold">{user?.surname}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-lg">Fecha Nacimiento</p>
            <p className="text-xl font-bold">{getFormattedDate(user?.dob)}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-lg">Nº documento</p>
            <p className="text-xl font-bold">{user?.documentNumber}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-lg">Tipo documento</p>
            <p className="text-xl font-bold">{user?.documentType}</p>
          </div>
        </div>
        <div className="flex flex-col mt-3">
          <p className="text-lg">Dirección</p>
          <p className="text-xl font-bold">{user?.address}</p>
        </div>
        <div className="grid lg:grid-cols-2 mt-3 gap-3">
          <div className="flex flex-col">
            <p className="text-lg">Codigo Postal</p>
            <p className="text-xl font-bold">{user?.postalCode}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-lg">Ciudad</p>
            <p className="text-xl font-bold">
              {user?.city?.label ? user?.city?.label : user?.city}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="text-lg">Email</p>
            <p className="text-xl font-bold">{user?.email}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-lg">Telefono</p>
            <p className="text-xl font-bold">{user?.phoneNo}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-lg">Pais</p>
            <p className="text-xl font-bold">{user?.country}</p>
          </div>
          <div className="flex flex-col">
            <p className="text-lg">Provincia</p>
            <p className="text-xl font-bold">
              {user?.province?.label ? user?.province?.label : user?.province}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
