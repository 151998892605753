import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import dompurify from "dompurify";

import { ImageAsset, Loader } from "@components";
import { getAdvantageById } from "@store/advantage/AdvantageActions";
import { urlify } from "@helpers/utils";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

interface AdvantageDetailProps {}

const AdvantageDetail: React.FC<AdvantageDetailProps> = (props) => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const [modifiedDescription, setModifiedDescription] = useState<string>();

  // const sanitizer = dompurify.sanitize;

  const { id } = routeParams;

  const { loading }: any = useSelector<any>(({ Travelfine }) => Travelfine.app);
  const { advantage }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.advantage
  );

  useEffect(() => {
    if (id) {
      dispatch(getAdvantageById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (advantage?.description) {
      const html = urlify(advantage?.description);
      setModifiedDescription(html);
    }
  }, [advantage?.description]);

  return (
    <div className="flex relative lg:px-10vw">
      <Loader loading={loading} />
      {advantage && advantage?.image && (
        <div
          className="hidden lg:flex relative"
          style={{
            width: "60vw",
            // backgroundImage: `url(${advantage?.image.toString()})`,
            // backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: "contain",
          }}
        >
          <div
            className="lg:relative mt-16 mb-20 lg:min-h-96 flex flex-col lg:px-10 lg:py-10 shadow-lg rounded-md bg-white z-10 overflow-auto"
            style={{ width: "40vw" }}
          >
            <p className="text-2xl font-bold mb-6">{advantage?.title}</p>

            {modifiedDescription && (
              <p className="text-sm whitespace-pre-wrap">
                <span
                  dangerouslySetInnerHTML={{
                    __html: modifiedDescription,
                  }}
                />
              </p>
            )}
            <Accordion className="mt-3">
              <AccordionSummary
                expandIcon={<ImageAsset className="w-6" src="expand" />}
                id="moreInfo"
              >
                <p className="text-xl font-bold">Más Info</p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="text-sm whitespace-pre-wrap">
                  {advantage?.moreInfo}
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion className="mt-3">
              <AccordionSummary
                expandIcon={<ImageAsset className="w-6" src="expand" />}
                id="howToGet"
              >
                <p className="text-xl font-bold">
                  ¿Cómo conseguir el descuento?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className="text-sm whitespace-pre-wrap">
                  {advantage?.howToGet}
                </p>
              </AccordionDetails>
            </Accordion>
          </div>
          <img
            src={advantage?.image}
            className="absolute left-60 h-full w-full object-cover pb-4"
            alt="advantage"
          />
        </div>
      )}
      <div className="flex flex-col lg:hidden mt-6 w-full">
        <img src={advantage?.image} className="w-full" alt="advantage" />
        <div className="px-2 py-2">
          <p className="text-xl font-bold">{advantage?.title}</p>
          {modifiedDescription && (
            <p className="text-sm">
              <span
                dangerouslySetInnerHTML={{
                  __html: modifiedDescription,
                }}
              />
            </p>
          )}
          <Accordion className="mt-3">
            <AccordionSummary
              expandIcon={<ImageAsset className="w-3" src="expand" />}
              id="moreInfo"
            >
              <p className="text-xl font-bold">Más Info</p>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-sm whitespace-pre-wrap">
                {advantage?.moreInfo}
              </p>
            </AccordionDetails>
          </Accordion>
          <Accordion className="mt-3">
            <AccordionSummary
              expandIcon={<ImageAsset className="w-3" src="expand" />}
              id="howToGet"
            >
              <p className="text-xl font-bold">¿Cómo conseguir el descuento?</p>
            </AccordionSummary>
            <AccordionDetails>
              <p className="text-sm whitespace-pre-wrap">
                {advantage?.howToGet}
              </p>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default AdvantageDetail;
