import { GLOBAL_FINE } from '@constants/constants';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import logoTravelfineWhite from "assets/icons/logoTravelfineWhite.png";

interface MetaPageProps {
    link?: any;
}

const MetaPage: React.FC<MetaPageProps> = ({ link }) => {
    const { code, brandImage }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);

    const isExistCode = code?.codeKey?.toUpperCase().includes(GLOBAL_FINE.code?.toUpperCase()) ?? false;
    const isExistUrlHome = window.location.host?.includes(GLOBAL_FINE.code) ?? false;
    const title = (isExistCode || isExistUrlHome) ? 'Globalfine' : 'Travelfine';
    const image = brandImage ? brandImage : logoTravelfineWhite;
    return (
        <div>
            <Helmet>
            <title>{title}</title>
                <meta name="description" content={`¡Hola! Mira esta imagen y más detalles en el siguiente enlace`} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={`¡Hola! Mira esta imagen y más detalles en el siguiente enlace`} />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={link} />         
            </Helmet>
        </div>
    );
};

export default MetaPage;
