import { Button, Input } from "@components";
import { useNavigate } from "react-router-dom";

interface UpdatePasswordFormProps {
  control: any;
  errors: any;
  onSubmit: any;
}

const UpdatePasswordForm: React.FC<UpdatePasswordFormProps> = (props) => {
  const { control, errors, onSubmit } = props;

  const navigate = useNavigate();

  const cancelHandler = () => {
    navigate(-1);
  };

  return (
    <form
      name="changePasswordForm"
      noValidate
      className="flex flex-col items-start justify-center w-full space-y-3"
      onSubmit={onSubmit}
    >
      <div className="w-full lg:w-2/4">
        <Input
          className="whitebgInput"
          name="oldPassword"
          type="password"
          label={"Contraseña anterior"}
          control={control}
          required
          error={!!errors.oldPassword}
          errorMessage={errors?.oldPassword?.message}
          labelOutside
        />
      </div>

      <div className="w-full lg:w-2/4">
        <Input
          className="whitebgInput"
          name="password"
          type="password"
          label={"Contraseña"}
          control={control}
          required
          error={!!errors.password}
          errorMessage={errors?.password?.message}
          labelOutside
        />
      </div>

      <div className="w-full lg:w-2/4">
        <Input
          className="whitebgInput"
          name="passwordConfirm"
          type="password"
          label={"Confirmar Contraseña"}
          control={control}
          required
          error={!!errors.passwordConfirm}
          errorMessage={errors?.passwordConfirm?.message}
          labelOutside
        />
      </div>
      <div className="flex self-end space-x-3">
        <Button
          color="secondary"
          className="w-full mt-4"
          variant="outlined"
          onClick={cancelHandler}
        >
          Cancelar
        </Button>
        <Button color="secondary" className="w-full mt-4" type="submit">
          Salvar
        </Button>
      </div>
    </form>
  );
};

export default UpdatePasswordForm;
