import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Loader } from "@components";

import { Dialog } from "@mui/material";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import ChangePasswordForm from "./components/ChangePasswordForm";

interface ForgotPasswordProps {
  onClose: any;
  open: boolean;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {
  const { onClose, open } = props;

  const { loading }: any = useSelector<any>(({ Travelfine }) => Travelfine.app);

  const [emailSent, setEmailSent] = useState("");

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Loader loading={loading}></Loader>
      {emailSent ? (
        <ChangePasswordForm emailSent={emailSent} handleClose={handleClose} />
      ) : (
        <ForgotPasswordForm setEmailSent={setEmailSent} />
      )}
    </Dialog>
  );
};

export default ForgotPassword;
