import _ from "lodash";
import { useEffect, useRef } from "react";

function deepCompareEquals(a: any, b: any) {
  return _.isEqual(a, b);
}

function useDeepCompareMemoize(value: any) {
  const ref = useRef();
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffect(callback: any, dependencies: any[]) {
  // Disabled useEffect dependencies missing warning just for callback function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
export default useDeepCompareEffect;
