import { call, put, takeLatest } from "redux-saga/effects";

import { axiosInstance } from "@api/axios";
import {
  getMunicipalsUrl,
  updatePasswordUrl,
  userUrl,
  getUserPointsUrl,
  loginUrl,
  checkUserUrl,
  forgotPasswordUrl,
  changePasswordUrl,
  loginViaTokenUrl,
} from "@api/Endpoint";
import { UserActionTypes } from "../redux/actionTypes";
import {
  getMunicipalsSuccess,
  getUser,
  getUserPointsSuccess,
  getUserSuccess,
  setUserLoader,
} from "./UserActions";
import { toast } from "react-toastify";
import { setUpdatePasswordModal } from "@store/policy/PolicyActions";
import { PAYMENT_STATUS } from "@helpers/data";

function* getMunicipalsSaga(action: any) {
  try {
    yield put(setUserLoader(true));
    const postalCode = action.payload;
    const url = getMunicipalsUrl(postalCode);

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    if (response?.status === 200) {
      const payload = response.data.data;
      yield put(getMunicipalsSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
      toast.error("Código postal inválido");
    }
    yield put(setUserLoader(false));
  } catch (err: any) {
    const errorMessage = err?.response?.data?.message;
    console.log("err: ", errorMessage);
    toast.error("Código postal inválido");
    yield put(setUserLoader(false));
  }
}

function* createPasswordSaga(action: any) {
  try {
    const { navigate, paymentStatus } = action;
    const url = updatePasswordUrl();

    const requestPayload = {
      newPassword: action.payload.newPassword,
    };

    const {
      travelInfo,
      paymentMethod,
      productVariant,
      codeKey,
      productProvider,
      productId,
    } = action.payload;

    const response: ResponseGenerator = yield call(
      axiosInstance.put,
      url,
      requestPayload
    );

    if (response?.status === 200) {
      const successMessage = response.data.message;
      toast.success(successMessage);
      if (paymentStatus !== PAYMENT_STATUS.ERROR_PAYMENT) {
        navigate("/dashboard/success", {
          state: {
            travelInfo: {
              ...travelInfo,
              paymentMethod,
              variant: productVariant,
              code: codeKey,
              provider: productProvider,
              productId,
            },
          },
        });
      } else {
        yield put(setUpdatePasswordModal(false));
      }
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err: any) {
    const errorMessage = err?.response?.data?.message;
    console.log("err: ", errorMessage);
  }
}

function* getUserSaga(action: any) {
  try {
    const url = userUrl();

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    if (response?.status === 200) {
      const payload = response.data.data;
      yield put(getUserSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err: any) {
    const errorMessage = err?.response?.data?.message;
    console.log("err: ", errorMessage);
  }
}

function* checkUserExistsSaga(action: any) {
  try {
    const { email, openModal } = action.payload;
    const url = checkUserUrl();

    const payload = {
      email,
    };

    const response: ResponseGenerator = yield call(
      axiosInstance.post,
      url,
      payload
    );

    if (response?.status === 200) {
      const payload = response.data.data;

      if (payload.userExists) {
        openModal();
      }
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err: any) {
    const errorMessage = err?.response?.data?.message;
    console.log("err: ", errorMessage);
  }
}

function* updateUserSaga(action: any) {
  try {
    const url = userUrl();

    const requestPayload = action.payload;

    const response: ResponseGenerator = yield call(
      axiosInstance.put,
      url,
      requestPayload
    );

    if (response?.status === 200) {
      const successMessage = response.data.message;
      toast.success(successMessage);
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err: any) {
    const errorMessage = err?.response?.data?.message;
    console.log("err: ", errorMessage);
  }
}

function* updatePasswordSaga(action: any) {
  try {
    const url = updatePasswordUrl();

    const requestPayload = action.payload;

    const response: ResponseGenerator = yield call(
      axiosInstance.put,
      url,
      requestPayload
    );

    if (response?.status === 200) {
      const successMessage = response.data.message;
      toast.success(successMessage);
    }
  } catch (err: any) {
    const errorMessage = err?.response?.data?.message;
    console.log("err: ", errorMessage);
    toast.error(errorMessage);
  }
}

function* getUserPointsSaga(action: any) {
  try {
    const url = getUserPointsUrl();

    yield put(setUserLoader(true));

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    yield put(setUserLoader(false));
    if (response?.status === 200) {
      const payload = response.data.data;
      yield put(getUserPointsSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err: any) {
    const errorMessage = err?.response?.data?.message;
    console.log("err: ", errorMessage);
    yield put(setUserLoader(false));
  }
}

function* loginSaga(action: any) {
  try {
    const url = loginUrl();

    const requestPayload = {
      email: action.payload.email,
      password: action.payload.password,
    };

    const navigate = action.payload.navigate;

    const response: ResponseGenerator = yield call(
      axiosInstance.post,
      url,
      requestPayload
    );

    if (response?.status === 200) {
      const successMessage = response.data.message;
      const data = response.data.data;
      toast.success(successMessage);
      localStorage.setItem("tr_access_token", data.accessToken);
      localStorage.setItem("userId", data.userId);
      yield put(getUser());
      if (navigate) {
        navigate("/dashboard/purchases");
      }
    } else {
      const errorMessage = response.response.data.message;
      toast.error(errorMessage);
      console.log("error: ", errorMessage);
    }
  } catch (err: any) {
    const errorMessage = err?.response?.data?.message;
    toast.error(errorMessage);
    console.log("err: ", errorMessage);
  }
}

function* forgotPasswordSaga(action: any) {
  try {
    const url = forgotPasswordUrl();

    const requestPayload = {
      email: action.payload.email,
    };

    const setEmailSent = action.setEmailSent;

    const response: ResponseGenerator = yield call(
      axiosInstance.post,
      url,
      requestPayload
    );

    if (response?.status === 200) {
      const successMessage = response.data.message;
      const data = response.data.data;
      toast.success(successMessage);
      if (data && setEmailSent) {
        setEmailSent(action.payload.email);
      }
    } else {
      const errorMessage = response.response.data.message;
      toast.error(errorMessage);
      console.log("error: ", errorMessage);
    }
  } catch (err: any) {
    const errorMessage = err?.response?.data?.message;
    toast.error(errorMessage);
    console.log("err: ", errorMessage);
  }
}

function* changePasswordSaga(action: any) {
  try {
    const url = changePasswordUrl();

    const requestPayload = {
      ...action.payload,
    };

    const response: ResponseGenerator = yield call(
      axiosInstance.put,
      url,
      requestPayload
    );

    if (response?.status === 200) {
      const successMessage = response.data.message;
      toast.success(successMessage);
    } else {
      const errorMessage = response.response.data.message;
      toast.error(errorMessage);
      console.log("error: ", errorMessage);
    }
  } catch (err: any) {
    const errorMessage = err?.response?.data?.message;
    toast.error(errorMessage);
    console.log("err: ", errorMessage);
  }
}

function* autoLoginSaga(action: any) {
  try {
    yield put(setUserLoader(true));

    const url = loginViaTokenUrl();

    const requestPayload = {
      token: action.payload.loginToken,
    };

    const navigate = action.payload.navigate;

    const response: ResponseGenerator = yield call(
      axiosInstance.post,
      url,
      requestPayload
    );

    if (response?.status === 200) {
      const data = response.data.data;
      localStorage.setItem("tr_access_token", data.accessToken);
      localStorage.setItem("userId", data.userId);
      yield put(getUser());
      if (navigate) {
        navigate("/dashboard/purchases");
      }
    } else {
      const errorMessage = response.response.data.message;
      toast.error(errorMessage);
      console.log("error: ", errorMessage);
    }
    yield put(setUserLoader(false));
  } catch (err: any) {
    yield put(setUserLoader(false));
    const errorMessage = err?.response?.data?.message;
    toast.error(errorMessage);
    console.log("err: ", errorMessage);
  }
}

export function* userSagas() {
  yield takeLatest(UserActionTypes.GET_MUNICIPALS, getMunicipalsSaga);
  yield takeLatest(UserActionTypes.CREATE_PASSWORD, createPasswordSaga);
  yield takeLatest(UserActionTypes.GET_USER, getUserSaga);
  yield takeLatest(UserActionTypes.UPDATE_USER, updateUserSaga);
  yield takeLatest(UserActionTypes.UPDATE_PASSWORD, updatePasswordSaga);
  yield takeLatest(UserActionTypes.GET_USER_POINTS, getUserPointsSaga);
  yield takeLatest(UserActionTypes.LOGIN, loginSaga);
  yield takeLatest(UserActionTypes.CHECK_USER_EXISTS, checkUserExistsSaga);
  yield takeLatest(UserActionTypes.FORGOT_PASSWORD, forgotPasswordSaga);
  yield takeLatest(UserActionTypes.CHANGE_PASSWORD, changePasswordSaga);
  yield takeLatest(UserActionTypes.AUTO_LOGIN, autoLoginSaga);
}
