import { Button, ExternalLink } from "@components";
import { Paper } from "@mui/material";

interface ViewProductPdfsCardProps {
  coveragePdfFile: any;
  emailPdfFiles: any;
}

const ViewProductPdfsCard: React.FC<ViewProductPdfsCardProps> = (props) => {
  const { coveragePdfFile, emailPdfFiles } = props;

  return (
    <>
      {((emailPdfFiles && emailPdfFiles[0]) || coveragePdfFile) && (
        <Paper
          elevation={3}
          className={`flex flex-col items-center rounded-lg px-4 py-4 `}
        >
          {emailPdfFiles && emailPdfFiles[0] && (
            <ExternalLink href={emailPdfFiles[0]}>
              <Button
                className="whitespace-nowrap"
                color="secondary"
                variant="outlined"
              >
                Condiciones Generales
              </Button>
            </ExternalLink>
          )}
          {coveragePdfFile && (
            <ExternalLink href={coveragePdfFile}>
              <Button color="secondary" variant="outlined" className="mt-4">
                Resumen Coberturas
              </Button>
            </ExternalLink>
          )}
        </Paper>
      )}
    </>
  );
};

export default ViewProductPdfsCard;
