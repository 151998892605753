import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { numberWithCommas } from "@helpers/utils";
import { ImageAsset } from "@components";

interface MobileGuranteeItemProps {
  item: any;
  type: string;
}

const MobileGuranteeItem: React.FC<MobileGuranteeItemProps> = (props) => {
  const { item, type } = props;

  const limitObj = item.limits?.find((limit: any) => limit.name === type);
  const limit = limitObj?.value;
  const isPrice = !isNaN(limit);

  return (
    <div className={`text-sm font-normal py-2 text-center px-2`}>
      <div className="flex lg:block justify-between items-center">
        <div className="flex items-center space-x-1">
          <ImageAsset src="greenCheckIcon" className="w-5" />
          <p className={`text-left `}>{item.title}</p>
        </div>

        {limit === "Si" ? (
          <CheckCircleIcon className="fill-current text-green-300" />
        ) : limit === "No" ? (
          <CancelIcon className="fill-current text-gray-400" />
        ) : (
          <p>
            {isPrice ? numberWithCommas(limit) : limit}
            {isPrice && "€"}
          </p>
        )}
      </div>
    </div>
  );
};

export default MobileGuranteeItem;
