import { ImageAsset } from "@components";
import { getFormattedDate } from "@helpers/utils";
import ReactGA from "react-ga4";

import cardImage from "assets/icons/ticketCard.png";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ThankyouCard from "./components/ThankyouCard";
import { format } from "date-fns";

interface SuccessProps {}

const Success: React.FC<SuccessProps> = (props) => {
  let { state = {} }: { state: any } = useLocation();

  if (!state) {
    state = {};
  }

  const { travelInfo = {} }: any = state;

  useEffect(() => {
    const eventData = {
      origin: travelInfo?.origin?.value,
      destiny: travelInfo?.destination?.value,
      departureDate: format(new Date(travelInfo?.departureDate), "yyyy-MM-dd"),
      returnDate: format(new Date(travelInfo?.returnDate), "yyyy-MM-dd"),
      totalTravellers: travelInfo?.totalTravellers,
      modalidad: travelInfo?.category?.label
        ? travelInfo?.category?.label
        : travelInfo?.category,
      code: travelInfo?.code,
      variant: travelInfo?.variant,
      paymentMethod: travelInfo?.paymentMethod,
      productId: travelInfo?.productId,
      provider: travelInfo?.provider,
    };

    ReactGA.ga("send", {
      hitType: "event",
      eventCategory: "purchase",
      eventAction: "purchase",
      ...eventData,
    });
  }, [
    travelInfo?.departureDate,
    travelInfo?.origin?.value,
    travelInfo?.destination?.value,
    travelInfo?.returnDate,
    travelInfo?.totalTravellers,
    travelInfo?.category,
    travelInfo?.code,
    travelInfo?.variant,
    travelInfo?.paymentMethod,
    travelInfo?.productId,
    travelInfo?.provider,
  ]);

  return (
    <div className="px-10vw">
      <div className="flex flex-col lg:flex-row pt-20 pb-44">
        <div className="lg:hidden flex justify-center mb-4">
          <ThankyouCard />
        </div>
        <div className="flex ml-3 lg:ml-0 lg:w-1/2 justify-center relative">
          <div
            className="px-20 py-6 space-y-2 rounded-3xl relative"
            style={{
              background: `url(${cardImage})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            }}
          >
            <p className="">Origen</p>
            <p className="font-medium">{travelInfo?.origin?.value}</p>
            <p className="">Destino</p>
            <p className="font-medium">{travelInfo?.destination?.value}</p>
            <p className="">Fecha De Salida</p>
            <p className="font-medium">
              {getFormattedDate(travelInfo?.departureDate)}
            </p>
            <p className="">Fecha De Retorno</p>
            <p className="font-medium">
              {getFormattedDate(travelInfo?.returnDate)}
            </p>
            <p className="">Viajeros</p>
            <p className="font-medium">{travelInfo?.totalTravellers}</p>

            <ImageAsset className="w-40" src="barcode" />
            <div className="hidden lg:block absolute -bottom-20 -right-40">
              <ThankyouCard />
            </div>
          </div>
        </div>

        <div className="w-1/2 hidden lg:flex relative justify-center">
          <ImageAsset className="w-96" src="aeroplane" />
          <ImageAsset
            className="absolute top-0 w-52"
            src="cloud1"
            style={{ left: "50%" }}
          />
        </div>
      </div>
      <ImageAsset
        className="hidden lg:block absolute bottom-0 right-0 w-80 contain"
        src="cloud2"
      />
    </div>
  );
};

export default Success;
