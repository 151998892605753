import { all } from "redux-saga/effects";

import { categorySagas } from "@store/category/CategorySaga";
import { productSagas } from "@store/product/ProductSaga";
import { policySagas } from "@store/policy/PolicySaga";
import { budgetSagas } from "@store/budget/BudgetSaga";
import { codeSagas } from "@store/code/CodeSaga";
import { userSagas } from "@store/user/UserSaga";
import { advantageSagas } from "@store/advantage/AdvantageSaga";
import { rewardSagas } from "@store/reward/RewardSaga";

export default function* rootSaga() {
  yield all([
    categorySagas(),
    productSagas(),
    policySagas(),
    budgetSagas(),
    codeSagas(),
    userSagas(),
    advantageSagas(),
    rewardSagas(),
  ]);
}
