import { UserActionTypes } from "../redux/actionTypes";

export const getMunicipals = (payload: any) => ({
  type: UserActionTypes.GET_MUNICIPALS,
  payload,
});

export const getMunicipalsSuccess = (payload: any) => ({
  type: UserActionTypes.GET_MUNICIPALS_SUCCESS,
  payload,
});

export const createPassword = (
  payload: any,
  navigate: any,
  paymentStatus: any
) => ({
  type: UserActionTypes.CREATE_PASSWORD,
  payload,
  navigate,
  paymentStatus,
});

export const updatePassword = (payload: any) => ({
  type: UserActionTypes.UPDATE_PASSWORD,
  payload,
});

export const getUser = () => ({
  type: UserActionTypes.GET_USER,
});

export const getUserSuccess = (payload: any) => ({
  type: UserActionTypes.GET_USER_SUCCESS,
  payload,
});

export const checkUserExists = (payload: any) => ({
  type: UserActionTypes.CHECK_USER_EXISTS,
  payload,
});

export const updateUser = (payload: any) => ({
  type: UserActionTypes.UPDATE_USER,
  payload,
});

export const getUserPoints = () => ({
  type: UserActionTypes.GET_USER_POINTS,
});

export const getUserPointsSuccess = (payload: any) => ({
  type: UserActionTypes.GET_USER_POINTS_SUCCESS,
  payload,
});

export const login = (payload: any) => ({
  type: UserActionTypes.LOGIN,
  payload,
});

export const autoLogin = (payload: any) => ({
  type: UserActionTypes.AUTO_LOGIN,
  payload,
});

export const forgotPassword = (payload: any, setEmailSent: any) => ({
  type: UserActionTypes.FORGOT_PASSWORD,
  payload,
  setEmailSent,
});

export const changePassword = (payload: any) => ({
  type: UserActionTypes.CHANGE_PASSWORD,
  payload,
});

export const setUserLoader = (payload: any) => ({
  type: UserActionTypes.SET_USER_LOADER,
  payload,
});
