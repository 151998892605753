import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { Dialog } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import parse from "date-fns/parse";
import {
  isValid as isValidDate,
  format,
  differenceInYears,
  add,
} from "date-fns";

import FilterForm from "./FilterForm";
import { getFormattedDate } from "@helpers/utils";
import {
  getFilteredProduct,
  setProductFilter,
} from "@store/product/ProductActions";
import {
  ANNUAL_CATEGOIRES,
  COLLABORATOR_PANEL_URL_PARAMETER_NAME,
  getCountriesListSpanish,
  originList,
} from "@helpers/data";
import { useTheme } from "@mui/styles";
import ReactGA from "react-ga4";

interface FilterProps {
  filter: any;
  defaultValues: any;
  applyCollaboratorPanelStyles?: boolean;
}

const Filter: React.FC<FilterProps> = (props) => {
  const { filter, defaultValues, applyCollaboratorPanelStyles } = props;

  const dispatch = useDispatch();

  const { categories }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.category
  );

  const navigate = useNavigate();

  const theme: any = useTheme();

  const [searchParams] = useSearchParams();

  const startDateParams = searchParams.get("startDate");
  const endDateParams = searchParams.get("endDate");
  const originParams = searchParams.get("origin");
  const destinyParams = searchParams.get("destiny");
  const numberOfTravellersParams = searchParams.get("numberOfTravellers");
  const categoryParams = searchParams.get("category");
  const codeKey = searchParams.get("code");
  const userInDestinationParams = searchParams.get("userInDestination");
  const userTypeParams = searchParams.get("userType");
  const budgetId = searchParams.get("budgetId");

  const prevFilter: any = useRef({});
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [categoriesList, setCategoriesList] = useState<any>([]);

  const schema = yup.object().shape({
    // returnDate: yup
    //   .date()
    //   .min(
    //     yup.ref("departureDate"),
    //     "La fecha de regreso no puede ser menor que la fecha de salida."
    //   ),
    // .when(
    //   "departureDate",
    //   (departureDate, schema) =>
    //     departureDate &&
    //     schema.min(
    //       departureDate,
    //       "La fecha de regreso no puede ser menor que la fecha de salida."
    //     )
    // ),
  });
  const methods = useForm({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { control, handleSubmit, formState, watch, setValue, getValues } =
    methods;

  const { isValid, errors } = formState;

  const { origin, destination, departureDate, returnDate, totalTravellers } =
    filter;

  const { code }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);

  useEffect(() => {
    if (code?.collaborator?.includedCategories) {
      const includedCategories = categories.filter((category: any) =>
        code?.collaborator?.includedCategories?.includes(category.value)
      );
      setCategoriesList([...includedCategories]);
    } else {
      const filteredCategories = categories.filter(
        (category: any) => !category.isHidden
      );
      setCategoriesList([...filteredCategories]);
    }
  }, [categories, code]);

  const onSubmit = (data: any) => {
    const form: any = {
      modality: data.modality,
      origin: data.origin,
      destination: data.destination,
      departureDate: data.departureDate,
      returnDate: data.returnDate,
      totalTravellers: data.totalTravellers,
      userInDestination: data.userInDestination,
    };
    const filterObj: any = {
      category: data?.modality?.key,
      origin: data?.origin?.code,
      destiny: data?.destination?.code,
      startDate: format(data.departureDate, "dd/MM/yyyy"),
      endDate: format(data.returnDate, "dd/MM/yyyy"),
      numberOfTravellers: data.totalTravellers,
      userInDestination: data.userInDestination,
    };
    if (codeKey) {
      filterObj.code = codeKey;
      form.code = codeKey;
      form.pricing = true;
    }
    let queryString = Object.keys(filterObj)
      .map((key) => key + "=" + filterObj[key])
      .join("&");

    if (queryString.length > 0) {
      if (userTypeParams) {
        queryString = queryString + "&userType=" + userTypeParams;
      }
      if (applyCollaboratorPanelStyles) {
        queryString += `&${COLLABORATOR_PANEL_URL_PARAMETER_NAME}=true`;
      }

      navigate("/?" + queryString);
    }
    dispatch(setProductFilter(form));
    handleClose();
  };

  useEffect(() => {
    dispatch(
      setProductFilter({
        origin: {
          label: "España y Andorra",
          value: "España y Andorra",
          code: "ES",
          region: "europa",
        },
        destination: {
          label: "Todo el mundo",
          value: "Todo el mundo",
          code: "WR",
        },
        modality: "reset",
        departureDate: new Date(),
        returnDate: new Date(),
        totalTravellers: 1,
        pricing: "reset",
        userInDestination: false,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const form: any = {};
      if (categoriesList.length > 0) {
        if (categoryParams) {
          const formattedCategoryParams = decodeURI(categoryParams);
          const category = categoriesList.find(
            (category: any) =>
              formattedCategoryParams?.toLowerCase() ===
              category?.key?.toLowerCase()
          );
          if (category) {
            form[`modality`] = category;
            setValue("modality", form.modality);
          }
        }
        if (originParams) {
          const formattedOriginParams = decodeURI(originParams);
          const origin = originList.find(
            (origin: any) =>
              formattedOriginParams?.toLowerCase() === origin.code.toLowerCase()
          );
          if (origin) {
            form[`origin`] = origin;
            setValue("origin", form.origin);
          }
        }
        if (destinyParams) {
          let categoryKey = undefined;
          if (categoryParams) {
            categoryKey = decodeURI(categoryParams);
          }
          const formattedDestinyParams = decodeURI(destinyParams);
          const destiny = getCountriesListSpanish(categoryKey).find(
            (destiny: any) =>
              formattedDestinyParams?.toLowerCase() ===
              destiny.code?.toLowerCase()
          );

          if (destiny) {
            form[`destination`] = destiny;
            setValue("destination", form.destination);
          }
        }
        if (
          endDateParams &&
          isValidDate(parse(endDateParams, "dd/MM/yyyy", new Date()))
        ) {
          form[`returnDate`] = parse(endDateParams, "dd/MM/yyyy", new Date());
          setValue("returnDate", form.returnDate);
        }
        if (
          startDateParams &&
          isValidDate(parse(startDateParams, "dd/MM/yyyy", new Date())) &&
          parse(startDateParams, "dd/MM/yyyy", new Date()) >= new Date()
        ) {
          form[`departureDate`] = parse(
            startDateParams,
            "dd/MM/yyyy",
            new Date()
          );
          setValue("departureDate", form.departureDate);
        }
        if (
          numberOfTravellersParams &&
          !isNaN(Number(numberOfTravellersParams)) &&
          Number(numberOfTravellersParams) > 0 &&
          Number(numberOfTravellersParams) <= 200
        ) {
          form[`totalTravellers`] = Number(numberOfTravellersParams);
          setValue("totalTravellers", form.totalTravellers);
        }

        if (userInDestinationParams !== undefined) {
          form[`userInDestination`] = userInDestinationParams === "true";

          setValue("userInDestination", form.userInDestination);
        }

        const startDate = form.departureDate
          ? form.departureDate
          : getValues("departureDate");
        const endDate = form.returnDate
          ? form.returnDate
          : getValues("returnDate");

        if (startDate > endDate) {
          setValue("returnDate", startDate);
          form[`returnDate`] = startDate;
        }

        const modalityValue = getValues("modality");

        const isAlreadyValueAssigned = categoriesList.some(
          (category: any) =>
            category?.value === modalityValue?.value ||
            category?.value === form[`modality`]?.value
        );

        const isAlreadyFilterAssigned = categoriesList.some(
          (category: any) =>
            category.value === filter?.modality?.value ||
            category.value === form[`modality`]?.value
        );

        if (!isAlreadyValueAssigned) {
          setValue("modality", categoriesList[0]);
        }
        if (!isAlreadyFilterAssigned) {
          form[`modality`] = categoriesList[0];
        }

        const defaultDepartureDate = form.departureDate || new Date();
        if (ANNUAL_CATEGOIRES.includes(form[`modality`]?.key)) {
          form[`returnDate`] = add(defaultDepartureDate, {
            days: 364,
          });
        }

        if (
          differenceInYears(
            form.returnDate || new Date(),
            defaultDepartureDate
          ) >= 1
        ) {
          form[`returnDate`] = add(defaultDepartureDate, {
            days: 364,
          });
        }

        if (form[`modality`]?.key === "AVIRISAnualMultiviaje") {
          form[`returnDate`] = add(defaultDepartureDate, {
            days: 365,
          });
        }

        if (codeKey) {
          form.code = codeKey;
        }
        await dispatch(setProductFilter(form));
      }
    })();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    startDateParams,
    endDateParams,
    originParams,
    destinyParams,
    numberOfTravellersParams,
    categoryParams,
    userInDestinationParams,
    dispatch,
    setValue,
    getValues,
    categoriesList,
    codeKey,
    // filter,
  ]);

  useEffect(() => {
    if (
      filter.modality &&
      !filter?.dontCallAPI &&
      JSON.stringify(prevFilter?.current) !== JSON.stringify(filter)
    ) {
      const payload: any = {
        form: {
          ...filter,
          modality: filter.modality,
          departureDate: format(new Date(filter?.departureDate), "yyyy-MM-dd"),
          returnDate: format(new Date(filter?.returnDate), "yyyy-MM-dd"),
          budgetId,
        },
        setValue,
      };
      const eventData = {
        origin: filter?.origin?.label,
        destiny: filter?.destination?.label,
        departureDate: format(new Date(filter?.departureDate), "yyyy-MM-dd"),
        returnDate: format(new Date(filter?.returnDate), "yyyy-MM-dd"),
        totalTravellers: filter?.totalTravellers,
        modalidad: filter?.modality?.label
          ? filter?.modality?.label
          : filter?.modality,
        code: filter?.code,
        userInDestination: filter.userInDestination
          ? filter.userInDestination
          : false,
      };
      ReactGA.ga("send", {
        hitType: "event",
        eventCategory: "set_checkout_option",
        eventAction: "set_checkout_option",
        ...eventData,
      });
      prevFilter.current = filter;
      dispatch(getFilteredProduct(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filter, setValue]);

  return (
    <>
      <div
        className="px-4 py-4 shadow-md lg:pt-8 lg:pb-4 rounded-b-md"
        style={{ backgroundColor: theme.palette.secondary.main }}
      >
        <div className="hidden lg:block">
          <FilterForm
            applyCollaboratorPanelStyles={applyCollaboratorPanelStyles ?? false}
            control={control}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            isValid={isValid}
            errors={errors}
            watch={watch}
            setValue={setValue}
            categoriesList={categoriesList}
            getValues={getValues}
          />
        </div>
        <div className="flex flex-col lg:hidden">
          <p className="text-sm">{`${origin.value} - ${destination.value}`}</p>
          <div className="flex items-center justify-between mt-1">
            <p className="text-sm">{`${getFormattedDate(
              departureDate
            )} - ${getFormattedDate(returnDate)}`}</p>
            <p className="text-sm">
              {totalTravellers}
              <span className="ml-1">
                <PersonOutlineIcon />
              </span>
            </p>
            <p
              className="text-sm underline cursor-pointer"
              onClick={handleOpen}
            >
              Editar
            </p>
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="filter-Dialog">
        <div
          className="flex flex-col px-2 py-4"
          style={{ backgroundColor: theme.palette.secondary.main }}
        >
          <CancelIcon className="self-end text-4xl" onClick={handleClose} />
          <div className="px-4 pt-8 pb-12">
            <FilterForm
              applyCollaboratorPanelStyles={false}
              control={control}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              isValid={isValid}
              errors={errors}
              watch={watch}
              setValue={setValue}
              categoriesList={categoriesList}
              getValues={getValues}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Filter;
