import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Button, ExternalLink } from "@components";
import { RequestBudget } from "@components/modals";
import { ShareBudget } from "@components/SharedBugget";
import { getPrice, showPrice } from "@helpers/utils";
import { Paper, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Extra from "./Extra";
import Guarantee from "./Guarantee";
import { useTheme } from "@emotion/react";
import { GLOBAL_FINE } from "@constants/constants";

interface DesktopPlanTableProps {
  product: any;
  buyClickHandler: any;
  totalTravellers: number;
  filter: any;
  variantParams?: any
}

const DesktopPlanTable: React.FC<DesktopPlanTableProps> = (props) => {
  const { product, buyClickHandler, totalTravellers, filter, variantParams } = props;

  const { prices }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.product
  );
  const theme: any = useTheme();
  const { numberOfTravellersPerPrice } = product;
  const { code }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);

  const isExistCode = code?.codeKey?.toUpperCase().includes(GLOBAL_FINE.code?.toUpperCase()) ?? false;
  const colorSelect = isExistCode ? theme.palette.secondary.main: '#fdc413';


  const [open, setOpen] = useState(false);
  const [filterData, setFilterData] = useState<any>({});

  const handleOpen = (variant: string) => {
    setOpen(true);
    const productId = product?._id;
    const productVariant = variant;

    const price = getPrice({
      price: prices[variant],
      totalTravellers,
      numberOfTravellersPerPrice,
    });

    setFilterData({
      ...filter,
      productId,
      productVariant,
      price,
    });
  };
  const handleClose = () => {
    setOpen(false);
    setFilterData({});
  };

  const useStyles = makeStyles((theme: any) => ({
    hover: {
      "&:hover": {
        color: theme.palette.secondary.main,
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Paper elevation={3} className="rounded-md">
        <table className="relative w-full border border-collapse border-gray-100 rounded-md">
          <thead className="sticky z-10 bg-white top-32">
            <tr>
              <th className="w-3/12 border rounded-md"></th>
              {product?.variants?.length > 0 &&
                product?.variants.map((variant: any, index: number) => (
                  <th
                    className={`items-center px-3 py-6 bg-opacity-50  border-2 ${variantParams === variant ? 'variant-select bg-opacity-50' : ''}`}
                    key={variant}
                    style={{
                      borderColor: variantParams === variant ? colorSelect : '',
                      backgroundColor: variantParams === variant ? colorSelect : '',
                    }}
                  >
                    <h1 className="text-xl font-medium" style={{
                      color: variantParams === variant ? theme.palette.secondary.contrastText : ''
                    }} >{product?.title}</h1>
                    <h1 className="text-xl font-medium " style={{
                      color: variantParams === variant ? theme.palette.secondary.contrastText : ''
                    }}>
                      {variant}
                    </h1>
                    <h2 className="text-2xl font-black">
                      {prices &&
                        showPrice({
                          price: prices[variant],
                          discountPercentage: code?.percentage,
                          totalTravellers,
                          numberOfTravellersPerPrice,
                        })}
                    </h2>
                    {!!code?.percentage && (
                      <h3 className="text-xl font-black line-through">
                        {prices &&
                          showPrice({
                            price: prices[variant],
                            totalTravellers,
                            numberOfTravellersPerPrice,
                          })}
                      </h3>
                    )}
                    {totalTravellers > 1 && (
                      <h3 className="text-sm font-bold">
                        {prices &&
                          showPrice({
                            price: prices[variant],
                            discountPercentage: code?.percentage,
                            numberOfTravellersPerPrice,
                          })}
                        <span className="ml-1 text-xs font-normal text-gray-500">
                          /
                          {numberOfTravellersPerPrice
                            ? `${numberOfTravellersPerPrice} persona`
                            : "persona"}
                        </span>
                      </h3>
                    )}
                    <Button
                      color="primary"
                      className="mt-2 font-bold"
                      onClick={() => buyClickHandler(variant)}
                    >
                      Contratar
                    </Button>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {product?.guarantees &&
              product?.guarantees.map((guarantee: any) => (
                <React.Fragment key={guarantee.title}>
                  <tr key={guarantee.title}>
                    <td
                      className="py-2 bg-gray-50"
                    >
                      <p className="ml-4 font-bold">{guarantee.title}</p>
                    </td>
                   {product?.variants?.length ? product?.variants?.map((variant:any) =>(
                      <td
                      className={`${variantParams === variant ? 'border-2':'border'} text-center border-t-0 border-b-0 bg-gray-50`} key={variant}
                      style={{
                        borderColor: `${variantParams === variant ? colorSelect :''}`,
                      }}></td>
                    )):''}
                    
                  </tr>
                  {guarantee.items.map(
                    (item: any) =>
                      !item?.isHidden?.onWeb && (
                        <Guarantee key={item.id} item={item} />
                      )
                  )}
                </React.Fragment>
              ))}
            {product?.extras?.length > 0 && (
              <tr>
                <td
                      className="py-2 border bg-gray-50"
                >
                  <p className="ml-4 font-bold">GARANTIAS OPTATIVAS</p>
                </td>
                {product?.variants?.length ? product?.variants?.map((variant:any) =>(
                      <td
                      className={` ${variantParams === variant ? 'border-2':'border'} py-2 text-center px-2  border-t-0 border-b-0 bg-gray-50`} key={variant}
                      style={{
                        borderColor: `${variantParams === variant ? colorSelect :''}`,
                      }}></td>
                    )):''}
              </tr>
              
            )}
            {product?.extras &&
              product?.extras.map((item: any) => (
                <Extra key={item.id} item={item} />
              ))}
            {product?.irisExtras &&
              product?.irisExtras.filter((extra: any) => extra?.is_hidden ? extra?.is_hidden === false : true).map((item: any) => (
                <Tooltip
                  placement="top"
                  title="Tomador del Seguro La persona que celebra el contrato  y que es responsable del pago de la prima."
                >
                  <Extra key={item.id} item={item} isIRISExtra />
                </Tooltip>
              ))}
            <tr>
              <td className="py-4 pl-2 border"></td>
              {product?.variants?.length > 0 &&
                product?.variants.map((variant: any) => (
                  <td
                    className={` ${variantParams === variant ? 'border-2':'border'} border-t-0 py-2 text-center px-2`} key={variant}
                    style={{
                      borderColor: `${variantParams === variant ? colorSelect :''}`,
                    }}
                  >
                    <div className="flex flex-col items-center">
                      <Button onClick={() => handleOpen(variant)}>
                        Guardar Presupuesto
                      </Button>
                      {product?.coveragePdfFiles &&
                        product?.coveragePdfFiles[variant] && (
                          <ExternalLink
                            href={product?.coveragePdfFiles[variant]}
                          >
                            <p
                              className={`hover:underline font-bold transition duration-300 ease-in-out cursor-pointer mt-2 ${classes.hover}`}
                            >
                              Más coberturas
                            </p>
                          </ExternalLink>
                        )}
                      <ShareBudget
                        className="flex justify-center w-full m-1"
                        data={{
                          ...filter,
                          productId: product?._id,
                          productVariant: variant,
                          productProvider: product?.providers[variant] ?? ''
                        }}
                        productId={product?._id}
                      />
                    </div>
                  </td>
                ))}
            </tr>
          </tbody>
        </table>
      </Paper>
      <RequestBudget open={open} onClose={handleClose} filter={filterData} />
    </>
  );
};

export default DesktopPlanTable;
