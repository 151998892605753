import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "@components/Input";
import { Button } from "@components";
import { useDispatch } from "react-redux";
import { forgotPassword } from "@store/user/UserActions";

interface ForgotPasswordFormProps {
  setEmailSent: any;
}

const defaultValues = {
  email: "",
};

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props) => {
  const { setEmailSent } = props;
  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Debe introducir un mail válido")
      .required("Debe introducir un mail"),
  });

  const dispatch = useDispatch();

  const { control, formState, handleSubmit } = useForm({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const onSubmit = (data: any) => {
    const payload = {
      email: data.email,
    };

    dispatch(forgotPassword(payload, setEmailSent));
  };

  return (
    <form
      name="createPasswordForm"
      noValidate
      className="flex flex-col justify-center w-full space-y-3 px-4 py-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <p className="text-xl font-medium text-center">
        Has olvidado tu contraseña
      </p>
      <Input
        name="email"
        type="email"
        label={"Email"}
        control={control}
        required
        fullWidth
        error={!!errors.email}
        errorMessage={errors?.email?.message}
      />
      <Button color="primary" className="w-full mt-4" type="submit">
        Enviar
      </Button>
    </form>
  );
};

export default ForgotPasswordForm;
