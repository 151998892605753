import { RewardActionTypes } from "../redux/actionTypes";

export const getRewards = (payload: any) => ({
  type: RewardActionTypes.GET_REWARDS,
  payload,
});

export const getRewardsSuccess = (payload: any) => ({
  type: RewardActionTypes.GET_REWARDS_SUCCESS,
  payload,
});

export const claimReward = (payload: any) => ({
  type: RewardActionTypes.CLAIM_REWARD,
  payload,
});

export const setRewardLoader = (payload: any) => ({
  type: RewardActionTypes.SET_REWARD_LOADER,
  payload,
});
