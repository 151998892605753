import { call, put, takeLatest } from "redux-saga/effects";

import { axiosInstance } from "@api/axios";
import { getRewardsUrl, pointTransactionUrl } from "@api/Endpoint";
import { RewardActionTypes } from "../redux/actionTypes";
import { toast } from "react-toastify";
import { getRewardsSuccess, setRewardLoader } from "./RewardActions";
import { getUserPoints } from "@store/user/UserActions";

function* getRewardsSaga(action: Action) {
  try {
    const requestPayload = action.payload;

    yield put(setRewardLoader(true));

    const url = getRewardsUrl(requestPayload);

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    yield put(setRewardLoader(false));

    if (response.status === 200) {
      const payload = response.data.data;
      yield put(getRewardsSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err) {
    yield put(setRewardLoader(false));
    console.log("err: ", err);
  }
}

function* claimRewardSaga(action: any) {
  try {
    const url = pointTransactionUrl();

    const requestPayload = action.payload;

    const response: ResponseGenerator = yield call(
      axiosInstance.post,
      url,
      requestPayload
    );

    if (response?.status === 200) {
      const successMessage = response.data.message;
      toast.success(successMessage);
      yield put(getUserPoints());
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err: any) {
    const errorMessage = err?.response?.data?.message;
    toast.error(errorMessage);
    console.log("err: ", errorMessage);
  }
}

export function* rewardSagas() {
  yield takeLatest(RewardActionTypes.GET_REWARDS, getRewardsSaga);
  yield takeLatest(RewardActionTypes.CLAIM_REWARD, claimRewardSaga);
}
