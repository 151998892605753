import { Autocomplete, Box, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";

interface SelectAutoCompleteProps {
  name: string;
  label?: string;
  control: any;
  error?: boolean;
  errorMessage?: string;
  required?: boolean;
  fullWidth?: boolean;
  className?: string;
  color?:
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  size?: "small" | "medium" | undefined;
  variant?: "outlined" | "standard" | "filled" | undefined;
  list?: any;
  disableClearable?: boolean;
  labelOutside?: boolean;
  saveObject?: boolean;
  groupBy?: any;
  labelCenter?: boolean;
  onChange?: any;
  value?: any;
  smallTextsInBigScreens?: boolean;
}

const useStyles: any = makeStyles((theme: any) => ({
  root: {
    "& .MuiFilledInput-root": {
      background: "white !important",
    },
  },
}));

const SelectAutoComplete: React.FC<SelectAutoCompleteProps> = (props) => {
  const {
    name,
    label,
    control,
    error,
    errorMessage,
    required,
    fullWidth,
    className,
    color,
    variant,
    size,
    list,
    disableClearable,
    labelOutside,
    saveObject,
    groupBy,
    labelCenter,
    onChange,
    value,
    smallTextsInBigScreens,
  } = props;

  const classes = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <Autocomplete
            // disablePortal
            className={`flex justify-center ${className}`}
            options={list}
            disableClearable={disableClearable}
            groupBy={groupBy}
            defaultValue={saveObject ? field?.value?.label : field?.value}
            value={
              value
                ? value
                : saveObject
                ? field?.value
                : { value: field?.value, label: field?.value?.toString() }
            }
            onChange={(event: any, newValue: any) => {
              onChange
                ? onChange(newValue, field)
                : saveObject
                ? field.onChange(newValue)
                : field.onChange(newValue.value);
            }}
            renderGroup={
              smallTextsInBigScreens
                ? (params) => (
                    <Box key={params.key}>
                      <p className="text-sm p-2">{params.group}</p>
                      {params.children}
                    </Box>
                  )
                : undefined
            }
            renderOption={(props, option) => (
              <li {...props}>
                <p
                  className={`flex w-full ${
                    labelCenter && " justify-center "
                  } ${smallTextsInBigScreens && " lg:text-xs"}`}
                >
                  {option?.label ? option.label : option}
                </p>
              </li>
            )}
            isOptionEqualToValue={(option: any, value) =>
              option.value === value.value
            }
            renderInput={(params) => {
              if (smallTextsInBigScreens) {
                params.InputProps.className += " lg:text-xs";
              }
              return (
                <div className="flex flex-col w-full">
                  {labelOutside && (
                    <label className="text-sm font-medium whitespace-nowrap">
                      {label}
                      {required && "*"}
                    </label>
                  )}
                  <TextField
                    className={`${classes.root} capitalize`}
                    {...params}
                    label={!labelOutside && label}
                    error={error}
                    helperText={errorMessage}
                    required={required}
                    variant={variant}
                    size={size ? size : "medium"}
                    fullWidth={fullWidth}
                    color={color}
                  />
                </div>
              );
            }}
          />
        </>
      )}
    />
  );
};

export default SelectAutoComplete;
