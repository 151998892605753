import { Button, ExternalLink, ImageAsset } from "@components";
import { useTheme } from "@mui/styles";
import { useSelector } from "react-redux";
import { Fragment, useLayoutEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper";

import app1 from "assets/images/app-1.png";
import app2 from "assets/images/app-2.png";
import app3 from "assets/images/app-3.png";
import app4 from "assets/images/app-4.png";
import app5 from "assets/images/app-5.png";
import app6 from "assets/images/app-6.png";
import app7 from "assets/images/app-7.png";

SwiperCore.use([Pagination]);

const images: any = [app1, app2, app3, app4, app5, app6, app7];

interface InfoParams {
  globalTenant?: any;
}

const infoTexts = (globalTenant: InfoParams) => [
  {
    text1: globalTenant ? "My GlobalFine" : "My Travelfine",
    text2:
      "Comprueba en el área de cliente que tus datos y tu seguro de viaje están correctos, Si tienes contratada la garantía de cancelación, gestiona tu reembolso desde la propia app",
  },
  {
    text1: "Llamada gratuita",
    text2:
      "En caso de sufrir alguna incidencia en tu viaje, utiliza la llamada de asistencia gratuita que te ofrecemos para contactar con el servicio médico. Servicio 24h/365 días al año.",
  },
  {
    text1: "Gestión de siniestros",
    text2:
      "Gestiona en un clic cualquier siniestro, donde y cuando quieras, consulta o sube la documentación y soluciona tu incidencia o reclama un reembolso en tiempo récord. Tu seguro de viaje siempre te acompaña.",
  },
  {
    text1: "Resolución de dudas",
    text2:
      "Elige el canal de contacto (Chat, WhatsApp, Teléfono…etc.) que mejor se adapte a ti y uno de nuestros profesionales resolverá tus dudas de inmediato. Estamos para ayudarte.",
  },
  {
    text1: "Mis Ventajas",
    text2:
      "Disfruta de descuentos y ventajas exclusivas en actividades, alojamientos, restaurantes…etc. adaptadas a los gustos e intereses de tus viajes, para que vivas una experiencia única. Un seguro de viaje a tu altura.",
  },
];

interface MobileAppSectionProps {
  applyCollaboratorPanelStyles: boolean;
}

const MobileAppSection: React.FC<MobileAppSectionProps> = (props) => {
  const theme: any = useTheme();
  const { applyCollaboratorPanelStyles } = props;
  const { globalFineObject, code }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.code
  );
  const textCurrent = infoTexts(globalFineObject);
  const [currentImage, setCurrentImage] = useState(
    globalFineObject ? globalFineObject.image : app1
  );

  useLayoutEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage(
        globalFineObject
          ? globalFineObject.image
          : images[Math.floor(Math.random() * images.length)]
      );
    }, 2000);

    return () => clearInterval(intervalId);
  }, [code, globalFineObject]);

  return (
    <div>
      <p className="text-3xl font-bold text-center lg:text-5xl font-calistoga">
        La mejor app{" "}
        <span
          style={{
            color: theme.palette.secondary.main,
          }}
        >
          para viajar seguro
        </span>
      </p>
      <p className="mt-6 text-xl text-center lg:text-3xl">
        Saca el máximo partido a tu seguro de viaje
      </p>
      <div className="py-4 lg:hidden">
        <Swiper spaceBetween={30} slidesPerView={1} pagination={true}>
          {textCurrent.map((item: any, index) => (
            <SwiperSlide key={index}>
              <div className="pb-8 text-center">
                <p className="mt-6 text-xl font-bold lg:text-3xl">
                  {item.text1}
                </p>
                <p className="mt-4 text-sm font-medium"> {item.text2}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="flex flex-col items-center my-4 lg:flex-row">
        <div className="flex-col hidden lg:flex lg:w-3/12">
          {textCurrent.slice(0, 3).map((item: any, index) => (
            <Fragment key={index}>
              <p className="mt-6 text-lg font-bold lg:text-3xl">{item.text1}</p>
              <p className="mt-4 text-sm font-medium">{item.text2}</p>
            </Fragment>
          ))}
        </div>
        <div className="flex flex-col items-center px-3 my-6 lg:w-6/12 lg:my-0">
          <img className="object-contain w-9/12" src={currentImage} alt="app" />
          <ExternalLink
            href="https://travelfine.es/app-travelfine/"
            disabled={applyCollaboratorPanelStyles}
          >
            <Button color="secondary" className="w-full mt-8">
              DESCÚBRELA
            </Button>
          </ExternalLink>
        </div>
        <div className="flex-col lg:w-3/12">
          <div className="hidden lg:block">
            {textCurrent
              .slice(3, textCurrent.length)
              .map((item: any, index) => (
                <Fragment key={index}>
                  <p className="mt-6 text-lg font-bold lg:text-3xl">
                    {item.text1}
                  </p>
                  <p className="mt-4 text-sm font-medium">{item.text2}</p>
                </Fragment>
              ))}
          </div>
          <p className="mt-6 text-lg font-bold lg:text-3xl">
            ¡Descarga ahora tu app Travelfine!
          </p>
          <div className="flex justify-center mt-4 space-x-2">
            <ExternalLink href="https://apps.apple.com/us/app/travelfine/id1561827319">
              <ImageAsset className="object-contain w-36" src="appStore" />
            </ExternalLink>
            <ExternalLink href="https://play.google.com/store/apps/details?id=com.playbots.travelfine">
              <ImageAsset className="object-contain w-36" src="playStore" />{" "}
            </ExternalLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAppSection;
