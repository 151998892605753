import { useSelector } from "react-redux";
import ItemTitle from "./ItemTitle";
import GuaranteeRowItem from "./GuaranteeRowItem";

interface GuaranteeProps {
  item: any;
  type?: string;
}

const Guarantee: React.FC<GuaranteeProps> = (props) => {
  const { item, type } = props;

  const { product }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.product
  );

  return (
    <tr>
      {!type && (
        <td className="border border-gray-100 py-2 px-4">
          <ItemTitle item={item} />
        </td>
      )}

      {type ? (
        <GuaranteeRowItem type={type} item={item} singleItem />
      ) : (
        <>
          {product?.variants?.length > 0 &&
            product?.variants.map((variant: any) => (
              <GuaranteeRowItem key={variant} type={variant} item={item} />
            ))}
        </>
      )}
    </tr>
  );
};

export default Guarantee;
