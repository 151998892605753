import { combineReducers } from "redux";

import app from "../app/AppReducers";
import category from "../category/CategoryReducers";
import product from "../product/ProductReducers";
import policy from "../policy/PolicyReducers";
import budget from "../budget/BudgetReducers";
import code from "../code/CodeReducers";
import user from "../user/UserReducers";
import advantage from "../advantage/AdvantageReducers";
import reward from "../reward/RewardReducers";

const Travelfine = combineReducers({
  app,
  category,
  product,
  policy,
  budget,
  code,
  user,
  advantage,
  reward,
});

export default Travelfine;
