import { call, put, takeLatest } from "redux-saga/effects";

import { axiosInstance } from "@api/axios";
import { getCategoriesUrl, getCategoryMaximumRangeUrl } from "@api/Endpoint";
import { CategoryActionTypes } from "../redux/actionTypes";
import {
  getCategoriesSuccess,
  getCategoryMaximumRangeSuccess,
} from "./CategoryActions";

function* getCategoriesSaga(action: any) {
  try {
    const url = getCategoriesUrl();

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    if (response.status === 200) {
      const payload = response.data.data;
      yield put(getCategoriesSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err) {
    console.log("err: ", err);
  }
}

function* getCategoryMaximumRangeSaga(action: Action) {
  try {
    const url = getCategoryMaximumRangeUrl(action.payload.id);

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    if (response.status === 200) {
      const payload = response.data.data;
      yield put(getCategoryMaximumRangeSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err) {
    console.log("err: ", err);
  }
}

export function* categorySagas() {
  yield takeLatest(CategoryActionTypes.GET_CATEGORIES, getCategoriesSaga);
  yield takeLatest(
    CategoryActionTypes.GET_CATEGORY_MAXIMUM_RANGE,
    getCategoryMaximumRangeSaga
  );
}
