import { PAYMENT_STATUS } from "@helpers/data";
import React from "react";

interface PaymentStatusBadgeProps {
  paymentStatus: string;
}

const PaymentStatusBadge: React.FC<PaymentStatusBadgeProps> = (props) => {
  const { paymentStatus } = props;
  return (
    <div className="flex items-center justify-center text-center">
      {paymentStatus && (
        <p
          className={`px-3 py-1 rounded-3xl text-white whitespace-nowrap ${
            paymentStatus === PAYMENT_STATUS.COMPLETED
              ? "bg-green-500"
              : paymentStatus === PAYMENT_STATUS.PAID
              ? "bg-yellow-500"
              : paymentStatus === PAYMENT_STATUS.PAYMENT_DEFFERED
              ? "bg-blue-600"
              : paymentStatus === PAYMENT_STATUS.ERROR_EMISSION
              ? "bg-yellow-600"
              : "bg-red-500"
          }`}
        >
          {paymentStatus}
        </p>
      )}
    </div>
  );
};

export default PaymentStatusBadge;
