import React from "react";

import { icons } from "assets";

interface Props {
  src: string;
  onClick?: any;
  className?: string;
  style?: object;
  type?: "IMG" | "SVG";
}

const ImageAsset: React.FC<Props> = (props) => {
  const { src, className, onClick, style, type = "IMG" } = props;
  if (type === "SVG") {
    const Icon = icons[src];
    return <Icon className={`${className}`} onClick={onClick} style={style} />;
  }
  return (
    <img
      alt="icon"
      src={icons[src]}
      className={`${className}`}
      onClick={onClick}
      style={style}
    />
  );
};

export default ImageAsset;
