import { AdvantageActionTypes } from "../redux/actionTypes";

const INITIAL_STATE: AdvantageState = {
  loading: false,
  advantages: [],
  totalAdvantages: 0,
  // advantage:{},
};

const AdvantageReducer = (
  state: AdvantageState = INITIAL_STATE,
  action: Action
): AdvantageState => {
  switch (action.type) {
    case AdvantageActionTypes.GET_ADVANTAGES_SUCCESS:
      if (action.payload.currentPage === 1) {
        return {
          ...state,
          advantages: action.payload.advantages,
          totalAdvantages: action.payload.totalAdvantages,
        };
      }
      return {
        ...state,
        advantages: [...state.advantages, ...action.payload.advantages],
        totalAdvantages: action.payload.totalAdvantages,
      };

    case AdvantageActionTypes.GET_ADVANTAGE_BY_ID:
      return {
        ...state,
        advantage: {},
      };

    case AdvantageActionTypes.GET_ADVANTAGE_BY_ID_SUCCESS:
      const { advantage } = action.payload;
      return {
        ...state,
        advantage,
      };

    case AdvantageActionTypes.SET_ADVANTAGE_LOADER:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
};

export default AdvantageReducer;
