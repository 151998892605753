import { AdvantageActionTypes } from "../redux/actionTypes";

export const getAdvantages = (payload: any) => ({
  type: AdvantageActionTypes.GET_ADVANTAGES,
  payload,
});

export const getAdvantagesSuccess = (payload: any) => ({
  type: AdvantageActionTypes.GET_ADVANTAGES_SUCCESS,
  payload,
});

export const getAdvantageById = (payload: any) => ({
  type: AdvantageActionTypes.GET_ADVANTAGE_BY_ID,
  payload,
});

export const getAdvantageByIdSuccess = (payload: any) => ({
  type: AdvantageActionTypes.GET_ADVANTAGE_BY_ID_SUCCESS,
  payload,
});

export const setAdvantageLoader = (payload: any) => ({
  type: AdvantageActionTypes.SET_ADVANTAGE_LOADER,
  payload,
});
