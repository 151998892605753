import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ImageAsset, Loader } from "@components";
import { getUserPoints } from "@store/user/UserActions";
import { claimReward, getRewards } from "@store/reward/RewardActions";
import RewardCard from "./components/RewardCard";
import { Confirmation } from "@components/modals";

interface RewardsProps {}

const limit = 20;

const defaultConfirmClaimValues = {
  open: false,
  rewardId: "",
};

const Rewards: React.FC<RewardsProps> = (props) => {
  const dispatch = useDispatch();
  const [currentPageNo, setCurrentPageNo] = useState(1);

  const [confirmClaim, setConfirmClaim] = useState({
    ...defaultConfirmClaimValues,
  });

  const { loading }: any = useSelector<any>(({ Travelfine }) => Travelfine.app);
  const { points, loading: userLoading }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.user
  );
  const {
    rewards,
    totalRewards,
    loading: rewardLoading,
  }: any = useSelector<any>(({ Travelfine }) => Travelfine.reward);

  const observer = useRef<any>();

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        const hasMore = currentPageNo < Math.ceil(totalRewards / limit);
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPageNo(currentPageNo + 1);
          const payload = {
            page: currentPageNo + 1,
            limit,
          };
          dispatch(getRewards(payload));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, currentPageNo, dispatch, totalRewards]
  );

  useEffect(() => {
    dispatch(getUserPoints());
    const payload = {
      page: 1,
      limit,
    };
    dispatch(getRewards(payload));
  }, [dispatch]);

  const confirmClaimHandler = () => {
    const { rewardId } = confirmClaim;
    dispatch(claimReward({ rewardId }));
    setConfirmClaim({ ...defaultConfirmClaimValues });
  };

  return (
    <div className="flex flex-col lg:px-10vw">
      <Loader loading={loading || userLoading || rewardLoading} />
      <ImageAsset src="rewardsCover" className="w-full lg:rounded-2xl" />
      <div className="flex flex-col items-center justify-center px-4 lg:px-0 mt-10">
        <p className="text-2xl mb-2 lg:mb-0 font-medium">Club Travelfine</p>
        <div className="flex flex-col lg:flex-row items-center space-y-3 lg:space-y-0 lg:space-x-8 lg:mt-4">
          <div className="lg:w-1/2">
            <p className="text-sm">
              ¡Contratar tus seguros de viaje con Travelfine tiene PREMIO! Suma
              puntos al realizar tus compras desde Travelfine y canjéalos por
              regalos, descuentos o experiencias increíbles.
            </p>
          </div>
          <div className="lg:w-1/2 flex justify-center">
            <div className="text-center rounded-md bg-white shadow-lg px-20 py-12">
              <p className="font-medium">Puntos de viaje</p>
              <p className="text-3xl font-black text-primary">{points}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 gap-6 px-2 lg:px-10 py-10">
        {rewards.map((reward: any) => (
          <RewardCard
            key={reward?._id}
            reward={reward}
            lastElementRef={lastElementRef}
            userPoints={points}
            claimReward={(rewardId: any) =>
              setConfirmClaim({ open: true, rewardId })
            }
          />
        ))}
      </div>
      <Confirmation
        onConfirm={confirmClaimHandler}
        onClose={() => setConfirmClaim({ ...defaultConfirmClaimValues })}
        open={confirmClaim.open}
        confirmText={"¿Estás seguro de que quieres reclamar esta recompensa?"}
      />
    </div>
  );
};

export default Rewards;
