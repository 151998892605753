import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { numberWithCommas } from "@helpers/utils";
import ItemTitle from "./ItemTitle";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useSelector } from "react-redux";
import { GLOBAL_FINE } from "@constants/constants";

interface GuaranteeRowItemProps {
  className?: string;
  item?: any;
  type: string;
  singleItem?: boolean;
}

const GuaranteeRowItem: React.FC<GuaranteeRowItemProps> = (props) => {
  const { className, item, type, singleItem } = props;

  const [searchParams] = useSearchParams();
  const variantParams: any = searchParams.get("variant");
  const theme: any = useTheme();
  const { code }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);

  const isExistCode = code?.codeKey?.toUpperCase().includes(GLOBAL_FINE.code?.toUpperCase()) ?? false;
  const colorSelect = isExistCode ? theme.palette.secondary.main: '#fdc413';

  const limitObj = item.limits?.find((limit: any) => limit.name === type);
  const limit = limitObj?.value;
  const isPrice = !isNaN(limit);

  return (
    <td
      className={`${
        variantParams === type ? "border-2 border-t-0 " : 'border'
      } py-2 text-center px-2 ${className}`}
      style={{
        borderColor: `${variantParams === type ? colorSelect : ''}`,
        borderTopColor: 'rgb(243 244 246)',
        borderBottomColor: 'rgb(243 244 246)'
      }}
    >
      <div className="flex items-center justify-between lg:block">
        {item && singleItem && <ItemTitle item={item} />}

        {limit === "Si" ? (
          <CheckCircleIcon className="text-green-300 fill-current" />
        ) : limit === "No" ? (
          <CancelIcon className="text-gray-400 fill-current" />
        ) : (
          <p>
            {isPrice ? numberWithCommas(limit) : limit}
            {isPrice && "€"}
          </p>
        )}
      </div>
    </td>
  );
};

export default GuaranteeRowItem;
