import { Dialog } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Input from "@components/Input";
import { Button, ExternalLink } from "@components";
import { useDispatch, useSelector } from "react-redux";
import { createPassword } from "@store/user/UserActions";
import { useNavigate } from "react-router-dom";
import Loader from "@components/Loader";
import { PAYMENT_STATUS } from "@helpers/data";

interface CreatePasswordProps {
  onClose: any;
  open: boolean;
  filter: any;
  paymentStatus: string;
  productInfo: any;
}

const defaultValues = {
  password: "",
  passwordConfirm: "",
};

const CreatePassword: React.FC<CreatePasswordProps> = (props) => {
  const { onClose, open, filter, paymentStatus, productInfo } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading }: any = useSelector<any>(({ Travelfine }) => Travelfine.app);

  const handleClose = () => {
    onClose();
  };

  const schema = yup.object().shape({
    password: yup
      .string()
      .required("Por favor, introduzca sucontraseña")
      .min(
        6,
        "La contraseña es demasiado corta, debe tener al menos 6 caracteres"
      ),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden"),
  });

  const { control, formState, handleSubmit } = useForm({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const onSubmit = (data: any) => {
    const {
      modality,
      origin,
      destination,
      departureDate,
      returnDate,
      totalTravellers,
    } = filter;

    const payload = {
      newPassword: data.password,
      travelInfo: {
        category: modality,
        origin,
        destination,
        departureDate,
        returnDate,
        totalTravellers,
      },
      ...productInfo,
    };
    dispatch(createPassword(payload, navigate, paymentStatus));
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="filter-Dialog">
      <Loader loading={loading} />
      <form
        name="createPasswordForm"
        noValidate
        className="flex flex-col justify-center w-full space-y-3 px-4 py-6"
        onSubmit={handleSubmit(onSubmit)}
      >
        {paymentStatus === PAYMENT_STATUS.ERROR_PAYMENT ? (
          <div className="text-xs lg:text-sm font-medium">
            <p>Error al realizar la compra de su seguro de viaje.</p>
            <p className="mt-1.5">
              Rogamos que contacte con nosotros para resolver su incidencia.
            </p>
            <div className="flex justify-center flex-wrap mt-1.5">
              <span>+ 34 911 098 666</span>
              <span className="ml-6">+34 685 375 249</span>
              <ExternalLink href="mailto:info@travelfine.es">
                <span className="underline hover:text-primary transition duration-300 ease-in-out lg:ml-6">
                  info@travelfine.es
                </span>
              </ExternalLink>
            </div>
            <p className="mt-3">
              Muchas gracias por asegurar tu experiencia con Travelfine. Añada
              una contraseña para la creación de su perfil.
            </p>
          </div>
        ) : (
          <p className="text-sm font-medium">
            Has contratado con éxito la póliza. Por favor, añade una contraseña
            para acceder a los detalles de tu póliza
          </p>
        )}
        <Input
          name="password"
          type="password"
          label={"Contraseña"}
          control={control}
          required
          fullWidth
          error={!!errors.password}
          errorMessage={errors?.password?.message}
        />

        <Input
          name="passwordConfirm"
          type="password"
          label={"Confirmar Contraseña"}
          control={control}
          required
          fullWidth
          error={!!errors.passwordConfirm}
          errorMessage={errors?.passwordConfirm?.message}
        />
        <Button color="primary" className="w-full mt-4" type="submit">
          Enviar
        </Button>
      </form>
    </Dialog>
  );
};

export default CreatePassword;
