import { call, put, takeLatest } from "redux-saga/effects";

import { axiosInstance } from "@api/axios";
import {
  policyUrl,
  getPoliciesUrl,
  policyByIdUrl,
  getGeneratedPolicyPDFUrl,
} from "@api/Endpoint";
import { PolicyActionTypes } from "../redux/actionTypes";
import { toast } from "react-toastify";
import {
  getPoliciesSuccess,
  getPolicyByIdSuccess,
  setUpdatePasswordModal,
} from "./PolicyActions";
import { PAYMENT_STATUS } from "@helpers/data";

function* createPolicy(action: any) {
  try {
    const requestPayload = action.payload;
    const navigate = action.navigate;

    const {
      travelInfo,
      paymentStatus,
      paymentMethod,
      productVariant,
      codeKey,
      productProvider,
      productId,
      userType,
    } = requestPayload;

    const url = policyUrl();

    const response: ResponseGenerator = yield call(
      axiosInstance.post,
      url,
      requestPayload
    );

    if (response.status === 200) {
      const successMessage = response.data.message;
      const data = response.data.data;
      if (paymentStatus === PAYMENT_STATUS.ERROR_PAYMENT) {
        toast.error(PAYMENT_STATUS.ERROR_PAYMENT);
        throw PAYMENT_STATUS.ERROR_PAYMENT;
      }
      if (userType !== "collaborator") {
        localStorage.setItem("tr_access_token", data.accessToken);
        localStorage.setItem("userId", data.userId);
      }
      if (data.newUser && userType !== "collaborator") {
        yield put(setUpdatePasswordModal(true));
      } else if (paymentStatus !== PAYMENT_STATUS.ERROR_PAYMENT) {
        toast.success(successMessage);

        let url = "/dashboard/success";

        if (userType === "collaborator") {
          url = `${url}/?userType=${userType}&code=${codeKey}`;
        }
        navigate(url, {
          state: {
            travelInfo: {
              ...travelInfo,
              paymentMethod,
              variant: productVariant,
              code: codeKey,
              provider: productProvider,
              productId,
            },
          },
        });
      }
    } else {
      const errorMessage = response.response.data.message;
      toast.error(errorMessage);
      console.log("error: ", errorMessage);
    }
  } catch (err: any) {
    console.log("err: ", err.response);
    const errorMessage = err?.response?.data?.data?.irisApiResponseData
      ?.Des_Error
      ? err?.response?.data?.data?.irisApiResponseData?.Des_Error
      : err?.response?.data?.message;
    toast.error(errorMessage);
  }
}
function* getPoliciesSaga(action: Action) {
  try {
    const requestPayload = action.payload;

    const url = getPoliciesUrl(requestPayload);

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    if (response.status === 200) {
      const payload = response.data.data;
      yield put(getPoliciesSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err) {
    console.log("err: ", err);
  }
}

function* getPolicyByIdSaga(action: Action) {
  try {
    const id = action.payload;
    const url = policyByIdUrl(id);

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    if (response.status === 200) {
      const payload = response.data.data;
      yield put(getPolicyByIdSuccess(payload));
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err) {
    console.log("err: ", err);
  }
}

function* getGeneratedPolicyPDFSaga(action: Action) {
  try {
    const id = action.payload;
    const url = getGeneratedPolicyPDFUrl(id);

    const response: ResponseGenerator = yield call(axiosInstance.get, url);

    if (response.status === 200) {
      const { url } = response.data.data;

      window.open(url, "_blank");
    } else {
      const errorMessage = response.response.data.message;
      console.log("error: ", errorMessage);
    }
  } catch (err: any) {
    if (err.response.status === 429) {
      const errorMessage = err.response.data;
      console.log("error: ", errorMessage);
      toast.error(errorMessage);
    }
    console.log("err: ", err);
  }
}

export function* policySagas() {
  yield takeLatest(PolicyActionTypes.CREATE_POLICY, createPolicy);
  yield takeLatest(PolicyActionTypes.GET_POLICIES, getPoliciesSaga);
  yield takeLatest(PolicyActionTypes.GET_POLICY_BY_ID, getPolicyByIdSaga);
  yield takeLatest(
    PolicyActionTypes.GET_GENERATED_POLICY_PDF,
    getGeneratedPolicyPDFSaga
  );
}
