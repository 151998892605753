import { Button, Loader, PaymentStatusBadge } from "@components";
import { PAYMENT_STATUS } from "@helpers/data";
import {
  getGeneratedPolicyPDF,
  getPolicyById,
} from "@store/policy/PolicyActions";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PurchasedDetailCardDesktop from "./components/PurchasedDetailCardDesktop";
import PurchasedDetailCardMobile from "./components/PurchasedDetailCardMobile";

interface PurchasedDetailProps {}

const PurchasedDetail: React.FC<PurchasedDetailProps> = (props) => {
  const dispatch = useDispatch();
  const routeParams = useParams();

  const { id } = routeParams;

  const { loading }: any = useSelector<any>(({ Travelfine }) => Travelfine.app);
  const { policy }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.policy
  );

  useEffect(() => {
    dispatch(getPolicyById(id));
  }, [dispatch, id]);

  const handleDownloadPolicyPdf = () => {
    dispatch(getGeneratedPolicyPDF(id));
  };

  return (
    <div className="flex flex-col lg:px-10vw">
      <p className="font-bold px-4 lg:px-0 mb-3 text-lg">
        Detalles de la póliza
      </p>
      <Loader loading={loading} />

      <div className="flex mb-4 flex-col px-4 lg:px-20 py-10 shadow-lg bg-white">
        {policy?.paymentStatus === PAYMENT_STATUS.COMPLETED && (
          <Button
            color="secondary"
            variant="outlined"
            className="self-end mb-6"
            onClick={handleDownloadPolicyPdf}
          >
            Descargar Póliza
          </Button>
        )}

        <PurchasedDetailCardDesktop policy={policy} />
        <PurchasedDetailCardMobile policy={policy} />
        <div className="flex space-x-3 items-center mt-4">
          <p className="text-gray-500">Estado De Pago:</p>
          <PaymentStatusBadge paymentStatus={policy?.paymentStatus} />
        </div>
      </div>
    </div>
  );
};

export default PurchasedDetail;
