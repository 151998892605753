import { ExternalLink, ImageAsset } from "@components";
import { useTheme } from "@mui/styles";

interface ContactInfoProps {
  applyCollaboratorPanelStyles?: boolean;
}

const CUSTOMER_SERVICE_WHATSAPP = {
  FOR_COLLABORATORS: "+34 722 89 75 38",
  FOR_CLIENTS: "+34 685 375 249",
};

const CUSTOMER_SERVICE_EMAIL = {
  FOR_COLLABORATORS: "Agencias@travelfine.es",
  FOR_CLIENTS: "info@travelfine.es",
};

const ContactInfo: React.FC<ContactInfoProps> = (props) => {
  const theme: any = useTheme();
  const { applyCollaboratorPanelStyles } = props;
  const phoneNumber = applyCollaboratorPanelStyles
    ? CUSTOMER_SERVICE_WHATSAPP.FOR_COLLABORATORS
    : CUSTOMER_SERVICE_WHATSAPP.FOR_CLIENTS;

  return (
    <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-10">
      <div className="flex flex-col items-center">
        <ImageAsset
          style={{ color: theme.palette.secondary.main }}
          type="SVG"
          src="phone"
          className="w-20"
        />
        <p className="font-bold text-xl mt-3">Atención al Cliente</p>
        <p className="mt-1">+34 911 098 666</p>
      </div>
      <div className="flex flex-col items-center">
        <ImageAsset
          style={{ color: theme.palette.secondary.main }}
          src="whatsapp"
          type="SVG"
          className="w-20"
        />
        <p className="font-bold text-xl mt-3">Servicio Whatsapp</p>
        <ExternalLink
          href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=%C2%A1Hola!%20Estoy%20interesado%20en%20un%20seguro`}
        >
          <p className="mt-1">{phoneNumber}</p>
        </ExternalLink>
      </div>
      <div className="flex flex-col items-center">
        <ImageAsset
          style={{ color: theme.palette.secondary.main }}
          type="SVG"
          src="email"
          className="w-20"
        />
        <p className="font-bold text-xl mt-3">Email</p>
        <p className="mt-1">
          {applyCollaboratorPanelStyles
            ? CUSTOMER_SERVICE_EMAIL.FOR_COLLABORATORS
            : CUSTOMER_SERVICE_EMAIL.FOR_CLIENTS}
        </p>
      </div>
    </div>
  );
};

export default ContactInfo;
