import { Button, ExternalLink, ImageAsset, Input, Loader } from "@components";
import { useTheme } from "@mui/styles";
import { filterIrisExtrasSelected, filterProductOvercosts } from "@helpers/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Dialog } from "@mui/material";
import { createBudget, setBudgetId } from "@store/budget/BudgetActions";
import { format } from "date-fns";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { GLOBAL_FINE } from "@constants/constants";

interface RequestBudgetProps {
  onClose: any;
  open: boolean;
  filter: any;
}

const defaultValues = {
  name: "",
  email: "",
  phoneNo: "",
  acceptPrivacyPolicy: false,
  acceptPromotions: false,
};

const RequestBudget: React.FC<RequestBudgetProps> = (props) => {
  const { onClose, open, filter } = props;

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);

  const { code }: any = useSelector<any>(({ Travelfine }) => Travelfine.code);
  const { loading, budgetId }: any = useSelector<any>(
    ({ Travelfine }) => Travelfine.budget
  );

  const {
    productOverCosts,
    product,
    irisExtraSelected,
    prices,
    originalPrices,
  }: any = useSelector<any>(({ Travelfine }) => Travelfine.product);

  const theme: any = useTheme();

  const discountPercentage = code?.percentage;
  const totalPrice = prices[filter.productVariant];
  const totalPriceAfterDiscount: number = discountPercentage
    ? totalPrice - (discountPercentage * totalPrice) / 100
    : totalPrice;

  const productVariant = filter?.productVariant;
  const productProvider = product?.providers?.[productVariant];

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Debe introducir un mail válido")
      .required("Debe introducir un mail"),
    name: yup.string().required("No debe estar vacío"),
    phoneNo: yup.string().required("No debe estar vacío"),
    acceptPrivacyPolicy:
      page === 1
        ? yup.boolean()
        : yup.boolean().oneOf([true], "debe ser verificado"),
  });

  const { control, formState, reset, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const handleClose = () => {
    onClose();
    reset();
    setPage(1);
    dispatch(setBudgetId(""));
  };

  const onSubmit = async (data: any) => {
    if (page === 1) {
      setPage(2);
      return;
    }

    if (page === 2) {
     const urlHome =  window.location.host; 
     const isExistCode = code?.codeKey?.toUpperCase()?.includes(GLOBAL_FINE?.code?.toUpperCase()) ?? false;
      const form = {
        extrasForEmail: {
          supplements: getSuplementsForMailInfo(
            productOverCosts,
            irisExtraSelected,
            productVariant
          ),
          originalPrice: originalPrices[filter.productVariant],
          totalPrice: totalPrice,
          showPriceAfterDiscount:
            discountPercentage !== undefined && discountPercentage > 0,
          priceAfterDiscount: totalPriceAfterDiscount,
        },
        extrasInfo: {
          productOvercosts: filterProductOvercosts(
            productOverCosts,
            productVariant
          ),
          irisExtraSelected: filterIrisExtrasSelected(
            irisExtraSelected,
            productVariant
          ),
        },
        collaboratorCode: code?.codeKey,
        name: data.name,
        email: data.email,
        phoneNo: data.phoneNo,
        departureDate: format(new Date(filter.departureDate), "yyyy-MM-dd"),
        returnDate: format(new Date(filter.returnDate), "yyyy-MM-dd"),
        totalTravellers: filter.totalTravellers,
        category: filter.modality.label,
        destination: {
          value: filter.destination.value,
          code: filter.destination.code,
          region: filter.destination.region
            ? filter.destination.region
            : "mundo",
        },
        origin: {
          value: filter.origin.value,
          code: filter.origin.code,
          region: filter.origin.region ? filter.origin.region : "mundo",
        },
        productId: filter.productId,
        productVariant,
        productProvider,
        price: filter.price,
        userInDestination: filter.userInDestination,
        acceptPrivacyPolicy: data.acceptPrivacyPolicy,
        acceptPromotions: data.acceptPromotions,
        theme: theme?.palette?.secondary ?? null,
        isGlobalFine: isExistCode || urlHome.includes(GLOBAL_FINE.code)
      };
      
      await dispatch(createBudget({ form }));
      reset(defaultValues);
      setPage(3);
      return;
    }
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <Loader loading={loading} />
      <form className={`lg:w-budgetModal`} onSubmit={handleSubmit(onSubmit)}>
        <div className={`relative px-2`} style={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText
        }}>
          <ImageAsset className="object-contain w-full" src="requestBudget2" />
          {page === 1 ? (
            <p className="absolute text-sm font-black right-8 bottom-12 lg:text-base">
              Rellena los siguientes datos <br /> para recibir tu presupuesto
            </p>
          ) : page === 2 ? (
            <p className="absolute text-sm font-black right-10 bottom-12 lg:text-base">
              La privacidad es <br /> importante para ti.
            </p>
          ) : (
            <p className="absolute text-sm font-black right-10 bottom-12 lg:text-base">
              ¡Enviado!
            </p>
          )}
        </div>
        <div>
          <div
            className={`flex flex-col items-center space-y-4 px-8 py-6 ${page !== 1 && "hidden"
              }`}
          >
            <Input
              label="Nombre"
              name="name"
              control={control}
              fullWidth
              error={!!errors.name}
              errorMessage={errors?.name?.message}
              size="small"
            />
            <Input
              label="Email"
              name="email"
              control={control}
              fullWidth
              error={!!errors.email}
              errorMessage={errors?.email?.message}
              size="small"
            />
            <Input
              label="Número de teléfono"
              name="phoneNo"
              control={control}
              fullWidth
              error={!!errors.phoneNo}
              errorMessage={errors?.phoneNo?.message}
              size="small"
            />
            <p className="text-xs">
              Recuerda que si has aplicado un código descuento con fecha de
              caducidad, el precio de este presupuesto no será válido, si
              finaliza la promoción
            </p>
            <Button
              type="submit"
              className={`rounded-full font-black w-full`}
              style={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText
              }}
            >
              SIGUIENTE
            </Button>
          </div>
          <div
            className={`flex flex-col items-center space-y-4 px-8 py-6 ${page !== 2 && "hidden"
              }`}
          >
            <Input
              label={
                <span>
                  He leído y acepto la{" "}
                  <ExternalLink
                    href="https://travelfine.es/politica-de-privacidad/"
                    className="underline transition duration-300 ease-in-out hover:text-primary"
                  >
                    política de privacidad
                  </ExternalLink>
                </span>
              }
              name="acceptPrivacyPolicy"
              control={control}
              fullWidth
              error={!!errors.acceptPrivacyPolicy}
              errorMessage={errors?.acceptPrivacyPolicy?.message}
              className="w-full mb-2"
              size="small"
              type="checkbox"
              color="secondary"
            />
            <Input
              label="Quiero recibir descuentos, promociones y novedades"
              name="acceptPromotions"
              control={control}
              fullWidth
              className="w-full mb-2"
              size="small"
              type="checkbox"
              color="secondary"
            />
            <Button
              type="submit"
              className={`rounded-full font-black w-full`}
              style={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText
              }}
            >
              ENVIAR PRESUPUESTO
            </Button>
          </div>
          <div
            className={`flex flex-col items-center space-y-4 px-8 py-6 ${page !== 3 && "hidden"
              }`}
          >
            <div className="flex items-center space-x-2 font-bold">
              <span>Nº de presupuesto:</span>{" "}
              {loading ? <CircularProgress /> : <span>{budgetId}</span>}
            </div>
            <div className="text-xs text-center">
              <p className="lg:whitespace-nowrap">
                Hemos procedido a enviarte el presupuesto solicitado por email.
              </p>
              <p> Rogamos revise correo no deseado y/o spam.</p>
              <p> Muchas gracias.</p>
            </div>

            <Button
              onClick={handleClose}
              className={`rounded-full font-black w-full`}
              style={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText
              }}
            >
              ENTENDIDO
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

function getSuplementsForMailInfo(
  productOverCosts: any,
  irisExtraSelected: any,
  productVariant: string
) {
  const output = productOverCosts[productVariant].map(
    (element: any) => element.title
  );

  // CLIENT SAID US TO DON'T SHOW IRIS EXTRAS
  // const irisExtra = irisExtraSelected[productVariant];
  // if (irisExtra) {
  //   output.push(irisExtra.title);
  // }

  return output;
}

export default RequestBudget;
