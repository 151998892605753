import { Tooltip } from "@mui/material";

interface RewardCardProps {
  reward: any;
  lastElementRef: any;
  userPoints: number;
  claimReward: any;
}

const RewardCard: React.FC<RewardCardProps> = (props) => {
  const { reward, lastElementRef, userPoints, claimReward } = props;

  let rangeWidthPercentage = (userPoints / reward?.points) * 100;

  if (rangeWidthPercentage >= 100) {
    rangeWidthPercentage = 100;
  }

  const rewardClickHandler = () => {
    claimReward(reward?._id);
  };

  return (
    <div className="flex flex-col" ref={lastElementRef}>
      <Tooltip placement="top" title={reward?.description}>
        <img
          src={reward?.image}
          alt="reward"
          className="h-60 w-full object-contain cursor-pointer"
          onClick={rewardClickHandler}
        />
      </Tooltip>
      <p className="text-xs text-center mt-3">{reward?.title}</p>
      <div className="flex justify-center mt-2 items-center w-full space-x-2">
        <div className="w-40 h-2 rounded-full bg-gray-200">
          <div
            className="h-full rounded-full bg-primary"
            style={{ width: `${rangeWidthPercentage}%` }}
          ></div>
        </div>

        <p className="text-xs whitespace-nowrap">{reward?.points} Puntos</p>
      </div>
    </div>
  );
};

export default RewardCard;
