import ImageAsset from "./ImageAsset";

interface ProfileCoverProps {}

const ProfileCover: React.FC<ProfileCoverProps> = (props) => {
  return (
    <div className="relative">
      <ImageAsset src="profileCover" className="w-full lg:rounded-2xl" />
      <div
        className="p-2 rounded-full bg-white absolute left-8 bottom-0"
        style={{ transform: "translate(0, 50%)" }}
      >
        <ImageAsset src="user" className="w-20" />
      </div>
    </div>
  );
};

export default ProfileCover;
