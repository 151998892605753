import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input from "@components/Input";
import { Button } from "@components";
import { useDispatch } from "react-redux";
import { changePassword } from "@store/user/UserActions";

interface ChangePasswordFormProps {
  emailSent: string;
  handleClose: any;
}

const defaultValues = {
  otp: "",
  password: "",
  passwordConfirm: "",
};

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = (props) => {
  const { emailSent, handleClose } = props;

  const schema = yup.object().shape({
    otp: yup.string().required("OTP es obligatorio"),
    password: yup
      .string()
      .required("Por favor, introduzca sucontraseña")
      .min(
        6,
        "La contraseña es demasiado corta, debe tener al menos 6 caracteres"
      ),
    passwordConfirm: yup
      .string()
      .oneOf([yup.ref("password"), null], "Las contraseñas no coinciden"),
  });

  const dispatch = useDispatch();

  const { control, formState, handleSubmit } = useForm({
    mode: "onSubmit",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const onSubmit = (data: any) => {
    const payload = {
      newPassword: data.password,
      otp: data.otp,
      email: emailSent,
    };
    dispatch(changePassword(payload));
    handleClose();
  };

  return (
    <form
      name="createPasswordForm"
      noValidate
      className="flex flex-col justify-center w-full space-y-3 px-4 py-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <p className="text-xl font-medium text-center">Actualiza contraseña</p>
      <Input
        name="otp"
        label={"OTP"}
        control={control}
        required
        fullWidth
        error={!!errors.otp}
        errorMessage={errors?.otp?.message}
      />
      <Input
        name="password"
        type="password"
        label={"Contraseña"}
        control={control}
        required
        fullWidth
        error={!!errors.password}
        errorMessage={errors?.password?.message}
      />

      <Input
        name="passwordConfirm"
        type="password"
        label={"Confirmar Contraseña"}
        control={control}
        required
        fullWidth
        error={!!errors.passwordConfirm}
        errorMessage={errors?.passwordConfirm?.message}
      />

      <Button color="primary" className="w-full mt-4" type="submit">
        Enviar
      </Button>
    </form>
  );
};

export default ChangePasswordForm;
