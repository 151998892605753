import { useEffect } from "react";
import { Outlet } from "react-router";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Theme from "./Theme";
import store from "./@store";
import "./App.css";

const App = () => {
  if (process.env.REACT_APP_NODE_ENV === "production") {
    ReactGA.initialize("G-10JJ2GXWXR");
    ReactGA.send("pageview");
  }

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === "production") {
      (function (c: any, l: any, a: any, r: any, i: any, t: any, y: any) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(
        window,
        document,
        "clarity",
        "script",
        "g5cgkk1sxs",
        undefined,
        undefined
      );
    }
  }, []);

  return (
    <div className="App">
      <Provider store={store}>
        <ToastContainer />
        <Theme>
          <Outlet />
        </Theme>
      </Provider>
    </div>
  );
};

export default App;
