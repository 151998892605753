import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

interface DashboardTabProps {
  tab: any;
}

const DashboardTab: React.FC<DashboardTabProps> = (props) => {
  const { tab } = props;
  const { pathname } = useLocation();

  return (
    <Link to={tab.route} className="w-full">
      <p
        className={`lg:bg-white lg:rounded-t-xl w-full flex  px-2 py-3 border-b-2 whitespace-nowrap transition-all duration-500 ease-in-out lg:shadow-lg ${
          pathname === tab.route
            ? " text-primary lg:text-black lg:border-primary"
            : "lg:border-gray-500"
        }`}
      >
        {tab.text}
      </p>
    </Link>
  );
};

export default DashboardTab;
