import { ImageAsset } from "@components";

interface ThankyouCardProps {}

const ThankyouCard: React.FC<ThankyouCardProps> = (props) => {
  return (
    <div
      className="bg-primary  px-4 w-60 py-12 flex flex-col items-center justify-center rounded-3xl"
      style={{
        boxShadow: "0px 4px 21px rgba(0, 0, 0, 0.25)",
      }}
    >
      <ImageAsset className="w-20" src="checkmark" />
      <p className="text-center font-medium text-3xl">¡Gracias!</p>
      <p className="text-center">Su compra fue un éxito</p>
    </div>
  );
};

export default ThankyouCard;
